import gql from 'graphql-tag';

export default gql`
  mutation UpdatePool($poolId: ID!, $stages: [PoolStageInput]) {
	  updatePool(poolId: $poolId, stages: $stages) {
			poolId
			stages {
				stageId,
				name,
				order
			}
	  }
  }
`
import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

// Components
import Header1 from "./Typography/Header1";
import Header2 from "./Typography/Header2";

// Skeleton loading blocks
import { SkeletonInput, SkeletonLabel } from "./UI/Skeleton";

// Images
import addIcon from "../assets/images/button-add.svg";
import tpIcon from "../assets/images/icon-talent-pools-coral.svg";

const PoolListStyled = styled.div`
  margin: 0 15px 0;
`;

const StyledHeader = styled(Header1)`
  padding: 0px;
  color: #ff475f;
`;

const TitleSection = styled.div`
  padding: 20px 15px;
  border-bottom: 2px solid #ff475f;
  margin: 0 -15px;
  & > h1 > img {
    padding-right: 10px;
    margin-bottom: -5px;
  }
  @media (min-width: 768px) {
    border-bottom: 1px solid #eee;
  }
`;

const PoolItem = styled.div`
  margin: 10px 0;
  padding: 5px 0 20px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #0f2d49;

  a {
    text-decoration: none;
  }
`;

const AddButton = props => {
  const user_type = props.user_type;
  if (user_type === "CLIENT") return "";
  return (
    <AddButtonStyled onClick={props.handleOpenAddPool}>
      <img src={addIcon} alt="Create a new Pool" />
    </AddButtonStyled>
  );
};

const AddButtonStyled = styled.button`
  float: right;
  margin-top: -8px;
  border: none;
  padding: 0;
  background: none;

  img {
    width: 44px;
    height: 44px;
  }
`;

const NewMessagesStyled = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2ba9e3;
`;

const RecentMessagesRead = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 10px;
  color: #6f8191;
`;

const RecentMessagesUnread = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #6f8191;
`;

const ShowHideToggle = styled.button`
  margin-top: 10px;
  padding: 0;
  font-size: 14px;
  color: #ff475f;
`;

const formatMessageText = (theme, data, message) => {
  switch (theme) {
    case "system_profile_stage_change":
    case "system_add_collaborator":
      return message;
    case "system_add_profile":
      return `${data.name} has been moved to the Candidate Pool.`;
    default:
      return message;
  }
};

const RecentMessages = props => {
  const { message_timestamp, marker_timestamp, message } = props;
  const message_time = message_timestamp
    ? moment(message_timestamp).fromNow()
    : null;

  if (message_timestamp <= marker_timestamp)
    return (
      <Link to={`/pool/${message.chatId}/${message.created_dt}`}>
        <RecentMessagesRead>
          {formatMessageText(message.theme, message.data, message.message)} -{" "}
          {message_time}
        </RecentMessagesRead>
      </Link>
    );
  else
    return (
      <Link to={`/pool/${message.chatId}/${message.created_dt}`}>
        <RecentMessagesUnread>
          {formatMessageText(message.theme, message.data, message.message)} -{" "}
          {message_time}
        </RecentMessagesUnread>
      </Link>
    );
};

const NewMessages = props => {
  const [showMore, setShowMore] = useState(false);
  const messages = JSON.parse(props.messages);
  const read_marker = props.read_marker;
  const username = props.username;
  if (messages && messages.constructor === Array && read_marker) {
    const messagesNoDuplicates = messages.filter(
      (message, index, self) =>
        index ===
        self.findIndex(
          t => t.place === message.place && t.SortKey === message.SortKey
        )
    );

    const myMessages = messagesNoDuplicates.map(e => e.username === username);
    const messagesWithoutText = messagesNoDuplicates
      .reverse()
      .filter(e => e.theme !== "text");
    const myLatestMessage = Math.max.apply(
      Math,
      myMessages.map(function(o) {
        return o.created_dt;
      })
    );
    const myLastRead =
      myLatestMessage.created_dt > read_marker.created_dt
        ? myLatestMessage.created_dt
        : read_marker.created_dt;
    const newMessages = messagesNoDuplicates.filter(
      e => e.created_dt > myLastRead && e.username !== username
    );
    const newMessagesCount = newMessages.length > 9 ? "9+" : newMessages.length;
    if (newMessages.length > 0) {
      return (
        <React.Fragment>
          <NewMessagesStyled>
            {newMessagesCount} unread messages
          </NewMessagesStyled>
          {messagesWithoutText.slice(0, 3).map((message, key) => (
            <RecentMessages
              key={key}
              message={message}
              message_timestamp={message.created_dt}
              marker_timestamp={read_marker.created_dt}
            />
          ))}
          {showMore
            ? messagesWithoutText
                .slice(3, 6)
                .map((message, key) => (
                  <RecentMessages
                    key={key}
                    message={message}
                    message_timestamp={message.created_dt}
                    marker_timestamp={read_marker.created_dt}
                  />
                ))
            : ""}
          {messagesWithoutText.length > 3 ? (
            <ShowHideToggle onClick={() => setShowMore(!showMore)}>
              {!showMore ? "Show more" : "Show less "}
            </ShowHideToggle>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {messagesWithoutText.slice(0, 3).map((message, key) => (
            <RecentMessages
              key={key}
              message={message}
              message_timestamp={message.created_dt}
              marker_timestamp={read_marker.created_dt}
            />
          ))}
          {showMore
            ? messagesWithoutText
                .slice(3, 6)
                .map((message, key) => (
                  <RecentMessages
                    key={key}
                    message={message}
                    message_timestamp={message.created_dt}
                    marker_timestamp={read_marker.created_dt}
                  />
                ))
            : ""}
          {messagesWithoutText.length > 3 ? (
            <ShowHideToggle onClick={() => setShowMore(!showMore)}>
              {!showMore ? "Show more" : "Show less "}
            </ShowHideToggle>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    }
  }
  return "";
};

const PoolList = props => {
  const { pools, user_type, handleOpenAddPool, username, loading } = props;
  if (loading && !pools) {
    return (
      <PoolListStyled>
        <TitleSection>
          <StyledHeader>
            <img src={tpIcon} alt="Talent Pools" /> Talent Pools
          </StyledHeader>
        </TitleSection>
        <SkeletonInput />
        <SkeletonLabel />
        <SkeletonLabel />
        <SkeletonLabel />
        <SkeletonInput />
        <SkeletonLabel />
        <SkeletonLabel />
        <SkeletonLabel />
        <SkeletonInput />
        <SkeletonLabel />
        <SkeletonLabel />
        <SkeletonLabel />
      </PoolListStyled>
    );
  } else {
    const poolsSorted = pools
      .sort((a, b) => a.last_active_dt - b.last_active_dt)
      .reverse();
    return (
      <PoolListStyled>
        <TitleSection>
          <AddButton
            user_type={user_type}
            handleOpenAddPool={handleOpenAddPool}
          />
          <StyledHeader>
            <img src={tpIcon} alt="Talent Pools" />
            Talent Pools
          </StyledHeader>
        </TitleSection>
        {poolsSorted.map(pool => (
          <PoolItem key={pool.poolId}>
            <Link to={"/pool/" + pool.poolId}>
              <Header2>{pool.pool_name}</Header2>
            </Link>
            {pool.company_name}
            <NewMessages
              messages={JSON.parse(pool.cache)}
              read_marker={pool.read_marker}
              username={username}
            />
          </PoolItem>
        ))}
      </PoolListStyled>
    );
  }
};

export default PoolList;

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Mutation } from "react-apollo"
import ADD_WORKHISTORY from './Queries/ADD_WORKHISTORY'
import DELETE_WORKHISTORY from './Queries/DELETE_WORKHISTORY'
import GET_PROFILE from './Queries/GET_PROFILE'

// UI Componenets
import TextInput from './UI/TextInput'
import Label from './Typography/Label'
import SubmitButton from './UI/SubmitButton'
import SelectDate from './UI/SelectDate'
import Textarea from './UI/Textarea'
import Confirm from './UI/Confirm'

// Images
import BinIcon from '../assets/images/bin.svg'

const currentEpoch = Math.floor(new Date().getTime())

const AddWorkHistoryCont = styled.div`
  display: block;
`

const SubmitButtonStyled = styled(SubmitButton)`
  font-size: 16px;
  margin: 0;
`

const CenterButton = styled.div`
  text-align: center;
`

const DateError = styled.div.attrs(({ hasError }) => ({
  visibility: hasError ? "visible" : "hidden"
}))`
  position: absolute;
  margin-left: 15px;
  margin-top: -16px;
  visibility: ${props => props.visibility};
  font-size: 12px;
  color: red;
`

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  color: 0f2d49;
`

const RemoveButton = styled.button`
  float: right;
  margin-top: -5px;
`

const DateCont = styled.div`
  position: relative;
`

class WorkHistoryEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start_dt: props.workhistory.start_dt,
      end_dt: props.workhistory.end_dt,
      hasError: false,
    }
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
  }

  handleStartDateChange(start_dt) {
    const hasError = (start_dt > this.state.end_dt) ? true : false
    this.setState(state => ({ 
      start_dt,
      hasError
    }))
  }
  handleEndDateChange(end_dt) {
    const hasError = (this.state.start_dt > end_dt) ? true : false
    this.setState(state => ({ 
      end_dt,
      hasError
    }))
  }

  render() {

    const { handleClose, handleRolldown, workhistory, profileId } = this.props
    const { start_dt, end_dt, hasError } = this.state
    let { workhistoryId, company_name, work_title, comments, candidate_id } = workhistory

    return (
      <Mutation 
        mutation={ADD_WORKHISTORY}
        >
        {(addWorkHistory, { data }) => (

          <Mutation 
            mutation={DELETE_WORKHISTORY}
            >
            {(deleteWorkHistory, { data }) => (

              <AddWorkHistoryCont>

                { workhistory.workhistoryId != null ? 
                  <Confirm description="Are you sure you want to delete this content?">
                    {confirm => (
                      <RemoveButton onClick={confirm((e) => deleteWorkHistory({ 
                        variables: { profileId: profileId, workhistoryId: workhistoryId },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          deleteWorkHistory: {
                            __typename: 'WorkHistory',
                            workhistoryId: workhistoryId,
                          }
                        },
                        update: (cache, { data: { deleteWorkHistory } }) => {

                          const query = GET_PROFILE
                          const variables = { profileId: profileId }
                          const data = cache.readQuery({ query, variables })

                          if (data.getProfile.workhistories.filter(e => e.workhistoryId === deleteWorkHistory.workhistoryId).length > 0) {
                            const updatedWorkHistories = data.getProfile.workhistories.filter(e => e.workhistoryId !== deleteWorkHistory.workhistoryId)
                            data.getProfile.workhistories = updatedWorkHistories
                            cache.writeQuery({ query, variables, data })
                          }

                          handleRolldown("Profile updated successfully")
                          handleClose()

                        }
                      })) 
                    } >
                        <img src={BinIcon} alt="Remove Work History" />
                      </RemoveButton>
                    )}
                  </Confirm>
                : null }


                <Title>{ workhistory.workhistoryId != null ? 'Edit' : 'Add' } Experience</Title>

                <form
                  onSubmit={e => {
                    e.preventDefault()

                    addWorkHistory({ 
                      variables: { 
                        profileId: profileId,
                        workhistoryId: workhistoryId,
                        company_name: company_name.value,
                        work_title: work_title.value,
                        start_dt: parseInt(start_dt),
                        end_dt: parseInt(end_dt),
                        comments: comments.value,
                        candidate_id: candidate_id
                      },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        addWorkHistory: {
                          __typename: 'WorkWistory',
                          profileId: profileId,
                          workhistoryId: workhistoryId,
                          company_name: company_name.value,
                          work_title: work_title.value,
                          start_dt: parseInt(start_dt),
                          end_dt: parseInt(end_dt),
                          comments: comments.value,
                          candidate_id: candidate_id
                        }
                      },
                      update: (cache, { data: { addWorkHistory } }) => {

                        const query = GET_PROFILE
                        const variables = { profileId: profileId }
                        const data = cache.readQuery({ query, variables })

                        if (data.getProfile.workhistories.filter(e => e.workhistoryId === addWorkHistory.workhistoryId).length > 0) {
                          const whIndex = data.getProfile.workhistories.findIndex((e => e.workhistoryId === addWorkHistory.workhistoryId))
                          data.getProfile.workhistories[whIndex].company_name = addWorkHistory.company_name
                          data.getProfile.workhistories[whIndex].work_title = addWorkHistory.work_title
                          data.getProfile.workhistories[whIndex].start_dt = addWorkHistory.start_dt
                          data.getProfile.workhistories[whIndex].end_dt = addWorkHistory.end_dt
                          data.getProfile.workhistories[whIndex].comments = addWorkHistory.comments
                          cache.writeQuery({ query: GET_PROFILE, data: data })
                        } else {
                          data.getProfile.workhistories.push(addWorkHistory)
                          cache.writeQuery({ query, variables, data })                          
                        }
                      }
                    })

                    handleRolldown("Profile updated successfully")
                    handleClose()
                  }}
                >
                  <DateCont>
                    <Label>Start Date</Label>
                    <SelectDate
                      date={start_dt}
                      handleDateChange={this.handleStartDateChange}
                    />
                  </DateCont>
                  <DateCont>
                    <Label>End Date</Label>
                    <SelectDate
                      date={end_dt}
                      handleDateChange={this.handleEndDateChange}
                      hasError={hasError}
                    />
                    <DateError hasError={hasError}>End date should be after Start date</DateError>
                  </DateCont>
                  <Label>Job Title</Label>
                  <TextInput
                    ref={node => {
                      work_title = node
                    }}
                    defaultValue={work_title}
                    required={true}
                  /><br />
                  <Label>Company Name</Label>
                  <TextInput
                    ref={node => {
                      company_name = node
                    }}
                    required={true}
                    defaultValue={company_name}
                  /><br />
                  <Label>Description</Label>
                  <Textarea
                    ref={node => {
                      comments = node
                    }}
                    required={true}
                    defaultValue={comments}
                  />
                  <CenterButton>
                    <SubmitButtonStyled type="submit">{ workhistory.workhistoryId != null ? 'Update' : 'Add' }</SubmitButtonStyled>
                  </CenterButton>
                </form>

              </AddWorkHistoryCont>

            )}
          </Mutation>
        )}
      </Mutation>
    )
  }
}

export default WorkHistoryEdit

WorkHistoryEdit.propTypes = {
  workhistory: PropTypes.object,
  profileId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,  
  handleRolldown: PropTypes.func.isRequired,  
}

WorkHistoryEdit.defaultProps = { 
  workhistory: {
    workhistoryId: null,
    company_name: '', 
    work_title: '', 
    start_dt: currentEpoch, 
    end_dt: currentEpoch, 
    comments: '', 
    candidate_id: '',
  }
}
import gql from 'graphql-tag';

export default gql`
  query {
    getAllPools {
		__typename
		poolId
		pool_name
		company_name
		cache
		last_active_dt
		read_marker {
			username
			created_dt
		}
    }
  }
`
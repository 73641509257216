import gql from 'graphql-tag';

export default gql`
  subscription OnAddProfile($poolId: ID!) {
    onAddProfile(poolId: $poolId) {
      __typename
      poolId
      profileId
      name
      recruiter_comments
      current_job_title
      notice_period
      stageId
      job_type
    }
  }
 `
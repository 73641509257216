import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CloseIcon from '../assets/images/down-arrow.svg'

const PopUnderCont = styled.div.attrs(({ height, bottom, zIndex }) => ({
  height: height,
  bottom: bottom,
  zIndex: zIndex
}))`
  position: fixed;
  z-index: ${props => props.zIndex};
  width: 100%;
  height: ${props => props.height};
  bottom: ${props => props.bottom};
  left: 0
  overflow: scroll;
  background-color: transparent;
  transition: bottom 0.3s ease, height 0.3s ease;
`
const Card = styled.div.attrs(({ zIndex }) => ({
  zIndex: zIndex,
}))`
  position: relative;
  max-width: 600px;
  height: 100%;
  box-sizing: border-box;
  margin: 0 15px;
  padding: 15px;
  z-index: ${props => props.zIndex};
  border: solid 1px #DDD;
  border-radius: 9px 9px 0 0;
  background-color: #FFF;

  @media (min-width: 640px) {
    margin: 0 auto;
  }
`

const Overlay = styled.div.attrs(({ isOpen, zIndex }) => ({
  display: isOpen ? "block" : "none",
  background: isOpen ? "rgba(15,45,73,.15)" : "rgba(15,45,73,0)",
  zIndex: zIndex
}))`
  display: ${props => props.display};
  position: fixed; 
  top: 0;
  left: 0;
  z-index: ${props => props.zIndex};
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  transition: all 0.3s ease;
`

const Button = styled.button`
  position: absolute;
  left: 0;
  padding: 0;
  width: 100%;
  background: none;
  border: none;
  text-align: center;

  img {
    width: 22px;
    height: 12px;
  }
`

const Title = styled.h1`
  margin: 20px 0 15px;
  font-weight: bold;
  font-size: 16px;
  color: #0F2D49;
  letter-spacing: 0;
  line-height: 26px;  
`

const PopUnder = (props) => {
  const { isOpen, height, title, zIndex } = props
  let bottom = "0px"
  if (!isOpen) {
    bottom = `-${height}`
  }
  return (
    <React.Fragment>
      <Overlay isOpen={isOpen} zIndex={zIndex-10} />
      <PopUnderCont height={height} bottom={bottom} zIndex={zIndex}>
        <Card zIndex={zIndex - 5}>
          <Button onClick={props.handleClose}><img src={CloseIcon} alt="Close" /></Button>
          <Title>{title}</Title>
          {props.children}
        </Card>
      </PopUnderCont>
    </React.Fragment>
  )
}

export default PopUnder

PopUnder.propTypes = {
  isOpen: PropTypes.bool,
  height: PropTypes.string,
  title: PropTypes.string,
  zIndex: PropTypes.number,
  handleClose: PropTypes.func
}

PopUnder.defaultProps = { 
  isOpen: false,
  height: "0px",
  title: "",
  zIndex: 100,
}
import gql from 'graphql-tag';

export default gql`
  mutation UpdateProfilePool($poolId: ID!, $profileId: ID!, $stageId: ID!) {
	  updateProfilePool(poolId: $poolId, profileId: $profileId, stageId: $stageId) {
			poolId
			stageId
			profileId
	  }
  }
`
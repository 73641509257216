import styled from 'styled-components'

const TextInput = styled.input`
	width: 100%;
	margin-bottom: 20px;
	padding: 10px 15px;
	background: #FFFFFF;
	border: 1px solid #7FCBEE;
	box-shadow: 1px 1px 4px 0 #E7EAED;
	box-sizing: border-box;
 	border-radius: 27px;
	font-size: 14px;
	color: #0f2d49;
	letter-spacing: 0;
	line-height: 22px;
	caret-color: #FF475F;
	transition: box-shadow 0.3s ease;

	&:focus {
		box-shadow: 0 0 4px 1px #55BAE8;
		border-radius: 27px;			
	}
`

export default TextInput
import gql from 'graphql-tag';

export default gql`
  query GetParsedCV($s3Key: String!) {
    getParsedCV( s3Key: $s3Key) {
      profileId
      name
      email
      current_job_title
      workhistories {
        work_title,
        company_name,
        comments,
        start_dt,
        end_dt
      }
      educations {
        institute_name,
        institute_place,
        description,
        level,
        level_description,
        start_dt,
        end_dt
      }
      skills {
        skill_type,
        name
      }
      hobbies {
        name
      }
    }
  }
`
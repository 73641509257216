import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// Apollo components
import { Mutation } from "react-apollo"
import ADD_HOBBY from './Queries/ADD_HOBBY'
import DELETE_HOBBY from './Queries/DELETE_HOBBY'

// UI Components
import HobbiesEditTags from './HobbiesEditTags'
import Tags from './UI/Tags'

const HobbiesStyled = styled(Tags)`
	max-height: 300px;
	overflow: scroll;
`

const HobbiesEdit = (props) => {

	const { hobbies, profileId } = props
	const hobbiesForTags = hobbies.map((hobby, index) => ( { id: hobby.hobbyId, "text": hobby.name } ))

	return (
		<Mutation 
		  mutation={ADD_HOBBY}
		  >
		  {(AddHobby, { data }) => (

		    <Mutation 
		      mutation={DELETE_HOBBY}
		      >
		      {(DeleteHobby, { data }) => (

						<HobbiesStyled>
							<HobbiesEditTags profileId={profileId} hobbiesForTags={hobbiesForTags} AddHobby={AddHobby} DeleteHobby={DeleteHobby} />
		    		</HobbiesStyled>

		      )}
		    </Mutation>
		  )}
		</Mutation>
	)
}

export default HobbiesEdit

HobbiesEdit.propTypes = {
	profileId: PropTypes.string.isRequired,
  hobbies: PropTypes.array.isRequired,
}

HobbiesEdit.defaultProps = { 
  hobbies: [],
}


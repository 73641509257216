import gql from 'graphql-tag';

export default gql`
  mutation AddReadMarker($chatId: ID!, $SortKey: ID, $username: String!) {
   addReadMarker(chatId: $chatId, SortKey: $SortKey, username: $username) {
      __typename
      chatId
      SortKey
      created_dt
      username
    }
  }
`
import React from "react";
import styled from "styled-components";
import moment from "moment";

// Components
import StageChangeButton from "./StageChangeButton";
import CandidateStub from "./CandidateStub";
import InitialsOrImage from "./InitialsOrImage";

// Images
import JobTypeIcon from "../assets/images/profile-job-type-turquoise.svg";
import NoticeIcon from "../assets/images/profile-notice-turquoise.svg";

const MessageItem = styled.div`
  clear: both;
  margin: 5px 0 0;
  @media (min-width: 768px) {
    margin: 5px 10px 0;
  }
`;

const MessageUsername = styled.div.attrs(({ isNewUserMessage }) => ({
  display: isNewUserMessage ? "block" : "none"
}))`
  display: ${props => props.display};
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 13px;
  color: #333333;
  letter-spacing: 0;
  line-height: 13px;
  & > span {
    padding-left: 5px;
    font-weight: normal;
    color: #bbbbbb;
  }
`;

const MessageUserIcon = styled.div`
  float: left;
  width: 40px;
  margin-top: 10px;
`;

const MessageBody = styled.div`
  margin-left: 40px;
`;

const MessageMessage = styled.div`
  background: #ffffff;
  border-radius: 9px;
  font-size: 13px;
  color: #333;
  letter-spacing: 0;
  text-align: left;
  line-height: 18px;

  hr {
    border: 1px solid #e7eaed;
    border-top: none;
  }
`;

const MessageMessageBlueBorder = styled(MessageMessage)`
  max-width: 350px;
  min-width: 200px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(127, 203, 238);
  border-image: initial;
  border-radius: 9px;
  box-shadow: rgb(231, 234, 237) 1px 1px 4px 0px;
`;

const MessageMessageSystem = styled(MessageMessage)`
  max-width: 350px;
  min-width: 200px;
  border: 1px solid #e7eaed;
  padding: 10px;
  box-shadow: rgb(231, 234, 237) 1px 1px 4px 0px;

  > div > img {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-bottom: -1px;
    padding-right: 5px;
  }
`;

const MessageMarker = styled.div`
  clear: both;
  position: relative;
  width: 100%;
  padding: 20px 0 0;
  color: #ff475f;
  text-align: center;
  font-weight: 600;
  font-size: 14px;

  > span {
    position: relative;
    padding: 10px 12px;
    background: white;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 28px;
    height: 1px;
    width: 100%;
    background: #ff909f;
  }
`;

const MessageJobType = styled.div`
  text-transform: capitalize;
`;

const LinkButton = styled.button`
  padding: 0;
  color: #0f2d49;
`;

const ButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
`;

const NotMyReadMarker = styled.div``;

const Message = props => {
  const {
    message,
    chatId,
    showMarker,
    currentUsername,
    profileSummaries,
    isNewUserMessage,
    handleProfileOpen
  } = props;
  const message_time = message.created_dt
    ? moment(message.created_dt).format("hh:mm A")
    : null;
  if (!message.theme && message.username === currentUsername && showMarker)
    return (
      <MessageMarker>
        <span>New Messages</span>
      </MessageMarker>
    );

  if (!message.theme && message.username !== currentUsername)
    return (
      <NotMyReadMarker>
        <InitialsOrImage name={message.username} size="small" />
      </NotMyReadMarker>
    );

  if (message.theme === "system_profile_stage_change") {
    const profile_data = JSON.parse(message.data);

    switch (profile_data.stageId) {
      case "draft":
        const draftMessage =
          "This candidate has been removed from your talent pool";
        return (
          <MessageItem>
            {isNewUserMessage ? (
              <MessageUserIcon>
                <InitialsOrImage name={message.username} size="chat" />
              </MessageUserIcon>
            ) : null}
            <MessageBody>
              <MessageUsername isNewUserMessage={isNewUserMessage}>
                {message.username} <span>{message_time}</span>
              </MessageUsername>
              <MessageMessageBlueBorder>
                <CandidateStub
                  name={profile_data.name}
                  profileId={profile_data.profileId}
                />
                <div>{draftMessage}</div>
              </MessageMessageBlueBorder>
            </MessageBody>
          </MessageItem>
        );
      case "shortlist":
      case "not_interested":
        const shortlistMessage = "This candidate has been shortlisted";
        const regrettedMessage = "This candidate has been regretted";
        const statusMessage =
          profile_data.stageId === "shortlist"
            ? shortlistMessage
            : regrettedMessage;
        return (
          <MessageItem>
            {isNewUserMessage ? (
              <MessageUserIcon>
                <InitialsOrImage name={message.username} size="chat" />
              </MessageUserIcon>
            ) : null}
            <MessageBody>
              <MessageUsername isNewUserMessage={isNewUserMessage}>
                {message.username} <span>{message_time}</span>
              </MessageUsername>
              <MessageMessageBlueBorder>
                <CandidateStub
                  name={profile_data.name}
                  profileId={profile_data.profileId}
                  handleProfileOpen={handleProfileOpen}
                />
                <div>{statusMessage}</div>
              </MessageMessageBlueBorder>
            </MessageBody>
          </MessageItem>
        );
      case "pool":
        const profile = profileSummaries.filter(
          e => e.profileId === profile_data.profileId
        );
        return (
          <MessageItem>
            {isNewUserMessage ? (
              <MessageUserIcon>
                <InitialsOrImage name={message.username} size="chat" />
              </MessageUserIcon>
            ) : null}
            <MessageBody>
              <MessageUsername isNewUserMessage={isNewUserMessage}>
                {message.username} <span>{message_time}</span>
              </MessageUsername>
              <MessageMessageSystem>
                <LinkButton
                  onClick={() => handleProfileOpen(profile_data.profileId)}
                >
                  <strong>{profile_data.name}</strong>
                </LinkButton>
                <hr />
                {profile_data.current_job_title}
                <hr />
                <p>'{profile_data.recruiter_comments}'</p>
                {profile[0] && profile[0].stageId !== "draft" ? (
                  <ButtonsGrid>
                    <StageChangeButton
                      currentUsername={currentUsername}
                      poolId={chatId}
                      profile={profile[0]}
                      buttonText="Not Interested"
                      stageId="not_interested"
                    />
                    <StageChangeButton
                      currentUsername={currentUsername}
                      poolId={chatId}
                      profile={profile[0]}
                      buttonText="Shortlist"
                      stageId="shortlist"
                    />
                  </ButtonsGrid>
                ) : null}
              </MessageMessageSystem>
            </MessageBody>
          </MessageItem>
        );
      default:
        return "";
    }
  }
  // Deprecated - will not show messages on initial add anymore
  if (
    message.theme === "system_add_profile" &&
    message.data &&
    profileSummaries
  ) {
    const profile_data = JSON.parse(message.data);
    const profile = profileSummaries.filter(
      e => e.profileId === profile_data.profileId
    );
    return (
      <MessageItem>
        <MessageUsername isNewUserMessage={isNewUserMessage}>
          {message.username} added a new profile
        </MessageUsername>
        <MessageMessageSystem>
          <LinkButton onClick={() => handleProfileOpen(profile_data.profileId)}>
            <strong>{profile_data.name}</strong>
          </LinkButton>
          <br />
          {profile_data.current_job_title}
          <br />
          <hr />
          <div>
            <img src={NoticeIcon} alt="Notice Period" />{" "}
            {profile_data.notice_period} Notice Period
          </div>
          <MessageJobType>
            <img src={JobTypeIcon} alt="Job Type" />{" "}
            {profile_data.job_type.toLowerCase()}
          </MessageJobType>
          <hr />
          <p>'{profile_data.recruiter_comments}'</p>
          <ButtonsGrid>
            <StageChangeButton
              currentUsername={currentUsername}
              poolId={chatId}
              profile={profile[0]}
              buttonText="Not Interested"
              stageId="not_interested"
            />
            <StageChangeButton
              currentUsername={currentUsername}
              poolId={chatId}
              profile={profile[0]}
              buttonText="Shortlist"
              stageId="shortlist"
            />
          </ButtonsGrid>
        </MessageMessageSystem>
      </MessageItem>
    );
  }
  if (message.message)
    return (
      <MessageItem>
        {isNewUserMessage ? (
          <MessageUserIcon>
            <InitialsOrImage name={message.username} size="chat" />
          </MessageUserIcon>
        ) : null}
        <MessageBody>
          <MessageUsername isNewUserMessage={isNewUserMessage}>
            {message.username} <span>{message_time}</span>
          </MessageUsername>
          <MessageMessage>
            <span
              dangerouslySetInnerHTML={{
                __html: message.message
              }}
            />
          </MessageMessage>
        </MessageBody>
      </MessageItem>
    );
  return "";
};

export default Message;

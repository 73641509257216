import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { WithContext as ReactTags } from 'react-tag-input'

// Apollo components
import GET_PROFILE from './Queries/GET_PROFILE'

// Images
import TagRemoveIcon from '../assets/images/tag-remove.svg'

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

const RemoveButton = styled.button`
	height: 14px;
	position: relative;
	top: 1px;
	margin: 0;
	padding: 0 0 0 5px;

	& > img {
		width: 14px;
		height: 14px;		
	}
`

const RemoveComponent = (props) => {
	return (
		<RemoveButton {...props}>
			<img src={TagRemoveIcon} alt="Remove Hobby" />
		</RemoveButton>
	)
}

const HobbiesEditTags = (props) => {

	const { profileId, hobbiesForTags, AddHobby, DeleteHobby, handleInputFocus } = props

	return (

    <ReactTags 
    	inputFieldPosition="inline"
    	tags={hobbiesForTags}
    	allowUnique={false}
    	autocomplete={true}
    	autofocus={true}
    	handleInputFocus={handleInputFocus}
    	delimiters={delimiters}
    	removeComponent={RemoveComponent}
    	handleAddition={ (hobby) => AddHobby({ 
  			variables: { profileId: profileId, name: hobby.text },
        optimisticResponse: {
          __typename: 'Mutation',
          addHobby: {
            __typename: 'Hobby',
            profileId: profileId,
            hobbyId: '',
            objectId: '',
            name: hobby.text,
          }
        },
        update: (cache, { data: { addHobby } }) => {

          const query = GET_PROFILE
          const variables = { profileId: profileId }
          const data = cache.readQuery({ query, variables })

          if (data.getProfile.hobbies.filter(e => e.hobbyId === addHobby.hobbyId).length <= 0) {
            data.getProfile.hobbies.push(addHobby)
            cache.writeQuery({ query, variables, data })
          }
        }
  		})}
    	handleDelete={ (i) => DeleteHobby({ 
    		variables: { profileId: profileId, hobbyId: hobbiesForTags[i].id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteHobby: {
            __typename: 'Hobby',
            hobbyId: hobbiesForTags[i].id,
          }
        },
        update: (cache, { data: { deleteHobby } }) => {
          const query = GET_PROFILE
          const variables = { profileId: profileId }
          const data = cache.readQuery({ query, variables })

          if (data.getProfile.hobbies.filter(e => e.hobbyId === deleteHobby.hobbyId).length > 0) {
            const updatedHobby = data.getProfile.hobbies.filter(e => e.hobbyId !== deleteHobby.hobbyId)
            data.getProfile.hobbies = updatedHobby
            cache.writeQuery({ query, variables, data })
          }
        }
    	})}
     />

	)
}

export default HobbiesEditTags

HobbiesEditTags.propTypes = {
  profileId: PropTypes.string.isRequired,
  hobbiesForTags: PropTypes.array.isRequired,
  AddHobby: PropTypes.func.isRequired,
  DeleteHobby: PropTypes.func.isRequired,
}
import React from 'react'
import styled from 'styled-components'
import { Mutation } from "react-apollo"
import ADD_PROFILE from './Queries/ADD_PROFILE'
import GET_PROFILE from './Queries/GET_PROFILE'
import GET_POOL from './Queries/GET_POOL'

// UI Componenets
import TextInput from './UI/TextInput'
import Label from './Typography/Label'
import SubmitButton from './UI/SubmitButton'
import Select from './UI/Select'
import Textarea from './UI/Textarea'

const AddProfileCont = styled.div`
  display: block;
`

const SubmitButtonStyled = styled(SubmitButton)`
  font-size: 16px;
  margin: 0;
`

const CenterButton = styled.div`
  text-align: center;
`

class BasicProfileEdit extends React.Component {

  render() {
    let { handleClose, handleRolldown, profileId, name, email, recruiter_comments, notice_period, current_job_title, job_type, poolId } = this.props

    return (
      <Mutation 
        mutation={ADD_PROFILE}
        >
        {(addProfile, { data }) => (

        <AddProfileCont>
          <form
            onSubmit={e => {
              e.preventDefault()

              addProfile({ 
                variables: { 
                  profileId: profileId,
                  email: email.value,
                  name: name.value,
                  recruiter_comments: recruiter_comments.value, 
                  notice_period: notice_period.value, 
                  current_job_title: current_job_title.value,
                  job_type: job_type.value,
                  poolId: poolId
                },
                update: (cache, { data: { addProfile } }) => {

                  const poolData = cache.readQuery({ query: GET_POOL, variables: { poolId } })
                  if (poolData.getPool.profileSummaries.filter(e => e.profileId === addProfile.profileId).length > 0) {
                    const profileIndex = poolData.getPool.profileSummaries.findIndex((e => e.profileId === addProfile.profileId))
                    poolData.getPool.profileSummaries[profileIndex].name = addProfile.name
                    poolData.getPool.profileSummaries[profileIndex].recruiter_comments = addProfile.recruiter_comments
                    poolData.getPool.profileSummaries[profileIndex].notice_period = addProfile.notice_period
                    poolData.getPool.profileSummaries[profileIndex].current_job_title = addProfile.current_job_title
                    poolData.getPool.profileSummaries[profileIndex].job_type = addProfile.job_type
                    cache.writeQuery({ query: GET_POOL, data: poolData })
                  }

                  const profileData = cache.readQuery({ query: GET_PROFILE, variables: { profileId } })
                  profileData.getProfile.name = addProfile.name
                  profileData.getProfile.recruiter_comments = addProfile.recruiter_comments
                  profileData.getProfile.notice_period = addProfile.notice_period
                  profileData.getProfile.current_job_title = addProfile.current_job_title
                  profileData.getProfile.job_type = addProfile.job_type
                  cache.writeQuery({ query: GET_PROFILE, data: profileData })

                }
              })

              handleRolldown("Profile updated successfully")
              handleClose()
            }}
          >
            <Label>Candidate Email</Label>
            <TextInput
              ref={node => {
                email = node
              }}
              defaultValue={email}
              required={true}
            /><br />
            <Label>Candidate Name</Label>
            <TextInput
              ref={node => {
                name = node
              }}
              required={true}
              defaultValue={name}
            /><br />
            <Label>Current Job Title</Label>
            <TextInput
              ref={node => {
                current_job_title = node
              }}
              required={true}
              defaultValue={current_job_title}
            /><br />
            <Label>Notice Period</Label>
            <TextInput
              ref={node => {
                notice_period = node
              }}
              required={true}
              defaultValue={notice_period}
            /><br />
            <Label>Job Type</Label>
            <Select
              ref={node => {
                job_type = node
              }}
              defaultValue={job_type}
            >
              <option value="PERMANENT">Permanent</option>
              <option value="CONTRACT">Contract</option>
              <option value="TEMPORARY">Temporary</option>
            </Select><br />

            <Label>Recruiter Comments</Label>
            <Textarea
              ref={node => {
                recruiter_comments = node
              }}
              required={true}
              defaultValue={recruiter_comments}
            />
            <CenterButton>
              <SubmitButtonStyled type="submit">Update</SubmitButtonStyled>
            </CenterButton>
          </form>
        </AddProfileCont>
        )}
      </Mutation>
    )
  }
}

export default BasicProfileEdit
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// Components
import StageChangeButton from './StageChangeButton'
import StatusRolldown from './StatusRolldown'

// Typography
import Header4 from './Typography/Header4'

// Images
import addIcon from '../assets/images/button-add.svg'

const isRecruiter = (user_type) => user_type === "RECRUITER"

const getSuccessfulStageChangeMessage = (name, stageId) => {
  if (stageId === 'draft') return `${name} has been moved back to Draft`
  if (stageId === 'pool') return `${name} has been moved to Candidate Pool`
}

const PoolCandidateListCont = styled.div`
  & > button {
    margin-top: -10px;
  }

  @media (min-width: 768px) {
    padding: 0 15px;
  }
`

const ProfileItem = styled.div`
  margin: 10px 0;
  padding: 10px 0;
`

const LinkButton = styled.button`
	padding: 0;
	color: #0f2d49;	
`

const Header4Styled = styled(Header4)`
  margin: 10px 0 30px;  
`

const ProfileItemCont = styled.div`
  clear: both;
  margin-top: 10px;
` 

const StageChangeButtonCont = styled.div`
  margin-top: -5px;
  float: right;
`

const NoProfiles = styled.div`
  width: 164px;
  margin: 100px auto;
  text-align: center;
  font-size: 14px;
  color: #3f576d;
  line-height: 1.57;
`

const AddProfileButton = (props) => {
  const user_type = props.user_type
  return isRecruiter(user_type) ? <AddProfileButtonStyled onClick={props.handleOpenAddProfile}><img src={addIcon} alt="Add a new candidate" /></AddProfileButtonStyled> : ''
}

const AddProfileButtonStyled = styled.button`
  float: right;
  border: none;
  padding: 0;
  background: none;
`

export class PoolCandidateList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rolldownOpen: false,
      rolldownText: "",
    }

    this.handleFinishedSuccessfulStageChange = this.handleFinishedSuccessfulStageChange.bind(this);
    this.handleCloseRolldown = this.handleCloseRolldown.bind(this);
  }

  handleFinishedSuccessfulStageChange(name, stageId) {
    this.setState({ rolldownOpen: true, rolldownText: getSuccessfulStageChangeMessage(name, stageId) })
    setTimeout(function() {
        this.setState({rolldownOpen: false})
    }.bind(this), 3000)
  }

  handleCloseRolldown() {
    this.setState({ rolldownOpen: false })
  }

  render() {

  	const { profileSummaries, stageId, title, poolId, currentUsername, user_type, handleProfileOpen, handleOpenAddProfile } = this.props
    const { rolldownOpen, rolldownText } = this.state
  	return (
      <PoolCandidateListCont>
        {this.props.children}
        { stageId === "draft" && isRecruiter(user_type) ? <AddProfileButton user_type={user_type} handleOpenAddProfile={handleOpenAddProfile} /> : null }
        <Header4Styled>{title}</Header4Styled>
        <ProfileItemCont>
          {profileSummaries.length > 0 ?
            profileSummaries.map(profile => (
              <ProfileItem key={profile.profileId}>
                <LinkButton onClick={() => handleProfileOpen(profile.profileId) } >
      						{profile.name}
      					</LinkButton>
                <StageChangeButtonCont>
                  { profile.stageId === "draft" && isRecruiter(user_type) ? <StageChangeButton currentUsername={currentUsername} poolId={poolId} profile={profile} buttonText="Move to Pool" stageId="pool" uiSize="small" callback={this.handleFinishedSuccessfulStageChange} /> : null }
                  { profile.stageId === "pool" && isRecruiter(user_type) ? <StageChangeButton currentUsername={currentUsername} poolId={poolId} profile={profile} buttonText="Move to Draft" stageId="draft" uiSize="small" callback={this.handleFinishedSuccessfulStageChange} /> : null }
                </StageChangeButtonCont>
              </ProfileItem>
            )) :
            <NoProfiles>No candidates have been added yet.</NoProfiles>
          }      
        </ProfileItemCont>
        <StatusRolldown isOpen={rolldownOpen} text={rolldownText} handleClose={this.handleCloseRolldown} />
      </PoolCandidateListCont>
  	)
  }
}

export default PoolCandidateList

PoolCandidateList.propTypes = {
  profileSummaries: PropTypes.array,
  title: PropTypes.string
}

PoolCandidateList.defaultProps = { 
  profileSummaries: [],
  title: ""
}
import styled from 'styled-components'

const FixedWidthContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
	z-index: 2;
  width: 100%;

  > * {
  	max-width: 1200px;
  	margin: 0 auto;
  }

  @media (min-width: 768px) {
    padding-left: 270px;
    box-sizing: border-box;

    & > div {
      margin: 0;
    }
  }
`

export default FixedWidthContainer
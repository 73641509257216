import styled from 'styled-components'

const SkeletonItem = styled.div`
	background: repeating-linear-gradient(to right, #cfd5da 0%, #e7eaed 50%, #cfd5da 100%);
	background-size: 200% auto;
	background-position: 0 100%;
	animation: skeletoninputgradient 2s infinite;
	animation-fill-mode: forwards;
	animation-timing-function: linear;

	@keyframes skeletoninputgradient { 
		0%   { background-position: 0 0; }
		100% { background-position: -200% 0; }
	}
`

export const SkeletonCont = styled.div`
	margin: 15px;
	overflow: auto;
`

export const SkeletonInput = styled(SkeletonItem)`
	width: 100%;
	margin: 10px 0;
	padding: 15px 0;
`

export const SkeletonLabel = styled(SkeletonItem)`
	width: 40%;
	margin: 10px 0;
	padding: 10px 0;
`

export const SkeletonChat = styled(SkeletonItem).attrs(({ align }) => ({
  float: align
}))`
  float: ${props => props.float};
	width: 60%;
	height: 60px;
	margin: 10px 0;
	padding: 25x 0;
`
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import Select from "./Select";

// Languages
import { years, months } from "../../assets/dates";

const SelectDateStyled = styled.div`
  display: grid;
  grid-template-columns: 55% auto;
  grid-column-gap: 15px;
`;

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      year: moment(props.date).format("YYYY"),
      month: moment(props.date).format("MMMM"),
      hasError: false
    };
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  handleMonthChange(e) {
    const month = e.target.value;
    const date = moment.utc();
    date.set("year", this.state.year);
    date.set(
      "month",
      moment()
        .month(month)
        .format("M")
    );
    date.set("date", 1);
    date.startOf("day");
    const timestamp = parseInt(date.format("x"));

    this.setState(state => ({
      date: timestamp,
      month: month
    }));

    this.props.handleDateChange(timestamp);
  }

  handleYearChange(e) {
    const year = e.target.value;
    const date = moment.utc();
    date.set("year", year);
    date.set(
      "month",
      moment()
        .month(this.state.month)
        .format("M")
    );
    date.set("date", 1);
    date.startOf("day");
    const timestamp = parseInt(date.format("x"));

    this.setState(state => ({
      date: timestamp,
      year: year
    }));

    this.props.handleDateChange(timestamp);
  }

  render() {
    const { year, month } = this.state;
    const hasError = this.props.hasError;

    return (
      <SelectDateStyled>
        <Select
          data-testid="select-month"
          hasError={hasError}
          name="month"
          value={month}
          onChange={this.handleMonthChange}
        >
          {months.map((m, i) => (
            <option key={i} value={m}>
              {m}
            </option>
          ))}
        </Select>
        <Select
          data-testid="select-year"
          hasError={hasError}
          name="year"
          value={year}
          onChange={this.handleYearChange}
        >
          {years.map((y, i) => (
            <option key={i} value={y}>
              {y}
            </option>
          ))}
        </Select>
      </SelectDateStyled>
    );
  }
}

SelectDate.propTypes = {
  /** The label of the checkbox */
  date: PropTypes.number
};

SelectDate.defaultProps = {
  date: 0
};

export default SelectDate;

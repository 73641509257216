import gql from 'graphql-tag';

export default gql`
  subscription OnUpdateProfilePool($poolId: ID!) {
    onUpdateProfilePool(poolId: $poolId) {
      poolId
      stageId
      profileId
    }
  }
`
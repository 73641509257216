import styled from 'styled-components'

const Tags = styled.div`
	.ReactTags__tag {
		display: inline-block;
		padding: 6px 13px;
		margin: 0 10px 10px 0;
		border-radius: 14px;
		background-image: linear-gradient(to right, #d4edf9, #d4f7f7);
		font-size: 14px;
		color: #0f2d49;		
	}
	.ReactTags__suggestions {
		display: fixed;
		padding: 3px;
	}
	.ReactTags__suggestions > ul {
		border: solid 1px #eaf6fc;
		border-radius: 3px;
		background-color: #ffffff;
		box-shadow: 0 1px 3px 0 rgba(159, 171, 182, 0.5);
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.ReactTags__suggestions > ul > li {
		padding: 10px;
	}
	.ReactTags__suggestions mark {
		background-color: #aaefef;
	}
	.ReactTags__tagInput {
		display: block;
	}
	.ReactTags__tagInput > input {
		width: 150px;
		border: none;
		background: #eaf6fc;
		padding: 6px 13px;
		margin: 0 10px 10px 0;
		border-radius: 14px;
		caret-color: #FF475F;
		color: #6f8191;
	}
	.ReactTags__activeSuggestion,
	.ReactTags__suggestions > ul > li:focus,
	.ReactTags__suggestions > ul > li:active {
		background-color: #eaf6fc;
	}
`

export default Tags
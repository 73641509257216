import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SuccessIcon from '../assets/images/status-rolldown--success.svg'
import CloseIcon from '../assets/images/remove-white.svg'

const StatusRolldownCont = styled.div.attrs(({ isOpen }) => ({
  top: isOpen ? "0px" : "-90px",
}))`
  display: grid;
  grid-template-columns: 45px auto 45px;
  align-items: center;

  position: fixed;
  box-sizing: border-box;
  z-index: 155;
  width: 100%;
  height: 66px;
  left: 0;
  top: ${props => props.top};
  overflow: hidden;
  padding: 10px;
  background-image: linear-gradient(to right, #5fe898, #37e37f);
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  color: #FFF;

  & > img {
    margin-left: 10px; 
    box-shadow: 0px 3px 2px 1px rgba(0,0,0,.1);
    border-radius: 100%;
  }
  & > span {
    padding-left: 15px;
  }
`

const Button = styled.button`
  & > img {
    width: 24px;
    height: 25px;
  }
`

const StatusRolldown = (props) => {
  const isOpen = props.isOpen
  const text = props.text
  return (
    <StatusRolldownCont isOpen={isOpen}>
      <img src={SuccessIcon} alt="Status Message" /><span>{text}</span><Button onClick={props.handleClose}><img src={CloseIcon} alt="Close" /></Button>
    </StatusRolldownCont>
  )
}

export default StatusRolldown

StatusRolldown.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
}

StatusRolldown.defaultProps = { 
  isOpen: false,
  text: "",
}
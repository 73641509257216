import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Carousel from 'nuka-carousel'
import Markdown from 'react-remarkable';

import { Query } from "react-apollo"
import GET_PROFILE from './Queries/GET_PROFILE'

// Components
import InitialsOrImage from './InitialsOrImage'
import PopOverBack from './PopOverBack'
import WorkHistoryEdit from './WorkHistoryEdit'
import EducationEdit from './EducationEdit'
import SkillsEdit from './SkillsEdit'
import HobbiesEdit from './HobbiesEdit'
import BasicProfileEdit from './BasicProfileEdit'
import StatusRolldown from './StatusRolldown'

// Images
import JobTypeIcon from '../assets/images/profile-job-type-turquoise.svg'
import NoticeIcon from '../assets/images/profile-notice-turquoise.svg'
import EditIcon from '../assets/images/edit-circle.svg'
import Edit2Icon from '../assets/images/edit-no-circle.svg'
import addIcon from '../assets/images/add.svg'

const isRecruiter = (user_type) => user_type === "RECRUITER"

const ProfileCont = styled.div`
	height: 100%;
	padding: 15px 15px 40px;
	box-sizing: border-box;
	overflow: scroll;
	color: #0f2d49;
	font-size: 14px;
`

const ProfileHead = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  margin-bottom: 15px;

  h1 {
  	font-size: 18px;
  	margin: 5px 0 3px;
  }
`

const ProfileHeadData = styled.div`
	margin-left: 15px;
`

const ProfileHeadCurrentJob = styled.div`
	font-size: 14px;
`

const ProfileHeadCurrentCompany = styled.div`
	margin-top: 10px;
	color: #6f8191;
`

const ProfileMeta = styled.div`
	margin: 0 0 25px;
	padding: 0px 15px;

	img {
		width: 18px;
		height: 18px;
    margin: 14px 6px -3px 0;
	}
`

const Capitalize = styled.span`
	text-transform: capitalize;
`

const Section = styled.div.attrs(({ visible }) => ({
  display: visible ? "block" : "none",
}))`
	display: ${props => props.display};
`

const SectionCont = styled.div`

	& > p {
		color: #3f576d;
		line-height: 1.5;
	}
`

const SectionTitle = styled.h2`
	margin: 15px 0;
	font-size: 14px;
	font-weight: 700;
`

const SectionSubTitle = styled.h2`
	margin: 15px 0;
	font-size: 14px;
	font-weight: 500;
	color: #0f2d49;
`

const PopOverFixedBottom = styled.div`
	& > .slider > .slider-frame {
	  overflow: visible !important;
	}
`

const CarouselCont = styled.div`
	clear: both;
	& > .slider > .slider-frame {
		margin: 0 -15px !important;
	}	
`

const WorkhistoryItem = (props) => {
	const workhistory = props.workhistory
	const start_dt = workhistory.start_dt ? moment(workhistory.start_dt).format("MMM YYYY") : null
	const end_dt = workhistory.end_dt ? moment(workhistory.end_dt).format("MMM YYYY") : null
	var dateDivider = ""
	if (start_dt && end_dt)
		dateDivider = " - "
	return (
  	<div>
  		<ExperienceDate>{start_dt}{dateDivider}{end_dt}</ExperienceDate>
    	<ExperienceTitle>{workhistory.work_title}</ExperienceTitle>
      <ExperienceCompany>{workhistory.company_name}</ExperienceCompany>
      <ExperienceComments><Markdown>{removeSpaces((props.preview && workhistory.comments) ? `${workhistory.comments.substring(0, 80)} ...` : workhistory.comments)}</Markdown></ExperienceComments>
      <ReadMore preview={props.preview}>Read more</ReadMore>
    </div>
	)
}

const ExperienceDate = styled.div`
	margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #6f8191;
`

const ExperienceTitle = styled.div`
	margin-bottom: 3px;
  font-size: 14px;
  font-weight: 700;
  color: #0f2d49;
`

const ExperienceCompany = styled.div`
  font-size: 14px;
  color: #3f576d;
`

const ExperienceComments = styled.div`
	margin: 1em 0;
  font-size: 14px;
  color: #3f576d;
  line-height: 1.5;

	ul {
		padding-left: 20px;
		list-style: disc;
		line-height: 1.5;
	}
`
const ReadMore = styled.span.attrs(({ preview }) => ({
  display: preview ? "block" : "none",
}))`
	display: ${props => props.display};
	position: absolute;
	right: 15px;
	bottom: 10px;
	font-size: 14px;
	color: #ff475f;
`

const SectionHR = styled.hr`
	margin-bottom: 10px;
	border: none;
	border-bottom: 1px solid #e7eaed;
`

const SkillsCont = styled.div`
	margin-bottom: 10px;
`

const EducationItem = (props) => {
	const education = props.education
	const start_dt = education.start_dt ? moment(education.start_dt).format("MMM YYYY") : null
	const end_dt = education.end_dt ? moment(education.end_dt).format("MMM YYYY") : null
	var dateDivider = ""
	if (start_dt && end_dt)
		dateDivider = " - "
	return (
  	<div>
  		<EducationDate>{start_dt}{dateDivider}{end_dt}</EducationDate>
    	<EducationTitle>{education.level_description}</EducationTitle>
    	<EducationLevel>{education.level}</EducationLevel>
    	<EducationInstitution>{education.institute_name}</EducationInstitution>
    	<EducationComments><Markdown>{removeSpaces((props.preview && education.description) ? `${education.description.substring(0, 80)} ...` : education.description)}</Markdown></EducationComments>
      <ReadMore preview={props.preview}>Read more</ReadMore>
    </div>
	)
}

const EducationDate = styled(ExperienceDate)`
`

const EducationTitle = styled(ExperienceTitle)`
`

const EducationLevel = styled(ExperienceCompany)`
`

const EducationInstitution = styled.div`
  font-size: 14px;
  color: #0f2d49;
`

const EducationComments = styled(ExperienceComments)`
`

const Tag = styled.span`
	display: inline-block;
	padding: 6px 13px;
	margin: 0 10px 10px 0;
	border-radius: 14px;
	background-image: linear-gradient(to right, #d4edf9, #d4f7f7);
	font-size: 14px;
	color: #0f2d49;
`

const EditSkillButton = styled.span`
	margin-top: -4px;
	float: right;
`

const AddEducationButton = styled.span`
	margin-top: 4px;
	float: right;
`

const EditWorkHistoryButton = styled.span`
	position: absolute;
	top: 10px;
	right: 10px;
`

const EditWorkHistoryButtonImageSmall = styled.img`
	width: 18px;
	height: 18px;
`

const EditBasicProfileButton = styled.span`
	float: right;
	margin: 40px -5px 0 0;
`

const removeSpaces = (string) => (string) ? string.replace(/\s\s+/g, ' ') : '' 

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    	isBasicProfileEditOpen: false,
      isWorkHistoriesOpen: false,
      isWorkHistoriesEditOpen: false,
      workHistorySelected: null,
      isEducationsOpen: false,
      isEducationsEditOpen: false,
      educationSelected: null,
      isSkillsOpen: false,
      ishobbiesOpen: false,
      skillTypeSelected: null,
      whSlideIndex: 0,
      eduSlideIndex: 0,
      skillSlideIndex: 0,
      isRolldownOpen: false,
      rolldownText: '',
      width: 0,
      height: 0,
    }
  	this.handleBasicProfileEditOpen = this.handleBasicProfileEditOpen.bind(this)
    this.handleWorkHistoriesOpen = this.handleWorkHistoriesOpen.bind(this)
    this.handleWorkHistoriesEditOpen = this.handleWorkHistoriesEditOpen.bind(this)
    this.handleEducationsOpen = this.handleEducationsOpen.bind(this)
    this.handleEducationsEditOpen = this.handleEducationsEditOpen.bind(this)
    this.handleSkillsOpen = this.handleSkillsOpen.bind(this)
    this.handleHobbiesOpen = this.handleHobbiesOpen.bind(this)
    this.handleRolldown = this.handleRolldown.bind(this)
    this.handleRolldownClose = this.handleRolldownClose.bind(this)
  }

	componentDidMount() {
	  this.updateWindowDimensions();
	}

  handleBasicProfileEditOpen() {
    this.setState({ 
      isBasicProfileEditOpen: !this.state.isBasicProfileEditOpen,
    })
  }

  handleWorkHistoriesOpen() {
    this.setState({ 
      isWorkHistoriesOpen: !this.state.isWorkHistoriesOpen,
    })
  }

  handleWorkHistoriesEditOpen(workhistory = undefined) {
    this.setState({ 
      workHistorySelected: workhistory,
      isWorkHistoriesEditOpen: !this.state.isWorkHistoriesEditOpen,
    })
  }

  handleEducationsOpen() {
    this.setState({ 
      isEducationsOpen: !this.state.isEducationsOpen,
    })
  }

  handleEducationsEditOpen(education = undefined) {
    this.setState({ 
      educationSelected: education,
      isEducationsEditOpen: !this.state.isEducationsEditOpen,
    })
  }

  handleSkillsOpen(skills) {
    this.setState({ 
      isSkillsOpen: !this.state.isSkillsOpen,
      skillTypeSelected: skills
    })
  }

  handleHobbiesOpen() {
    this.setState({ 
      ishobbiesOpen: !this.state.ishobbiesOpen,
    })
  }

  handleRolldown(text) {
    this.setState({ 
      isRolldownOpen: !this.state.isRolldownOpen,
      rolldownText: text
    })
    setTimeout(function() {
      this.setState({isRolldownOpen: false})
    }.bind(this), 3000)
  }

  handleRolldownClose() {
    this.setState({ 
      isRolldownOpen: false,
    })
  }

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

  render() {

		const { profileId, user_type } = this.props
		if (profileId)
			return (
			  <Query query={GET_PROFILE} variables={{ profileId }} fetchPolicy="network-only">
			    {({ loading, error, data, refetch }) => {
			      if (error) return `Error! ${error.message}`
						if (data.getProfile) {
							const { skillTypeSelected, workHistorySelected, educationSelected } = this.state
							const workhistoriesSorted = (data.getProfile.workhistories && data.getProfile.workhistories.length > 0) ? data.getProfile.workhistories.sort((a, b) => b.start_dt-a.start_dt) : []
							const educationsSorted = (data.getProfile.educations && data.getProfile.educations.length > 0) ? data.getProfile.educations.sort((a, b) => b.start_dt-a.start_dt) : []
							const computerSkills = data.getProfile.skills.filter( skill => skill.skill_type === "COMPUTER")
							const softSkills = data.getProfile.skills.filter( skill => skill.skill_type === "SOFT")
							const languageSkills = data.getProfile.skills.filter( skill => skill.skill_type === "LANGUAGE")
							const otherSkills = data.getProfile.skills.filter( skill => skill.skill_type === "OTHER")
							const carouselOpenedWidth = (this.state.width > 600) ? 400 : this.state.width - 45
							const { poolId, email, name, current_job_title, notice_period, job_type, recruiter_comments, created_dt } = data.getProfile
				      return (
				        <ProfileCont name="profile">
						      { isRecruiter(user_type) ? <EditBasicProfileButton onClick={ () => this.handleBasicProfileEditOpen() }><img src={EditIcon} alt="Edit Basic Information" /></EditBasicProfileButton> : null }
				        	<ProfileHead>
					        	<InitialsOrImage backgroundColor="#e4fc86" name={name} size="large" />
					        	<ProfileHeadData>
							      	<h1>{name}</h1>
							      	<ProfileHeadCurrentJob>{data.getProfile.current_job_title}</ProfileHeadCurrentJob>
							      	<ProfileHeadCurrentCompany></ProfileHeadCurrentCompany>
							      </ProfileHeadData>
							    </ProfileHead>
						      <SectionHR />
							    <ProfileMeta>
					          <div><img src={NoticeIcon} alt="Notice Period" /> {notice_period} Notice Period</div>
					          <div><img src={JobTypeIcon} alt="Job Type" /> <Capitalize>{job_type.toLowerCase()}</Capitalize></div>
					        </ProfileMeta>
						      <SectionHR />
					        <SectionCont>
						        <SectionTitle>Recruiter Comments</SectionTitle>
						        <p>{recruiter_comments}</p>
						      </SectionCont>
						      <SectionHR />
	            		{ isRecruiter(user_type) ? <EditSkillButton onClick={ () => this.handleWorkHistoriesEditOpen() }><img src={addIcon} alt="Create New Work History" /></EditSkillButton> : null }
					        <SectionTitle>Experience</SectionTitle>
					        <CarouselCont>
					        	{ workhistoriesSorted.length > 0 ? 
						        <Carousel slideWidth="300px" withoutControls={true} slideIndex={this.state.whSlideIndex} afterSlide={whSlideIndex => this.setState({ whSlideIndex })}>
				            {workhistoriesSorted.map((workhistory, index) => (
				            	<React.Fragment key={index}>
				            		{ isRecruiter(user_type) ? <EditWorkHistoryButton onClick={ () => this.handleWorkHistoriesEditOpen(workhistory) }><EditWorkHistoryButtonImageSmall src={Edit2Icon} alt="Edit Work History" /></EditWorkHistoryButton> : null }
					            	<CarouselItem isOpen={this.state.isWorkHistoriesOpen} handleOpen={this.handleWorkHistoriesOpen}>
													<WorkhistoryItem preview={true} workhistory={workhistory} />
												</CarouselItem>
											</React.Fragment>
				            ))}
				            </Carousel> : null }
			            </CarouselCont>
	            		{ isRecruiter(user_type) ? <AddEducationButton onClick={ () => this.handleEducationsEditOpen() }><img src={addIcon} alt="Create New Education" /></AddEducationButton> : null }
					        <SectionTitle>Education</SectionTitle>
					        <CarouselCont>
					        	{ educationsSorted.length > 0 ? 
						        <Carousel slideWidth="300px" withoutControls={true} slideIndex={this.state.eduSlideIndex} afterSlide={eduSlideIndex => this.setState({ eduSlideIndex })}>
				            {educationsSorted.map((education, index) => (
				            	<React.Fragment key={index}>
					            	<CarouselItem isOpen={this.state.isEducationsOpen} handleOpen={this.handleEducationsOpen}>
													<EducationItem preview={true} education={education} key={index} />
												</CarouselItem>
				            		{ isRecruiter(user_type) ? <EditWorkHistoryButton onClick={ () => this.handleEducationsEditOpen(education) }><EditWorkHistoryButtonImageSmall src={Edit2Icon} alt="Edit Education" /></EditWorkHistoryButton> : null }
				            	</React.Fragment>
				            ))}
				            </Carousel>
				            : null }
					        </CarouselCont>

					        <SectionTitle>Skills</SectionTitle>
					        <SectionHR />
					        { isRecruiter(user_type) ? <EditSkillButton onClick={ () => this.handleSkillsOpen("COMPUTER") }><img src={EditIcon} alt="Edit Skills" /></EditSkillButton> : null }
					        <SectionSubTitle>Computer</SectionSubTitle>
					        <SkillsCont>{computerSkills.slice(0, 8).map((hobby, index) => (
					        		<Tag key={index}>{hobby.name}</Tag>
					        	))}
					        </SkillsCont>
					        <SectionHR />
					        { isRecruiter(user_type) ? <EditSkillButton onClick={ () => this.handleSkillsOpen("LANGUAGE") }><img src={EditIcon} alt="Edit Skills" /></EditSkillButton> : null }
					        <SectionSubTitle>Languages</SectionSubTitle>
					        <SkillsCont>{languageSkills.slice(0, 8).map((hobby, index) => (
					        		<Tag key={index}>{hobby.name}</Tag>
					        	))}
					        </SkillsCont>
					        <SectionHR />
					        { isRecruiter(user_type) ? <EditSkillButton onClick={ () => this.handleSkillsOpen("SOFT") }><img src={EditIcon} alt="Edit Skills" /></EditSkillButton> : null }
					        <SectionSubTitle>Personal</SectionSubTitle>
					        <SkillsCont>{softSkills.slice(0, 8).map((hobby, index) => (
					        		<Tag key={index}>{hobby.name}</Tag>
					        	))}
					        </SkillsCont>
					        <SectionHR />
					        { isRecruiter(user_type) ? <EditSkillButton onClick={ () => this.handleSkillsOpen("OTHER") }><img src={EditIcon} alt="Edit Skills" /></EditSkillButton> : null }
					        <SectionSubTitle>Other</SectionSubTitle>
					        <SkillsCont>{otherSkills.slice(0, 8).map((hobby, index) => (
					        		<Tag key={index}>{hobby.name}</Tag>
					        	))}
					        </SkillsCont>
					        <SectionHR />

									<Section visible={(data.getProfile.hobbies.length > 0 || isRecruiter(user_type))}>
						        { isRecruiter(user_type) ? <EditSkillButton onClick={this.handleHobbiesOpen}><img src={EditIcon} alt="Edit Hobbies" /></EditSkillButton> : null }
						        <SectionTitle>Interests</SectionTitle>
						        <div>{data.getProfile.hobbies.map((hobby, index) => (
						        		<Tag key={index}>{hobby.name}</Tag>
						        	))}
						        </div>
						      </Section>

			            <PopOverBack isOpen={this.state.isWorkHistoriesOpen} handleClose={this.handleWorkHistoriesOpen}>
			            	<PopOverFixedBottom slideIndex={this.state.whSlideIndex}>
							        <Carousel slideWidth={`${carouselOpenedWidth}px`} withoutControls={true} slideIndex={this.state.whSlideIndex} afterSlide={whSlideIndex => this.setState({ whSlideIndex })}>
					            {workhistoriesSorted.map((workhistory, index) => (
					            	<React.Fragment key={index}>
						            	<CarouselItem isOpen={true}>
														<WorkhistoryItem preview={false} workhistory={workhistory} />
													</CarouselItem>
					            		{ isRecruiter(user_type) ? <EditWorkHistoryButton onClick={ () => this.handleWorkHistoriesEditOpen(workhistory) }><EditWorkHistoryButtonImageSmall src={Edit2Icon} alt="Edit Work History" /></EditWorkHistoryButton> : null }
												</React.Fragment>
					            ))}
					            </Carousel>
				            </PopOverFixedBottom>
			            </PopOverBack>

			            <PopOverBack isOpen={this.state.isEducationsOpen} handleClose={this.handleEducationsOpen}>
			            	<PopOverFixedBottom slideIndex={this.state.eduSlideIndex}>
							        <Carousel slideWidth={`${carouselOpenedWidth}px`} withoutControls={true} slideIndex={this.state.eduSlideIndex} afterSlide={eduSlideIndex => this.setState({ eduSlideIndex })}>
					            {educationsSorted.map((education, index) => (
					            	<React.Fragment key={index}>
						            	<CarouselItem isOpen={true}>
														<EducationItem preview={false} education={education} key={index} />
													</CarouselItem>
													{ isRecruiter(user_type) ? <EditWorkHistoryButton onClick={ () => this.handleEducationsEditOpen(education) }><EditWorkHistoryButtonImageSmall src={Edit2Icon} alt="Edit Education" /></EditWorkHistoryButton> : null }
												</React.Fragment>
					            ))}
					            </Carousel>
			            	</PopOverFixedBottom>
			            </PopOverBack>

			            <PopOverBack isOpen={this.state.isBasicProfileEditOpen} handleClose={this.handleBasicProfileEditOpen}>
			            	{ this.state.isBasicProfileEditOpen ?
						        	<BasicProfileEdit 
						        		profileId={profileId} 
						        		name={name} 
						        		email={email} 
						        		poolId={poolId} 
						        		current_job_title={current_job_title} 
						        		job_type={job_type} 
						        		notice_period={notice_period} 
						        		recruiter_comments={recruiter_comments} 
						        		created_dt={created_dt} 
						        		handleClose={this.handleBasicProfileEditOpen} 
						        		handleRolldown={this.handleRolldown}
						        	/>
			            	: null}
			            </PopOverBack>

			            <PopOverBack isOpen={this.state.isWorkHistoriesEditOpen} handleClose={this.handleWorkHistoriesEditOpen} withConfirm={true}>
			            	{ this.state.isWorkHistoriesEditOpen ?
						        	<WorkHistoryEdit workhistory={workHistorySelected} profileId={profileId} handleClose={this.handleWorkHistoriesEditOpen} handleRolldown={this.handleRolldown} />
			            	: null}
			            </PopOverBack>

			            <PopOverBack isOpen={this.state.isEducationsEditOpen} handleClose={this.handleEducationsEditOpen} withConfirm={true}>
			            	{ this.state.isEducationsEditOpen ?
						        	<EducationEdit education={educationSelected} profileId={profileId} handleClose={this.handleEducationsEditOpen} handleRolldown={this.handleRolldown} />
			            	: null}
			            </PopOverBack>

			            <PopOverBack isOpen={this.state.isSkillsOpen} handleClose={this.handleSkillsOpen}>
			            	{ this.state.isSkillsOpen ?
						        	<SkillsEdit skills={data.getProfile.skills} skillType={skillTypeSelected} profileId={profileId} />
			            	: null}
			            </PopOverBack>

			            <PopOverBack isOpen={this.state.ishobbiesOpen} handleClose={this.handleHobbiesOpen}>
			            	{ this.state.ishobbiesOpen ?
						        	<HobbiesEdit hobbies={data.getProfile.hobbies} profileId={profileId} />
			            	: null}
			            </PopOverBack>

									<StatusRolldown isOpen={this.state.isRolldownOpen} text={this.state.rolldownText} handleClose={this.handleRolldownClose} />

				        </ProfileCont>
				      )
				    }
			      if (loading) return ``
			      return <button onClick={() => refetch()}>Retry Loading Profile</button>
			    }}
			  </Query>
			)
		else
			return ''
	}
}

const CarouselItemStyled = styled.div.attrs(({ isOpen }) => ({
  height: isOpen ? "auto" : "140px",
  overflow: isOpen ? "scroll" : "hidden"
}))`
	height: ${props => props.height};
	max-height: 450px;
	min-height: 140px;
	overflow: ${props => props.overflow};
	border-radius: 5px;
	margin-left: 15px;
	padding: 15px;
	box-shadow: 1px 1px 4px 0 #cfd5da;
  border: solid 1px #d4edf9;
`

const CarouselItem = (props) => {
  return (
    <CarouselItemStyled isOpen={props.isOpen}>
    	<div onClick={props.handleOpen}>
    		{props.children}
    	</div>
    </CarouselItemStyled>
  )
}


export default Profile
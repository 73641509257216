import styled from 'styled-components'
import CloseIcon from '../../assets/images/down-arrow.svg'

const Select = styled.select.attrs(({ hasError }) => ({
  borderColor: hasError ? "red" : "#7FCBEE",
}))`
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 15px;
  background: url(${CloseIcon}) no-repeat right 20px top 17px;
  border: 1px solid ${props => props.borderColor};
  box-shadow: 1px 1px 4px 0 #E7EAED;
  box-sizing: border-box;
  border-radius: 27px;
  font-size: 14px;
  color: #0f2d49;
  letter-spacing: 0;
  line-height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`

export default Select
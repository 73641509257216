import gql from 'graphql-tag';

export default gql`
  query GetIdentity {
    getIdentity {
      username
      user_type
      first_name
      pools {
        poolId
      }
    }  
  }
 `
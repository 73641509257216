import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import InitialsOrImage from "./InitialsOrImage"

const CollaboratorsStyled = styled.div`
	float: right;

	span {
		float: right;
		margin-right: -3px;		
	}

`

const CollaboratorCount = (props) => {
	const count = props.count
	return (
			<InitialsOrImage name={`+${count}`} size="small" backgroundColor="#90ffdc" />
	)
}

const Collaborator = (props) => {
	const identity = props.identity
	const name = (identity.first_name && identity.last_name) ? `${identity.first_name} ${identity.last_name}` : identity.username
	return (
		<InitialsOrImage name={name} size="small" />
	)
}

export class Collaborators extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: false,
      canSubmit: true,
    }
  }

  submitForm(event) {


  }
  render() {
    const identities = this.props.identities
    const identitiesCount = identities.length
    if (identities) 
    	if (identitiesCount > 3) {
		    return (
		      <CollaboratorsStyled>
						<Collaborator identity={identities[0]} />
						<Collaborator identity={identities[1]} />
						<Collaborator identity={identities[2]} />
		      	<CollaboratorCount count={identitiesCount} />
		      </CollaboratorsStyled>
		    )
		  } else {
		  	return (
		      <CollaboratorsStyled>
						{identities.map(identity => (
						  <Collaborator identity={identity} key={identity.identityId} />
						))}
		      </CollaboratorsStyled>
				)  	
		  }
	  return ''
  }
}


Collaborators.propTypes = {
  identities: PropTypes.array
}

Collaborators.defaultProps = { 
	identities: [{
		first_name: '',
		last_name: '',
		identityId: '1'
	}]
}

export default Collaborators
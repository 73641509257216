import React from "react";
import styled from "styled-components";
import moment from "moment";
import { isNewDay } from "../../helpers/messages-functions";

const NewDayStyled = styled.div`
  position: relative;
  clear: both;
  width: 100%;
  padding: 30px 0 10px;
  color: #ccc;
  text-align: center;
  font-weight: 600;
  font-size: 14px;

  > span {
    position: relative;
    padding: 10px 12px;
    background: white;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 37px;
    height: 1px;
    width: 100%;
    background: #ccc;
  }
`;

const NewDayDivider = props => {
  const { currentDate, previousDate } = props;
  const currentDateFormated = currentDate
    ? moment(currentDate).format("dddd, MMMM Do")
    : null;
  if (isNewDay(currentDate, previousDate))
    return (
      <NewDayStyled>
        <span data-testid="day">{currentDateFormated}</span>
      </NewDayStyled>
    );
  else return null;
};

export default NewDayDivider;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Images
import JobPoolIcon from "../assets/images/job-pool-icon-turquoise.svg";
import UserIcon from "../assets/images/user-icon-turquoise.svg";
import Logo from "../assets/images/logo.svg";

// Data
import packageJson from "../../package.json";

const MenuStyled = styled.div.attrs(({ isDisplayed }) => ({
  left: isDisplayed ? "0px" : "-301px"
}))`
  position: fixed;
  top: 0;
  z-index: 10;
  left: ${props => props.left};
  width: 250px;
  height: 100%;
  background: #fafafa;
  box-shadow: 1px 1px 2px 0 rgba(15, 45, 73, 0.2);
  transition: left 0.3s ease;
`;

const LogoCont = styled.div`
  padding: 13px 10px 12px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(15, 45, 73, 0.1);
`;

const MenuUl = styled.ul`
  list-style: none;
  margin-top: 40px;
  padding-left: 20px;
`;

const MenuLi = styled.li`
  padding: 0 0 10px;

  a {
    color: #0f2d49;
    font-size: 14px;
    text-decoration: none;
  }

  img {
    margin-bottom: -5px;
    padding-right: 5px;
  }
`;

const VersionNumber = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #999;
  font-size: 12px;
`;

const Menu = props => {
  const menuOpen = props.menuOpen;

  return (
    <MenuStyled isDisplayed={menuOpen}>
      <LogoCont>
        <img src={Logo} alt="Galaxy Nova" />
      </LogoCont>

      <MenuUl>
        <MenuLi>
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={JobPoolIcon} alt="Talent Pools" /> Talent Pools
          </Link>
        </MenuLi>
        <MenuLi>
          <Link to="/account" onClick={() => window.scrollTo(0, 0)}>
            <img src={UserIcon} alt="My Account" /> My Account
          </Link>
        </MenuLi>
      </MenuUl>

      <VersionNumber>Version: {packageJson.version}</VersionNumber>
    </MenuStyled>
  );
};

Menu.propTypes = {
  menuOpen: PropTypes.bool
};

Menu.defaultProps = {
  menuOpen: true
};

export default Menu;

import gql from 'graphql-tag';

export default gql`
	mutation AddEducation(
		$profileId: ID!,
		$educationId: ID,
		$institute_name: String,
		$institute_place: String,
		$description: String,
		$start_dt: AWSTimestamp,
		$end_dt: AWSTimestamp,
		$level: String,
		$level_description: String)
	{
		addEducation(
			profileId: $profileId,
			educationId: $educationId,
			institute_name: $institute_name,
			institute_place: $institute_place,
			description: $description,
			start_dt: $start_dt,
			end_dt: $end_dt,
			level: $level,
			level_description: $level_description,
			db_candidate_id: "123")
		{
			educationId
			institute_name
			institute_place
			description
			start_dt
			end_dt
			level
			level_description
		}
	}
`
import React from 'react';
import { Link } from 'react-router-dom';
import AddProfile from '../../Components/AddProfile'

const AddProfilePage = (props) => {
	const poolId = props.match.params.poolId
  return (
  	<div className="profile-page">
  		<p>
        <Link to={`/pool/${poolId}`}>
          Back to Pool
        </Link>     
  		</p>
      <h1>Add Profile to Pool</h1>
      <AddProfile poolId={poolId} />
    </div>
  )
}

export default AddProfilePage
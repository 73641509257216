import styled from 'styled-components'

const Button = styled.button.attrs(({ isFilled, color }) => {
	const buttonColor = color ? color : "#FF475F"
	return({
		buttonColor: buttonColor,
		background: isFilled ? buttonColor : "transparent",
		color: isFilled ? "#FFF" : buttonColor,
		backgroundHover: isFilled ? "transparent" : buttonColor,
		colorHover: isFilled ? buttonColor : "#FFF"
	})
})`
	margin: 10px 0;
	padding: 10px 15px;
	background: ${props => props.background};
	border: 1px solid ${props => props.buttonColor};
	border-radius: 20px;
	font-weight: 500;
	font-size: 15px;
	color: ${props => props.color};

	&:hover {
		background: ${props => props.backgroundHover};
		color: ${props => props.colorHover};		
	}

`

export default Button
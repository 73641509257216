import gql from 'graphql-tag';

export default gql`
	mutation AddWorkHistory(
		$profileId: ID!, 
		$workhistoryId: ID, 
		$company_name: String, 
		$work_title: String, 
		$start_dt: AWSTimestamp, 
		$end_dt: AWSTimestamp, 
		$comments: String) 
		{
			addWorkHistory(
				profileId: $profileId, 
				workhistoryId: $workhistoryId, 
				company_name: $company_name, 
				work_title: $work_title, 
				start_dt: $start_dt, 
				end_dt: $end_dt, 
				comments: $comments, 
				db_candidate_id: "123"
			) {
				workhistoryId
				company_name
				work_title
				start_dt
				end_dt
				comments
		}
	}
`
import React from 'react'
import { Storage, AES256 } from 'aws-amplify'
import styled from 'styled-components'

// APOLLO/GRAPHQL
import { Query, Mutation } from "react-apollo"
import GET_PARSED_CV from './Queries/GET_PARSED_CV'
import ADD_PROFILE from './Queries/ADD_PROFILE'
import ADD_MESSAGE from './Queries/ADD_MESSAGE'

// UI Componenets
import TextInput from './UI/TextInput'
import Label from './Typography/Label'
import SubmitButton from './UI/SubmitButton'
import CancelButton from './UI/CancelButton'
import Select from './UI/Select'

import StatusRollup from './StatusRollup'

// Skeleton loading blocks
import { SkeletonInput, SkeletonLabel } from './UI/Skeleton'

const LoadingBar = (props) => {
  const uploading = props.uploading
  if (uploading) 
    return <LoadingBarActive />
  else
    return <LoadingBarIdle />
}

const LoadingBarIdle = styled.div`
  width: 100%;
  height: 5px;
  background: #e7eaed;
`

const LoadingBarActive = styled.div`
  background: repeating-linear-gradient(to right, #80e7e7 0%, #55bae8 50%, #80e7e7 100%);
  width: 100%;
  height: 5px;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  @keyframes gradient { 
    0%   { background-position: 0 0; }
    100% { background-position: -200% 0; }
  }
`

const AddProfileCont = styled.div`
  display: block;
`

const UploadUploadingText = styled.span`
  font-size: 12px;
  color: #0f2d49;
`

const UploadErrorText = styled.span`
  font-size: 12px;
  color: #f20000;
`

const UploadButton = styled.button.attrs(({ isDisabled }) => ({
  backgroundColor: isDisabled ? "#ffdadf" : "#ff475f"
}))`
  display: block;
  margin: 30px auto;
  padding: 12px 30px;
  background: ${props => props.backgroundColor};
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #FFF;
`

const FileInput = styled.input`
  color: transparent;
  width: 100%;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Choose file';
    display: block;
    width: 140px;
    box-sizing: border-box;
    margin: 10px auto;
    padding: 12px 30px;
    background: white;
    border: 1px solid #2ba9e3;
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: #2ba9e3;
  }
  &:hover::before {
    border-color: #2ba9e3;
  }
  &:active::before {
    background: white;
  }
`

const CancelButtonStyled = styled(CancelButton)`
  float: left;
  margin: 0;
`

const SubmitButtonStyled = styled(SubmitButton)`
  float: right;
  margin: 0;
`

class S3CVUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uploading: false,
      uploadingText: '',
      uploadingError: false,
      uploadingErrorText: '',
      file: null
    }
    this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  handleFileSelected(e) {
    const file = e.target.files[0];
    if (!file)
      return ''
    const file_size = file.size
    if (file_size > 52428800) {
      this.setState({ 
        uploading: false, 
        uploadingText: '',
        uploadingError: true,
        uploadingErrorText: 'Upload failed. File size is larger than 50mb.'
      })
      return ''    
    }
    this.setState({ uploadingText: `File ${file.name} selected`, file: file })
  }

  handleFileUpload(e) {
    const serverSideEncryption = AES256
    const file = this.state.file;
    const file_name = `${file.lastModified}-${file.name}`

    this.setState({ uploading: true, uploadingText: `Uploading file ${file.name}` })

    Storage.put(file_name, file, {
      contentType: 'application/pdf',
      serverSideEncryption
    })
    .then (result => {
      this.setState({ 
        uploading: false, 
        uploadingText: `File ${file.name} uploaded`,
        uploadingError: false,
        uploadingErrorText: ''
      })
      this.props.handleShowProfileForm(true, `public/${result.key}`)
      this.props.handleChangeAddProfilePopUnderHeight("650px")
    })
    .catch(err => {
      this.setState({ 
        uploading: false, 
        uploadingText: '',
        uploadingError: true,
        uploadingErrorText: 'Upload failed. Network error.'
      })
    })
  }

  render() {
    const uploading = this.state.uploading
    const uploadingText = this.state.uploadingText
    const uploadingErrorText = this.state.uploadingErrorText
    const isFile = !this.state.file ? true : false
    return (
      <div>
        <FileInput
          type="file" accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,text/plain,application/vnd.oasis.opendocument.text'
          onChange={(e) => this.handleFileSelected(e)}
        />
        <LoadingBar uploading={uploading} />
        <UploadUploadingText>{uploadingText}</UploadUploadingText>
        <UploadErrorText>{uploadingErrorText}</UploadErrorText>
        <UploadButton isDisabled={isFile} disabled={isFile} onClick={this.handleFileUpload}>Upload CV</UploadButton>
      </div>
    )
  }
}


class AddProfile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cvUploaded: false,
      s3Key: '',
    }
    this.handleShowProfileForm = this.handleShowProfileForm.bind(this)
    this.handleCancelUploaded = this.handleCancelUploaded.bind(this)
    this.handleFinishedUploaded = this.handleFinishedUploaded.bind(this)
  }

  handleShowProfileForm(cvUploaded, s3Key) {
    this.setState({ 
      cvUploaded, 
      s3Key
    })
  }

  handleCancelUploaded() {
    this.props.handleChangeAddProfilePopUnderHeight("300px")
    this.setState({ 
      cvUploaded: false
    })
  }

  handleFinishedUploaded() {
    this.props.handleChangeAddProfilePopUnderHeight("300px")
    this.setState({ 
      cvUploaded: false,
      s3Key: ''
    })
  }

  render() {
    const handleChangeAddProfilePopUnderHeight = this.props.handleChangeAddProfilePopUnderHeight
    const handleCancelUploaded = this.handleCancelUploaded
    const handleFinishedUploaded = this.handleFinishedUploaded
    const props = this.props
    let email, name, recruiter_comments, notice_period, current_job_title, job_type
    const { cvUploaded, s3Key } = this.state
    const poolId = props.poolId
    const GET_POOL = props.GET_POOL
    const handleClose = props.handleClose

    if (!cvUploaded) {
      return <S3CVUpload handleChangeAddProfilePopUnderHeight={handleChangeAddProfilePopUnderHeight} handleShowProfileForm={this.handleShowProfileForm} />
    } else {
      return (
        <Mutation 
          mutation={ADD_PROFILE}
          >
          {(addProfile, { data }) => (

            <Mutation 
              mutation={ADD_MESSAGE}
              >
              {(addMessage, { data }) => (

                <AddProfileCont>

                  <Query query={GET_PARSED_CV} variables={{ s3Key }} fetchPolicy="no-cache">
                    {({ loading, error, data, refetch, subscribeToMore }) => {
                      if (error) return `Error! ${error.message}`
                      if (data.getParsedCV) {
                        return (

                          <form
                            onSubmit={e => {
                              e.preventDefault()
                              handleClose()

                              const workhistoriesInput = data.getParsedCV.workhistories.map((acc) => {
                                const { __typename, ...workhistories } = acc
                                return workhistories;
                              })

                              const educationsInput = data.getParsedCV.educations.map((acc) => {
                                const { __typename, ...educations } = acc
                                return educations;
                              })

                              const skillsInput = data.getParsedCV.skills.map((acc) => {
                                const { __typename, ...skills } = acc
                                return skills;
                              })

                              const hobbiesInput = data.getParsedCV.hobbies.map((acc) => {
                                const { __typename, ...hobbies } = acc
                                return hobbies;
                              })

                              const profileIdInput = data.getParsedCV.profileId

                              addProfile({ 
                                variables: { 
                                  profileId: profileIdInput,
                                  email: email.value,
                                  name: name.value,
                                  recruiter_comments: recruiter_comments.value, 
                                  notice_period: notice_period.value, 
                                  current_job_title: current_job_title.value,
                                  job_type: job_type.value,
                                  poolId: poolId,
                                  workhistories: workhistoriesInput,
                                  educations: educationsInput,
                                  skills: skillsInput,
                                  hobbies: hobbiesInput
                                },
                                update: (cache, { data: { addProfile } }) => {

                                  const query = GET_POOL
                                  const variables = { poolId: poolId }
                                  const data = cache.readQuery({ query, variables })

                                  if (data.getPool.profileSummaries.filter(e => e.profileId === addProfile.profileId).length <= 0) {
                                    data.getPool.profileSummaries.push(addProfile)
                                    cache.writeQuery({ query, variables, data })
                                  }
                                }
                              })

                              email.value = ""
                              name.value = ""
                              recruiter_comments.value = ""
                              current_job_title.value = ""
                              notice_period.value = ""
                              handleFinishedUploaded()

                            }}
                          >
                            <Label>Candidate Email</Label>
                            <TextInput
                              ref={node => {
                                email = node
                              }}
                              defaultValue={data.getParsedCV.email}
                              required={true}
                            /><br />
                            <Label>Candidate Name</Label>
                            <TextInput
                              ref={node => {
                                name = node
                              }}
                              required={true}
                              defaultValue={data.getParsedCV.name}
                            /><br />
                            <Label>Current Job Title</Label>
                            <TextInput
                              ref={node => {
                                current_job_title = node
                              }}
                              required={true}
                              defaultValue={data.getParsedCV.current_job_title}
                            /><br />
                            <Label>Notice Period</Label>
                            <TextInput
                              ref={node => {
                                notice_period = node
                              }}
                              required={true}
                            /><br />
                            <Label>Job Type</Label>
                            <Select
                              ref={node => {
                                job_type = node
                              }}
                            >
                              <option value="PERMANENT">Permanent</option>
                              <option value="CONTRACT">Contract</option>
                              <option value="TEMPORARY">Temporary</option>
                            </Select><br />

                            <Label>Recruiter Comments</Label>
                            <TextInput
                              ref={node => {
                                recruiter_comments = node
                              }}
                              required={true}
                            /><br />
                            <CancelButtonStyled type="button" onClick={handleCancelUploaded}>Cancel</CancelButtonStyled>
                            <SubmitButtonStyled type="submit">Generate Profile</SubmitButtonStyled>
                          </form>
                        )
                      }
                      if (loading) return (
                        <div>
                          <SkeletonLabel />
                          <SkeletonInput />
                          <SkeletonLabel />
                          <SkeletonInput />
                          <SkeletonLabel />
                          <SkeletonInput />
                          <SkeletonLabel />
                          <SkeletonInput />
                          <SkeletonLabel />
                          <SkeletonInput />
                          <StatusRollup isOpen={true} text="Parsing Experience Details.." />
                        </div>
                      )

                    }}
                  </Query>
                </AddProfileCont>

              )}
            </Mutation>

          )}
        </Mutation>
      )
    }
  }
}

export default AddProfile
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { WithContext as ReactTags } from 'react-tag-input'

// Apollo components
import GET_PROFILE from './Queries/GET_PROFILE'

// Images
import TagRemoveIcon from '../assets/images/tag-remove.svg'

// Languages
import languageSuggestions from '../assets/languages'

const ifLanugageType = (skillType) => {
	return skillType === "LANGUAGE" ? true : false
}

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

const RemoveButton = styled.button`
	height: 14px;
	position: relative;
	top: 1px;
	margin: 0;
	padding: 0 0 0 5px;

	& > img {
		width: 14px;
		height: 14px;		
	}
`

const RemoveComponent = (props) => {
	return (
		<RemoveButton {...props}>
			<img src={TagRemoveIcon} alt="Remove Skill" />
		</RemoveButton>
	)
}

const SkillsEditTags = (props) => {
	const { profileId, skillsForTags, skillType, AddSkill, DeleteSkill, handleInputFocus } = props
	const suggestions = (skillType === "LANGUAGE" ? languageSuggestions : [])

	return (

    <ReactTags 
    	inputFieldPosition="inline"
    	tags={skillsForTags}
    	suggestions={suggestions}
    	allowUnique={false}
    	autocomplete={true}
    	autofocus={true}
    	handleInputFocus={handleInputFocus}
    	delimiters={delimiters}
    	removeComponent={RemoveComponent}
    	handleAddition={ (skill) => AddSkill({ 
  			variables: { 
  				profileId: profileId,
  				name: skill.text,
  				skill_type: skillType,
					languageCode: ifLanugageType(skillType) ? skill.id.slice(0,2) : null,
					proficiencyCode: ifLanugageType(skillType) ? skill.id.slice(3,4) : null,
					proficiencyCodeDescription: ifLanugageType(skillType) ? skill.text.match(/\(([^)]+)\)/)[1] : null,
  			},
        optimisticResponse: {
          __typename: 'Mutation',
          addSkill: {
            __typename: 'Skill',
            profileId: profileId,
            skillId: '',
            objectId: '',
            skill_type: skillType,
            name: skill.text,
            languageCode: ifLanugageType(skillType) ? skill.id.slice(0,2) : null,
            proficiencyCode: ifLanugageType(skillType) ? skill.id.slice(3,4) : null,
            proficiencyCodeDescription: ifLanugageType(skillType) ? skill.text.match(/\(([^)]+)\)/)[1] : null,
          }
        },
        update: (cache, { data: { addSkill } }) => {

          const query = GET_PROFILE
          const variables = { profileId: profileId }
          const data = cache.readQuery({ query, variables })

          if (data.getProfile.skills.filter(e => e.skillId === addSkill.skillId).length <= 0) {
            data.getProfile.skills.push(addSkill)
            cache.writeQuery({ query, variables, data })
          }
        }
  		})}
    	handleDelete={ (i) => DeleteSkill({ 
    		variables: { profileId: profileId, skillId: skillsForTags[i].id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteSkill: {
            __typename: 'Skill',
            skillId: skillsForTags[i].id,
          }
        },
        update: (cache, { data: { deleteSkill } }) => {

          const query = GET_PROFILE
          const variables = { profileId: profileId }
          const data = cache.readQuery({ query, variables })

          if (data.getProfile.skills.filter(e => e.skillId === deleteSkill.skillId).length > 0) {
            const updatedSkills = data.getProfile.skills.filter(e => e.skillId !== deleteSkill.skillId)
            data.getProfile.skills = updatedSkills
            cache.writeQuery({ query, variables, data })
          }
        }
    	})}
     />

	)
}

export default SkillsEditTags

SkillsEditTags.propTypes = {
  profileId: PropTypes.string.isRequired,
  skillsForTags: PropTypes.array.isRequired,
  skillType: PropTypes.string.isRequired,
  AddSkill: PropTypes.func.isRequired,
  DeleteSkill: PropTypes.func.isRequired,
  handleInputFocus: PropTypes.func.isRequired,
}
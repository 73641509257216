import styled from 'styled-components'

const Header2 = styled.h2`
	margin: 0px;
	font-weight: bold;
	font-size: 16px;
	color: #0F2D49;
	letter-spacing: 0;
	line-height: 24px;
`

export default Header2
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// Components
import Confirm from './UI/Confirm'

// Images
import backIcon from '../assets/images/header-back-white.svg'

const PopOverCont = styled.div.attrs(({ isOpen }) => ({
  height: isOpen ? "100%" : "0px",
  opacity: isOpen ? "1" : "0",
  top: isOpen ? "0" : "-9999px",
  buttonDisplay: isOpen ? "block" : "none",
}))`
  position: fixed;
  z-index: 105;
  width: 100%;
  height: ${props   => props.height};
  opacity: ${props => props.opacity};
  top: ${props => props.top};
  left: 0;
  overflow: hidden;
  background: rgba(15,45,73,.15);
  transition: opacity 0.3s ease;

  & > div {
    display: ${props => props.buttonDisplay};
  }
`

const Button = styled.button`
  img { 
    position: absolute;
    top: 20px;
    left: 20px;
  }

  span {
    position: absolute
    left: 60px;
    top: 23px;
    color: #FF475F;
  } 
`

const PopOverCard = styled.div`
  position: relative;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #FFF;

  @media (min-width: 1200px) {
    margin-top: 23px;
    border: solid 1px #DDD;
    border-radius: 9px;    
  }
`

const PopOverHeader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 106;
  width: 100%;
  height: 64px;
  background: #FFF;
  border-bottom: 2px solid #FF475F;
  font-size: 18px;
  text-decoration: none;
  color: #FF475F; 
`

const PopOverBody = styled.div`
  height: 100%;
  padding: 85px 15px 15px;
  box-sizing: border-box;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`

const PopOverBack = (props) => {
  const { isOpen, title, withConfirm } = props
  return (
    <PopOverCont isOpen={isOpen}>
      <PopOverCard>
        <PopOverHeader>
          { withConfirm ? 
            <Confirm description="Do you want to discard these changes?">
              {confirm => (
                <Button onClick={confirm(props.handleClose)}><img src={backIcon} alt="Close" /><span>{title}</span></Button>
              )}
            </Confirm>
            :
            <Button onClick={props.handleClose}><img src={backIcon} alt="Close" /><span>{title}</span></Button>
          }
        </PopOverHeader>
        <PopOverBody>
        {props.children}
        </PopOverBody>
      </PopOverCard>
    </PopOverCont>
  )
}

export default PopOverBack

PopOverBack.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  withConfirm: PropTypes.bool,
}

PopOverBack.defaultProps = { 
  isOpen: false,
  title: "",
  withConfirm: false
}
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import InformationIcon from '../assets/images/information-blue.svg'

const StatusRollupCont = styled.div.attrs(({ isOpen }) => ({
  bottom: isOpen ? "0px" : "-90px",
}))`
  display: grid;
  grid-template-columns: 45px 1px auto;
  align-items: center;

  position: fixed;
  box-sizing: border-box;
  z-index: 105;
  width: 100%;
  height: 86px;
  left: 0;
  bottom: ${props => props.bottom};
  overflow: hidden;
  padding: 10px;
  background-color: #FFF;
  box-shadow: 0 0 3px 0 rgba(17, 42, 66, 0.4);
  border: solid 1px #4086fb;
  border-radius: 3px;
  transition: all 0.3s ease;
  font-size: 14px;
  color: #0f2d49;

  img {
    margin: 0 6px; 
  }
  div {
    height: 100%;
    background-color: rgba(17, 42, 66, 0.2);
  }
  span {
    padding-left: 15px;
  }
`

const StatusRollup = (props) => {
  const isOpen = props.isOpen
  const text = props.text
  return (
    <StatusRollupCont isOpen={isOpen}>
      <img src={InformationIcon} alt="Status Message" /><div>&nbsp;</div><span>{text}</span>
    </StatusRollupCont>
  )
}

export default StatusRollup

StatusRollup.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
}

StatusRollup.defaultProps = { 
  isOpen: false,
  text: "",
}
import styled from 'styled-components'

const CancelButton = styled.button`
	margin: 10px 0;
	padding: 13px 25px;
	background: #FFF;
	border: 1px solid #2ba9e3;
	border-radius: 25px;
	color: #2ba9e3;
`

export default CancelButton
import styled from 'styled-components'

const Header1 = styled.h1`
	margin: 0;
	font-weight: bold;
	font-size: 18px;
	color: #0F2D49;
	letter-spacing: 0;
	line-height: 26px;
`

export default Header1
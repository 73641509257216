import gql from 'graphql-tag';

export default gql`
	query GetProfile($profileId: ID!) {
	  getProfile(profileId:$profileId) {
      name
      email
      poolId
      recruiter_comments
      job_type
      notice_period 
			current_job_title
      created_dt
      skills {
        objectId
        skillId
        name
        skill_type
        languageCode
        proficiencyCode
        proficiencyCodeDescription
      }
      workhistories {
        workhistoryId,
        work_title,
        company_name,
        comments,
        start_dt,
        end_dt
      }
      educations {
        educationId,
        institute_name,
        institute_place,
        description,
        level,
        level_description,
        start_dt,
        end_dt
      }
      hobbies {
        hobbyId
      	name
      }
    }
	}
`
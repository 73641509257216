import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import GET_POOL from "./Queries/GET_POOL";
import GET_MESSAGES from "./Queries/GET_MESSAGES";
import ADD_MESSAGE from "./Queries/ADD_MESSAGE";
import UPDATE_PROFILE_POOL from "./Queries/UPDATE_PROFILE_POOL";

const StageChangeButton = styled.button.attrs(
  ({ buttonIsActive, buttonColor, uiSize }) => ({
    background: buttonIsActive ? buttonColor : "#FFF",
    color: buttonIsActive ? "#FFF" : buttonColor,
    borderColor: buttonColor,
    padding: uiSize === "small" ? "7px 15px" : "10px 15px",
    fontSize: uiSize === "small" ? "12px" : "14px"
  })
)`
  padding: ${props => props.padding};
  border: 1px solid ${props => props.borderColor};
  border-radius: 22px;
  background: ${props => props.background};
  text-align: center;
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  font-weight: 600;
`;

const stages = [
  { stageId: "draft", stage: "Draft", color: "#0F2D49" },
  { stageId: "pool", stage: "Candidate Pool", color: "#2ba9e3" },
  { stageId: "shortlist", stage: "Shortlist", color: "#37E37F" },
  { stageId: "not_interested", stage: "Not Interested", color: "#F20000" }
];

function getStageNameByStageId(stageId) {
  const stage = stages.filter(e => e.stageId === stageId);
  return stage[0].stage;
}

function getStageColor(stageId) {
  const stage = stages.filter(e => e.stageId === stageId);
  return stage[0].color;
}

class StageChangeButtonWithData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonClicked: false
    };
    this.handleStageChange = this.handleStageChange.bind(this);
  }

  handleStageChange() {
    this.setState({ buttonClicked: !this.state.buttonClicked });
  }

  static getDerivedStateFromProps(props, current_state) {
    if (props.profile && props.stageId === props.profile.stageId) {
      return {
        buttonClicked: false
      };
    }
    return null;
  }

  render() {
    const {
      poolId,
      profile,
      stageId,
      currentUsername,
      uiSize,
      callback
    } = this.props;
    const { buttonClicked } = this.state;

    if (profile && profile.profileId) {
      const profileId = profile.profileId;
      const buttonIsActive = stageId === profile.stageId || buttonClicked;
      const buttonText =
        stageId === "shortlist" && buttonIsActive
          ? "Shortlisted"
          : this.props.buttonText;
      const profileName = profile.name;
      const buttonColor = getStageColor(stageId);

      return (
        <Mutation mutation={UPDATE_PROFILE_POOL}>
          {(UpdateProfilePool, { data }) => (
            <Mutation mutation={ADD_MESSAGE}>
              {(AddMessage, { data }) => (
                <StageChangeButton
                  buttonIsActive={buttonIsActive}
                  buttonColor={buttonColor}
                  disabled={buttonIsActive}
                  uiSize={uiSize}
                  onClick={e => {
                    e.preventDefault();
                    this.handleStageChange();

                    UpdateProfilePool({
                      variables: {
                        poolId: poolId,
                        profileId: profileId,
                        stageId: stageId
                      },
                      update: (cache, { data: { updateProfilePool } }) => {
                        const query = GET_POOL;
                        const variables = { poolId: poolId };
                        const data = cache.readQuery({ query, variables });

                        const changedProfile = data.getPool.profileSummaries.filter(
                          e => e.profileId === updateProfilePool.profileId
                        );
                        const restProfiles = data.getPool.profileSummaries.filter(
                          e => e.profileId !== updateProfilePool.profileId
                        );

                        const updatedProfile = {
                          ...changedProfile[0],
                          stageId: updateProfilePool.stageId
                        };

                        if (
                          data.getPool.profileSummaries.filter(
                            e => e.profileId === updateProfilePool.profileId
                          ).length >= 0
                        ) {
                          const dataChanged = {
                            getPool: {
                              company_name: data.getPool.company_name,
                              pool_name: data.getPool.pool_name,
                              profileSummaries: [
                                ...restProfiles,
                                updatedProfile
                              ],
                              identities: data.getPool.identities,
                              stages: data.getPool.stages,
                              __typename: "Pool"
                            }
                          };
                          cache.writeQuery({
                            query,
                            variables,
                            data: dataChanged
                          });
                        }
                        callback(profile.name, updateProfilePool.stageId);
                      }
                    });
                    AddMessage({
                      variables: {
                        message: `${profileName} has been moved to ${getStageNameByStageId(
                          stageId
                        )}.`,
                        chatId: poolId,
                        username: currentUsername,
                        theme: "system_profile_stage_change",
                        created_dt: Date.now(),
                        data: JSON.stringify({
                          name: profileName,
                          poolId: poolId,
                          stageId: stageId,
                          job_type: profile.job_type,
                          notice_period: profile.notice_period,
                          recruiter_comments: profile.recruiter_comments,
                          current_job_title: profile.current_job_title,
                          profileId: profileId
                        })
                      },
                      optimisticResponse: {
                        __typename: "Mutation",
                        addMessage: {
                          __typename: "Message",
                          chatId: poolId,
                          SortKey: null,
                          theme: "system_profile_stage_change",
                          username: currentUsername,
                          created_dt: Date.now(),
                          data: JSON.stringify({
                            name: profileName,
                            poolId: poolId,
                            stageId: stageId,
                            job_type: profile.job_type,
                            notice_period: profile.notice_period,
                            recruiter_comments: profile.recruiter_comments,
                            current_job_title: profile.current_job_title,
                            profileId: profileId
                          }),
                          message: `${profileName} has been moved to ${getStageNameByStageId(
                            stageId
                          )}.`
                        }
                      },
                      update: (cache, { data: { addMessage } }) => {
                        const query = GET_MESSAGES;
                        const variables = { chatId: poolId };
                        const data = cache.readQuery({ query, variables });
                        if (addMessage) {
                          if (
                            data.getMessages.filter(
                              e => e.SortKey === addMessage.SortKey
                            ).length <= 0
                          ) {
                            data.getMessages.push(addMessage);
                            cache.writeQuery({ query, variables, data });
                          }
                        }
                      }
                    });
                  }}
                >
                  {buttonText}
                </StageChangeButton>
              )}
            </Mutation>
          )}
        </Mutation>
      );
    } else {
      return "";
    }
  }
}

export default StageChangeButtonWithData;

StageChangeButtonWithData.propTypes = {
  currentUsername: PropTypes.string,
  poolId: PropTypes.string,
  profile: PropTypes.object,
  buttonText: PropTypes.string,
  stageId: PropTypes.string,
  uiSize: PropTypes.string,
  callback: PropTypes.func
};

StageChangeButtonWithData.defaultProps = {
  isOpen: false,
  uiSize: "medium",
  callback: () => {}
};

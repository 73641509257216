import styled from 'styled-components'

const SubmitButton = styled.button`
	margin: 10px 0;
	padding: 13px 25px;
	min-width: 120px;
	background: #FF475F;
	border: 1px solid #FF475F;
	border-radius: 25px;
	color: #FFF;
`

export default SubmitButton
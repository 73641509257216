import moment from "moment";

function isNewUserMessage(currentMsg, prevMsg) {
  if (!prevMsg || !currentMsg) return true;
  if (isNewDay(currentMsg.created_dt, prevMsg.created_dt)) return true;
  if (currentMsg.created_dt - prevMsg.created_dt > 600000) return true;
  if (currentMsg.username !== prevMsg.username) return true;
  else return false;
}

function isNewDay(currentDate, previousDate) {
  if (!currentDate || !previousDate) return true;
  const previousDay = previousDate
    ? moment(previousDate).format("dddd, MMMM Do")
    : null;
  const currentDay = currentDate
    ? moment(currentDate).format("dddd, MMMM Do")
    : null;
  if (previousDay !== currentDay) return true;
  else return false;
}

export { isNewDay, isNewUserMessage };

import React from "react";
import styled from "styled-components";
import { isNewUserMessage } from "../helpers/messages-functions";

// GraphQL Apollo
import { Query, Mutation } from "react-apollo";
import GET_MESSAGES from "./Queries/GET_MESSAGES";
import MESSAGES_SUBSCRIPTION from "./Queries/MESSAGES_SUBSCRIPTION";
import ADD_MESSAGE from "./Queries/ADD_MESSAGE";
import ADD_READMARKER from "./Queries/ADD_READMARKER";

// Components
import Message from "./Message";
import FixedWidthContainer from "./UI/FixedWidthContainer";
import NewDayDivider from "./UI/NewDayDivider";

// Images
import MessageSendIcon from "../assets/images/message-send.svg";

const MessagesWindow = styled.div`
  padding: 0 0 55px;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;

  @media (min-width: 900px) {
    padding: 0 0 70px;
  }
}
`;

const MessageAlign = styled.div.attrs(({ isNotMyMarker }) => ({
  float: isNotMyMarker ? "right" : "none",
  width: isNotMyMarker ? "auto" : "100%",
  marginTop: isNotMyMarker ? "5px" : "0",
  display: isNotMyMarker ? "inline-block" : "block"
}))`
  width: ${props => props.width};
  margin-top: ${props => props.marginTop};
  float: ${props => props.float};

  & > div {
    display: ${props => props.display};
  }
`;

const FixedWidthContainerMsgChat = styled(FixedWidthContainer)`
  top: auto;
  bottom: 0px;

  @media (min-width: 768px) {
    bottom: 0px;
  }

  @media (min-width: 900px) {
    padding-left: 530px;
  }
`;

const MessagesChat = styled.div`
  width: 100%;
  padding: 0 0 12px;
  background: #fff;
`;

const StartOfMessages = styled.div`
  margin-top: 20px;
  color: #aaa;
  font-size: 13px;
  text-align: center;
`;

const MessagesChatForm = styled.form`
  display: grid;
  grid-template-columns: auto 65px;
`;

const MessageChatInput = styled.input`
  display: block;
  float: left;
  width: auto;
  margin-left: 10px;
  padding: 10px 15px;
  background: #f3f3f3;
  border: none;
  border-radius: 27px;
  font-size: 14px;
  color: #0f2d49;
  letter-spacing: 0;
  line-height: 22px;
  transition: box-shadow 0.3s ease;
  caret-color: #ff475f;

  &:focus {
    box-shadow: 0 0 4px 1px #55bae8;
    border-radius: 27px;
  }
`;

const Button = styled.button`
  height: 44px;
  padding: 0;
  border: none;
  background: none;
  color: #fff;

  img {
    width: 44px;
    height: 44px;
  }
`;

class UpdateReadMarker extends React.Component {
  componentDidMount() {
    const { currentUsername, chatId, addReadMarker } = this.props;
    const created_dt = Date.now();

    setTimeout(function() {
      addReadMarker({
        variables: {
          created_dt,
          chatId,
          SortKey: `marker-${currentUsername}`,
          username: currentUsername
        },
        ignoreResults: true,
        onError: () => {
          console.error("reader marker error");
        }
      });
    }, 2000);
  }

  render() {
    return null;
  }
}

export class Messages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: false,
      canSubmit: true
    };
    this.msgWindow = React.createRef();
    this.messagesRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
  }
  componentDidMount() {
    this.props.subscribeToMessages();
  }
  componentDidUpdate() {
    if (this.msgWindow) {
      const messageCreated = this.props.messageCreated;
      if (messageCreated) {
        window.scrollTo(0, this.messagesRef[messageCreated].offsetTop - 155);
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  }
  submitForm(event) {
    event.preventDefault();
    const { submit, addReadMarker } = this.props;
    const message = this.newMessage.value;
    const created_dt = Date.now();

    if (message) {
      this.setState({ canSubmit: false });

      submit({
        message,
        created_dt
      }).then(res => {
        this.setState({ canSubmit: true });
        window.scrollTo(0, document.body.scrollHeight);

        if (!res.errors) {
          this.newMessage.value = "";
        } else {
          this.setState({ errors: res.errors });
        }
      });

      addReadMarker({ created_dt });
    }
  }
  render() {
    const data = this.props.data;
    const chatId = this.props.chatId;
    const { errors, canSubmit } = this.state;
    const currentUsername = this.props.currentUsername;
    const profileSummaries = this.props.profileSummaries;
    const myMarker =
      data.getMessages.filter(e => !e.theme && e.username === currentUsername)
        .length > 0
        ? data.getMessages.filter(
            e => !e.theme && e.username === currentUsername
          )
        : [{ username: currentUsername, created_dt: 0 }];
    const newMessages = data.getMessages.filter(
      e => e.created_dt > myMarker[0].created_dt && e.theme
    );
    const myNewMessages = data.getMessages.filter(
      e =>
        e.created_dt > myMarker[0].created_dt &&
        e.username === currentUsername &&
        e.theme
    );
    const showMarker =
      newMessages.length > 0 && newMessages.length > myNewMessages.length
        ? true
        : false;
    this.messagesRef = [];
    return (
      <React.Fragment>
        <div>
          <MessagesWindow ref={this.msgWindow}>
            <StartOfMessages>
              This is the start of the conversation.
            </StartOfMessages>
            {data.getMessages.map((message, key) => (
              <MessageAlign
                isNotMyMarker={
                  message.username !== currentUsername && !message.theme
                    ? true
                    : false
                }
                key={message.SortKey}
                ref={messagesRef => {
                  this.messagesRef[message.created_dt] = messagesRef;
                }}
              >
                {message.theme ? (
                  <NewDayDivider
                    currentDate={message.created_dt}
                    previousDate={
                      key === 0 ? 0 : data.getMessages[key - 1].created_dt
                    }
                  />
                ) : null}
                <Message
                  showMarker={showMarker}
                  chatId={chatId}
                  isNewUserMessage={
                    key === 0
                      ? true
                      : isNewUserMessage(
                          data.getMessages[key],
                          data.getMessages[key - 1]
                        )
                  }
                  message={message}
                  currentUsername={currentUsername}
                  profileSummaries={profileSummaries}
                  handleProfileOpen={this.props.handleProfileOpen}
                />
              </MessageAlign>
            ))}
          </MessagesWindow>
        </div>
        <FixedWidthContainerMsgChat>
          <MessagesChat>
            <MessagesChatForm onSubmit={this.submitForm}>
              <MessageChatInput
                type="text"
                className="form-control"
                placeholder="New message"
                ref={input => (this.newMessage = input)}
              />

              {errors && (
                <div className="alert alert-danger" role="alert">
                  {errors[0].message}
                </div>
              )}

              <Button
                type="submit"
                disabled={!canSubmit}
                className="btn btn-primary"
              >
                <img src={MessageSendIcon} alt="Send message" />
              </Button>
            </MessagesChatForm>
          </MessagesChat>
        </FixedWidthContainerMsgChat>
      </React.Fragment>
    );
  }
}

const MessagesWithData = props => {
  const chatId = props.chatId;
  const currentUsername = props.username;
  const profileSummaries = props.profileSummaries;
  const messageCreated = props.messageCreated;
  return (
    <Mutation mutation={ADD_READMARKER}>
      {addReadMarker => (
        <React.Fragment>
          <UpdateReadMarker
            chatId={chatId}
            addReadMarker={addReadMarker}
            currentUsername={currentUsername}
          />
          <Query
            query={GET_MESSAGES}
            variables={{ chatId }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data, refetch, subscribeToMore }) => {
              if (error) return `Error! ${error.message}`;
              if (data.getMessages)
                return (
                  <Mutation mutation={ADD_MESSAGE}>
                    {mutate => (
                      <Messages
                        data={data}
                        currentUsername={currentUsername}
                        profileSummaries={profileSummaries}
                        messageCreated={messageCreated}
                        handleProfileOpen={props.handleProfileOpen}
                        chatId={chatId}
                        subscribeToMessages={() =>
                          subscribeToMore({
                            document: MESSAGES_SUBSCRIPTION,
                            variables: { chatId: chatId },
                            onError: (e: Object): void => {
                              return console.error("APOLLO-CHAT", e);
                            },
                            updateQuery: (prev, { subscriptionData }) => {
                              if (!subscriptionData.data) return prev;
                              const messageItem =
                                subscriptionData.data.onAddMessage;
                              if (
                                prev.getMessages.filter(
                                  e => e.created_dt === messageItem.created_dt
                                ).length <= 0
                              ) {
                                return Object.assign({}, prev, {
                                  getMessages: [
                                    ...prev.getMessages,
                                    messageItem
                                  ]
                                });
                              } else {
                                return prev;
                              }
                            }
                          })
                        }
                        addReadMarker={({ created_dt }) =>
                          addReadMarker({
                            variables: {
                              created_dt,
                              chatId,
                              SortKey: `marker-${currentUsername}`,
                              username: currentUsername
                            }
                          })
                        }
                        submit={({ message, created_dt }) =>
                          mutate({
                            variables: {
                              message,
                              created_dt,
                              chatId,
                              SortKey: null,
                              theme: "text",
                              data: JSON.stringify({
                                username: currentUsername
                              }),
                              username: currentUsername
                            },
                            optimisticResponse: {
                              __typename: "Mutation",
                              addMessage: {
                                __typename: "Message",
                                chatId: chatId,
                                SortKey: null,
                                theme: "text",
                                username: currentUsername,
                                created_dt: created_dt,
                                data: {
                                  username: currentUsername
                                },
                                message: message
                              }
                            },
                            update: (cache, { data: { addMessage } }) => {
                              const query = GET_MESSAGES;
                              const variables = { chatId: chatId };
                              const data = cache.readQuery({
                                query,
                                variables
                              });
                              if (
                                data.getMessages.filter(
                                  e => e.created_dt === addMessage.created_dt
                                ).length <= 0
                              ) {
                                data.getMessages.push(addMessage);
                                cache.writeQuery({ query, variables, data });
                              }
                            }
                          })
                        }
                      />
                    )}
                  </Mutation>
                );
              if (loading) return `Loading...`;
              return null;
            }}
          </Query>
        </React.Fragment>
      )}
    </Mutation>
  );
};

export default MessagesWithData;

import gql from 'graphql-tag';

export default gql`
  query GetMessages($chatId: ID!) {
    getMessages(chatId: $chatId) {
      __typename
      chatId
      SortKey
      message
      theme
      created_dt
      data
      username
    }
  }
`
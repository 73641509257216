import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Mutation } from "react-apollo";
import ADD_EDUCATION from "./Queries/ADD_EDUCATION";
import DELETE_EDUCATION from "./Queries/DELETE_EDUCATION";
import GET_PROFILE from "./Queries/GET_PROFILE";

// UI Componenets
import TextInput from "./UI/TextInput";
import Label from "./Typography/Label";
import SubmitButton from "./UI/SubmitButton";
import SelectDate from "./UI/SelectDate";
import Textarea from "./UI/Textarea";
import Confirm from "./UI/Confirm";

// Images
import BinIcon from "../assets/images/bin.svg";

const currentEpoch = Math.floor(new Date().getTime());

const AddEducationCont = styled.div`
  display: block;
`;

const SubmitButtonStyled = styled(SubmitButton)`
  font-size: 16px;
  margin: 0;
`;

const CenterButton = styled.div`
  text-align: center;
`;

const DateError = styled.div.attrs(({ hasError }) => ({
  visibility: hasError ? "visible" : "hidden"
}))`
  position: absolute;
  margin-left: 15px;
  margin-top: -16px;
  visibility: ${props => props.visibility};
  font-size: 12px;
  color: red;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  color: 0f2d49;
`;

const RemoveButton = styled.button`
  float: right;
  margin-top: -5px;
`;

const DateCont = styled.div`
  position: relative;
`;

class EducationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_dt: props.education.start_dt,
      end_dt: props.education.end_dt,
      hasError: false
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  handleStartDateChange(start_dt) {
    const hasError = start_dt > this.state.end_dt ? true : false;
    this.setState(state => ({
      start_dt,
      hasError
    }));
  }
  handleEndDateChange(end_dt) {
    const hasError = this.state.start_dt > end_dt ? true : false;
    this.setState(state => ({
      end_dt,
      hasError
    }));
  }

  render() {
    const { handleClose, handleRolldown, education, profileId } = this.props;
    const { start_dt, end_dt, hasError } = this.state;
    let {
      educationId,
      institute_name,
      institute_place,
      description,
      level,
      level_description,
      candidate_id
    } = education;

    return (
      <Mutation mutation={ADD_EDUCATION}>
        {(addEducation, { data }) => (
          <Mutation mutation={DELETE_EDUCATION}>
            {(deleteEducation, { data }) => (
              <AddEducationCont>
                {education.educationId != null ? (
                  <Confirm description="Are you sure you want to delete this content?">
                    {confirm => (
                      <RemoveButton
                        onClick={confirm(e =>
                          deleteEducation({
                            variables: {
                              profileId: profileId,
                              educationId: educationId
                            },
                            optimisticResponse: {
                              __typename: "Mutation",
                              deleteEducation: {
                                __typename: "Education",
                                educationId: educationId
                              }
                            },
                            update: (cache, { data: { deleteEducation } }) => {
                              const query = GET_PROFILE;
                              const variables = { profileId: profileId };
                              const data = cache.readQuery({
                                query,
                                variables
                              });

                              if (
                                data.getProfile.educations.filter(
                                  e =>
                                    e.educationId ===
                                    deleteEducation.educationId
                                ).length > 0
                              ) {
                                const updatedEducations = data.getProfile.educations.filter(
                                  e =>
                                    e.educationId !==
                                    deleteEducation.educationId
                                );
                                data.getProfile.educations = updatedEducations;
                                cache.writeQuery({ query, variables, data });
                              }

                              handleRolldown("Profile updated successfully");
                              handleClose();
                            }
                          })
                        )}
                      >
                        <img src={BinIcon} alt="Remove Education" />
                      </RemoveButton>
                    )}
                  </Confirm>
                ) : null}

                <Title>
                  {education.educationId != null ? "Edit" : "Add"} Education
                </Title>

                <form
                  onSubmit={e => {
                    e.preventDefault();

                    addEducation({
                      variables: {
                        profileId: profileId,
                        educationId: educationId,
                        institute_name: institute_name.value,
                        institute_place: institute_place,
                        description: description.value,
                        level: level.value,
                        level_description: level_description.value,
                        candidate_id: candidate_id,
                        start_dt: parseInt(start_dt),
                        end_dt: parseInt(end_dt)
                      },
                      optimisticResponse: {
                        __typename: "Mutation",
                        addEducation: {
                          __typename: "Education",
                          profileId: profileId,
                          educationId: educationId,
                          institute_name: institute_name.value,
                          institute_place: institute_place,
                          description: description.value,
                          level: level.value,
                          level_description: level_description.value,
                          candidate_id: candidate_id,
                          start_dt: parseInt(start_dt),
                          end_dt: parseInt(end_dt)
                        }
                      },
                      update: (cache, { data: { addEducation } }) => {
                        const query = GET_PROFILE;
                        const variables = { profileId: profileId };
                        const data = cache.readQuery({ query, variables });

                        if (
                          data.getProfile.educations.filter(
                            e => e.educationId === addEducation.educationId
                          ).length > 0
                        ) {
                          const whIndex = data.getProfile.educations.findIndex(
                            e => e.educationId === addEducation.educationId
                          );
                          data.getProfile.educations[whIndex].institute_name =
                            addEducation.institute_name;
                          data.getProfile.educations[whIndex].institute_place =
                            addEducation.institute_place;
                          data.getProfile.educations[whIndex].description =
                            addEducation.description;
                          data.getProfile.educations[whIndex].level =
                            addEducation.level;
                          data.getProfile.educations[
                            whIndex
                          ].level_description = addEducation.level_description;
                          data.getProfile.educations[whIndex].start_dt =
                            addEducation.start_dt;
                          data.getProfile.educations[whIndex].end_dt =
                            addEducation.end_dt;
                          cache.writeQuery({ query: GET_PROFILE, data: data });
                        } else {
                          data.getProfile.educations.push(addEducation);
                          cache.writeQuery({ query, variables, data });
                        }
                      }
                    });

                    handleRolldown("Profile updated successfully");
                    handleClose();
                  }}
                >
                  <DateCont>
                    <Label>Start Date</Label>
                    <SelectDate
                      date={start_dt}
                      handleDateChange={this.handleStartDateChange}
                    />
                  </DateCont>
                  <DateCont>
                    <Label>End Date</Label>
                    <SelectDate
                      date={end_dt}
                      handleDateChange={this.handleEndDateChange}
                      hasError={hasError}
                    />
                    <DateError hasError={hasError}>
                      End date should be after Start date
                    </DateError>
                  </DateCont>
                  <Label>Course Title</Label>
                  <TextInput
                    ref={node => {
                      level_description = node;
                    }}
                    defaultValue={level_description}
                    required={true}
                  />
                  <br />
                  <Label>Type of Course</Label>
                  <TextInput
                    ref={node => {
                      level = node;
                    }}
                    required={true}
                    defaultValue={level}
                  />
                  <br />
                  <Label>Awarding Body</Label>
                  <TextInput
                    ref={node => {
                      institute_name = node;
                    }}
                    required={true}
                    defaultValue={institute_name}
                  />
                  <br />
                  <Label>Description</Label>
                  <Textarea
                    ref={node => {
                      description = node;
                    }}
                    required={true}
                    defaultValue={description}
                  />
                  <CenterButton>
                    <SubmitButtonStyled type="submit">
                      {education.educationId != null ? "Update" : "Add"}
                    </SubmitButtonStyled>
                  </CenterButton>
                </form>
              </AddEducationCont>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export default EducationEdit;

EducationEdit.propTypes = {
  education: PropTypes.object,
  profileId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRolldown: PropTypes.func.isRequired
};

EducationEdit.defaultProps = {
  education: {
    educationId: null,
    level: "",
    level_description: "",
    start_dt: currentEpoch,
    end_dt: currentEpoch,
    institute_name: "",
    institute_place: "",
    description: ""
  }
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

// Apollo / GraphQL
import { Query } from "react-apollo";
import GET_POOL from "../../Components/Queries/GET_POOL";
import ADD_PROFILE_SUBSCRIPTION from "../../Components/Queries/ADD_PROFILE_SUBSCRIPTION";
import UPDATE_PROFILE_SUBSCRIPTION from "../../Components/Queries/UPDATE_PROFILE_SUBSCRIPTION";

// Components
import PoolCandidateList from "../../Components/PoolCandidateList";
import Profile from "../../Components/Profile";
import Collaborators from "../../Components/Collaborators";
import Messages from "../../Components/Messages";
import PoolScrubber from "../../Components/PoolScrubber";
import Menu from "../../Components/Menu";
import AddProfile from "../../Components/AddProfile";
import AddPoolUserAccess from "../../Components/AddPoolUserAccess";
import PopOver from "../../Components/PopOver";
import PopUnder from "../../Components/PopUnder";
import FixedWidthContainer from "../../Components/UI/FixedWidthContainer";

// Skeleton loading blocks
import {
  SkeletonCont,
  SkeletonInput,
  SkeletonChat
} from "../../Components/UI/Skeleton";

// Typography
import Header1 from "../../Components/Typography/Header1";

// Images
import addIconWhite from "../../assets/images/button-add-white.svg";
import backIcon from "../../assets/images/dropdown-arrow.svg";

const isRecruiter = user_type => user_type === "RECRUITER";

const FixedWidthContainerTitleBar = styled(FixedWidthContainer)`
  top: 0px;
  background: #fff;
`;

const TitleBar = styled.div`
  position: relative;
  height: 50px;
  box-sizing: border-box;
  padding: 12px 15px;
  background-color: #fff;
  border-bottom: 1px solid #e7eaed;
`;

const StyledH1 = styled(Header1)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  font-size: 16px;
`;

const BackLink = styled(Link)`
  img {
    transform: rotate(90deg);
    width: 18px;
    margin: 0 5px -3px -4px;
  }
`;

const AddCollaboratorButton = props => {
  const user_type = props.user_type;
  return isRecruiter(user_type) ? (
    <AddCollaboratorButtonStyled onClick={props.handleOpenAddCollaborator}>
      <img src={addIconWhite} alt="Add a new collaborator" />
    </AddCollaboratorButtonStyled>
  ) : (
    ""
  );
};

const AddCollaboratorButtonStyled = styled.button`
  float: right;
  margin-left: 10px;
  border: none;
  padding: 0;
  background: none;

  img {
    width: 22px;
    height: 22px;
  }
`;

const WindowCont = styled.div.attrs(({ isDisplayed }) => ({
  display: isDisplayed ? "block" : "none"
}))`
  display: ${props => props.display};
  margin-top: 105px;
  padding: 15px;

  @media (min-width: 900px) {
    margin-top: 50px;
    margin-left: 250px;
  }
`;

class PoolPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: "__messages",
      isAddProfileOpen: false,
      isAddCollaboratorOpen: false,
      addProfilePopUnderHeight: "300px",
      isProfileOpen: false,
      openedProfileId: null
    };

    this.handleOpened = this.handleOpened.bind(this);
    this.handleOpenAddProfile = this.handleOpenAddProfile.bind(this);
    this.handleCloseAddProfile = this.handleCloseAddProfile.bind(this);
    this.handleOpenAddCollaborator = this.handleOpenAddCollaborator.bind(this);
    this.handleCloseAddCollaborator = this.handleCloseAddCollaborator.bind(
      this
    );
    this.handleChangeAddProfilePopUnderHeight = this.handleChangeAddProfilePopUnderHeight.bind(
      this
    );
    this.handleProfileOpen = this.handleProfileOpen.bind(this);
    this.handleCloseProfileOpen = this.handleCloseProfileOpen.bind(this);
  }

  componentDidMount() {
    this.props.subscribeToProfiles();
    this.props.subscribeToProfilesStageUpdates();
  }

  handleOpened(container) {
    this.setState({ opened: container });
  }

  handleOpenAddProfile() {
    this.setState({ isAddProfileOpen: true });
  }

  handleCloseAddProfile() {
    this.setState({ isAddProfileOpen: false });
  }

  handleOpenAddCollaborator() {
    this.setState({ isAddCollaboratorOpen: true });
  }

  handleCloseAddCollaborator() {
    this.setState({ isAddCollaboratorOpen: false });
  }

  handleChangeAddProfilePopUnderHeight(height) {
    this.setState({ addProfilePopUnderHeight: height });
  }

  handleProfileOpen(profileId) {
    this.setState({ isProfileOpen: true, openedProfileId: profileId });
  }

  handleCloseProfileOpen() {
    this.setState({ isProfileOpen: false });
  }

  render() {
    const {
      poolId,
      username,
      user_type,
      pool_name,
      stages,
      identities,
      profileSummaries,
      messageCreated
    } = this.props;
    const {
      opened,
      isAddProfileOpen,
      isAddCollaboratorOpen,
      addProfilePopUnderHeight,
      isProfileOpen,
      openedProfileId
    } = this.state;
    // Appsync does not support sort ATL sort, so sort here
    const stagesSorted = stages
      .sort((a, b) => a.order - b.order)
      .map(stage => {
        stage.countOfProfiles = profileSummaries.filter(
          profile => profile.stageId === stage.stageId
        ).length;
        return stage;
      });
    return (
      <div>
        <MediaQuery query="(min-width: 768px)">
          <Menu />
        </MediaQuery>
        <FixedWidthContainerTitleBar>
          <TitleBar>
            <AddCollaboratorButton
              user_type={user_type}
              handleOpenAddCollaborator={this.handleOpenAddCollaborator}
            />
            <Collaborators identities={identities} />
            <StyledH1>
              <BackLink to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src={backIcon} alt="View all job pools" />
              </BackLink>{" "}
              {pool_name}
            </StyledH1>
          </TitleBar>
        </FixedWidthContainerTitleBar>
        <PoolScrubber
          poolId={poolId}
          stages={stagesSorted}
          user_type={user_type}
          handleOpened={this.handleOpened}
          opened={opened}
        />
        <WindowCont isDisplayed={opened === "__messages"}>
          <Messages
            chatId={poolId}
            username={username}
            profileSummaries={profileSummaries}
            messageCreated={messageCreated}
            handleProfileOpen={this.handleProfileOpen}
          />
        </WindowCont>
        {stagesSorted.map((stage, key) => (
          <WindowCont key={key} isDisplayed={opened === stage.stageId}>
            <PoolCandidateList
              title={stage.name}
              stageId={stage.stageId}
              profileSummaries={profileSummaries.filter(
                e => e.stageId === stage.stageId
              )}
              poolId={poolId}
              currentUsername={username}
              user_type={user_type}
              handleProfileOpen={this.handleProfileOpen}
              handleOpenAddProfile={this.handleOpenAddProfile}
            />
          </WindowCont>
        ))}
        <PopUnder
          title="Add a Candidate"
          height={addProfilePopUnderHeight}
          isOpen={isAddProfileOpen}
          handleClose={this.handleCloseAddProfile}
          zIndex={120}
        >
          <AddProfile
            GET_POOL={GET_POOL}
            poolId={poolId}
            handleChangeAddProfilePopUnderHeight={
              this.handleChangeAddProfilePopUnderHeight
            }
            handleClose={this.handleCloseAddProfile}
            handleProfileOpen={this.handleProfileOpen}
          />
        </PopUnder>
        <PopUnder
          title="Invite Client"
          height="270px"
          isOpen={isAddCollaboratorOpen}
          handleClose={this.handleCloseAddCollaborator}
        >
          <AddPoolUserAccess
            username={username}
            poolId={poolId}
            handleClose={this.handleCloseAddCollaborator}
          />
        </PopUnder>
        <PopOver
          title="Candidate Pool"
          isOpen={isProfileOpen}
          handleClose={this.handleCloseProfileOpen}
        >
          {isProfileOpen ? (
            <Profile user_type={user_type} profileId={openedProfileId} />
          ) : null}
        </PopOver>
      </div>
    );
  }
}

const PoolPageWithData = props => {
  const poolId = props.match.params.poolId;
  const messageCreated = props.match.params.messageCreated;
  const username = props.identity.username;
  const user_type = props.identity.user_type;

  return (
    <Query
      query={GET_POOL}
      variables={{ poolId }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data, refetch, subscribeToMore }) => {
        if (error) {
          console.error(`Error! ${error.message}`);
          return `There was an issue loading the job pool.  Please refresh and try again.`;
        }
        if (data.getPool) {
          return (
            <PoolPage
              poolId={poolId}
              messageCreated={messageCreated}
              username={username}
              identities={data.getPool.identities}
              user_type={user_type}
              pool_name={data.getPool.pool_name}
              stages={data.getPool.stages}
              profileSummaries={data.getPool.profileSummaries}
              subscribeToProfiles={() =>
                subscribeToMore({
                  document: ADD_PROFILE_SUBSCRIPTION,
                  variables: { poolId: poolId },
                  onError: (e: Object): void => {
                    return console.error("APOLLO-CHAT", e);
                  },
                  updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const profileItem = subscriptionData.data.onAddProfile;
                    if (
                      prev.getPool.profileSummaries.filter(
                        e => e.profileId === profileItem.profileId
                      ).length <= 0
                    ) {
                      let updated = prev;
                      updated.getPool.profileSummaries.push(profileItem);
                      return updated;
                    }
                  }
                })
              }
              subscribeToProfilesStageUpdates={() =>
                subscribeToMore({
                  document: UPDATE_PROFILE_SUBSCRIPTION,
                  variables: { poolId: poolId },
                  onError: (e: Object): void => {
                    return console.error("APOLLO-CHAT", e);
                  },
                  updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const profileItem =
                      subscriptionData.data.onUpdateProfilePool;
                    if (
                      prev.getPool.profileSummaries.filter(
                        e => e.profileId === profileItem.profileId
                      ).length >= 0
                    ) {
                      const index = prev.getPool.profileSummaries
                        .map(e => e.profileId)
                        .indexOf(profileItem.profileId);
                      let updated = prev;
                      updated.getPool.profileSummaries[index].stageId =
                        profileItem.stageId;
                      //refetch() // Cache was not updating properly without this.. WHY?
                      return updated;
                    }
                  }
                })
              }
            />
          );
        }
        if (loading)
          return (
            <div>
              <MediaQuery query="(min-width: 768px)">
                <Menu />
              </MediaQuery>
              <SkeletonCont>
                <SkeletonInput />
                <SkeletonInput />
                <SkeletonChat align="left" />
                <SkeletonChat align="right" />
                <SkeletonChat align="left" />
              </SkeletonCont>
            </div>
          );
        return "";
      }}
    </Query>
  );
};

PoolPage.propTypes = {
  poolId: PropTypes.string,
  messageCreated: PropTypes.string,
  username: PropTypes.string,
  user_type: PropTypes.string,
  pool_name: PropTypes.string,
  profileSummaries: PropTypes.array
};

PoolPage.defaultProps = {
  poolId: "",
  messageCreated: null,
  username: "",
  user_type: "",
  pool_name: "",
  profileSummaries: []
};

export default PoolPageWithData;

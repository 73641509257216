import React, { useState, useRef, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ScrubberStyled = styled.div`
	position: fixed;
	max-width: 1200px;
  white-space: nowrap;
  overflow: scroll;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
`

const ScrubberItem = styled.div`
	display: inline-block;
	vertical-align: top;
`

const Scrubber = (props) => {
	const { children } = props
	const childrenFlat = [].concat(...children) // Some components might be send as an array
	const [activeItem, setActiveItem] = useState(0);
	const itemsRef = useRef([...Array(childrenFlat.length)].map(() => createRef()))
	const windowRef = useRef(null)

  useEffect(() => {
    if (windowRef) {
			windowRef.current.scrollTo({
			  top: 0,
			  left: itemsRef.current[activeItem].current.offsetLeft-15,
			  behavior: 'smooth'
			})
    }
  })

	return (
		<ScrubberStyled ref={windowRef}>
      {childrenFlat.map((child, key) => {
     		return (
	        <ScrubberItem onClick={() => setActiveItem(key)} ref={itemsRef.current[key]} key={key}>
	          {child}
	        </ScrubberItem>
	      )
      })}
    </ScrubberStyled>
	)
}

Scrubber.propTypes = {
  stages: PropTypes.array,
}

Scrubber.defaultProps = { 
  stages: [],
}

export default Scrubber
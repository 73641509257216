import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import MediaQuery from "react-responsive";

// Apollo
import { Query, Mutation } from "react-apollo";
import GET_POOLS from "../../Components/Queries/GET_POOLS";
import ADD_POOL from "../../Components/Queries/ADD_POOL";

// Components
import BottomTray from "../../Components/BottomTray";
import Menu from "../../Components/Menu";
import PoolList from "../../Components/PoolList";
import PopUnder from "../../Components/PopUnder";

// UI Components
import Label from "../../Components/Typography/Label";
import TextInput from "../../Components/UI/TextInput";
import SubmitButton from "../../Components/UI/SubmitButton";

// Images
import loadingImage from "../../assets/images/loading.gif";

const SubmitButtonCentered = styled.div`
  text-align: center;
`;

const Submitting = styled.div`
  position: absolute;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 50px;
`;

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: false,
      canSubmit: true,
      submitting: false,
      isAddPoolOpen: false
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleOpenAddPool = this.handleOpenAddPool.bind(this);
    this.handleCloseAddPool = this.handleCloseAddPool.bind(this);
  }

  submitForm(event) {
    event.preventDefault();
    const { submit } = this.props;
    const pool_name = this.newPoolInput.value;
    const company_name = this.companyNameInput.value;

    if (pool_name) {
      this.setState({ canSubmit: false, submitting: true });

      submit({
        pool_name,
        company_name
      }).then(res => {
        this.setState({ canSubmit: true });

        if (!res.errors) {
          this.newPoolInput.value = "";
          this.companyNameInput.value = "";

          setTimeout(
            function() {
              this.setState({ submitting: false });
              this.handleCloseAddPool();
              this.props.history.push(`/pool/${res.data.addPool.poolId}`);
            }.bind(this),
            1500
          );
        } else {
          this.setState({ errors: res.errors });
        }
      });
    }
  }

  handleOpenAddPool() {
    this.setState({ isAddPoolOpen: true });
  }

  handleCloseAddPool() {
    this.setState({ isAddPoolOpen: false });
  }

  render() {
    const { loading, pools, error } = this.props;
    const { errors, canSubmit, isAddPoolOpen, submitting } = this.state;
    const user_type = this.props.identity.user_type;
    const username = this.props.identity.username;
    if (error) return `Error! ${error.message}`;
    if (pools) {
      return (
        <div>
          <MediaQuery query="(min-width: 768px)">
            <Menu />
          </MediaQuery>
          <PoolList
            pools={pools}
            user_type={user_type}
            handleOpenAddPool={this.handleOpenAddPool}
            username={username}
            loading={loading}
          />
          <MediaQuery query="(max-width: 767px)">
            <BottomTray />
          </MediaQuery>
          <PopUnder
            height="300px"
            isOpen={isAddPoolOpen}
            handleClose={this.handleCloseAddPool}
          >
            <form onSubmit={this.submitForm}>
              <div className="form-group">
                <Label>Job Title</Label>
                <TextInput
                  type="text"
                  className="form-control"
                  placeholder="Enter pool name"
                  ref={input => (this.newPoolInput = input)}
                />
                <Label>Company Name</Label>
                <TextInput
                  type="text"
                  className="form-control"
                  placeholder="Enter company name"
                  ref={input => (this.companyNameInput = input)}
                />
              </div>

              {errors && (
                <div className="alert alert-danger" role="alert">
                  {errors[0].message}
                </div>
              )}
              {submitting ? (
                <Submitting>
                  <img src={loadingImage} alt="Creating Talent Pool" />
                </Submitting>
              ) : null}
              <SubmitButtonCentered>
                <SubmitButton
                  type="submit"
                  disabled={!canSubmit}
                  className="btn btn-primary"
                >
                  Create Talent Pool
                </SubmitButton>
              </SubmitButtonCentered>
            </form>
          </PopUnder>
        </div>
      );
    }
    if (loading)
      return (
        <div>
          <MediaQuery query="(min-width: 768px)">
            <Menu />
          </MediaQuery>
          <PoolList loading={loading} />
          <MediaQuery query="(max-width: 767px)">
            <BottomTray />
          </MediaQuery>
        </div>
      );
  }
}

export default withRouter(props => (
  <Query query={GET_POOLS} fetchPolicy="cache-and-network" pollInterval={10000}>
    {({ data: { getAllPools } = {}, subscribeToMore, loading, error }) => (
      <Mutation mutation={ADD_POOL}>
        {mutate => (
          <HomePage
            loading={loading}
            history={props.history}
            pools={getAllPools}
            error={error}
            subscribeToMore={subscribeToMore}
            identity={props.identity}
            submit={({ pool_name, company_name }) =>
              mutate({
                variables: { pool_name, company_name },
                optimisticResponse: {
                  __typename: "Mutation",
                  addPool: {
                    __typename: "Pool",
                    poolId: null,
                    company_name: company_name,
                    pool_name: pool_name
                  }
                }
              })
            }
          />
        )}
      </Mutation>
    )}
  </Query>
));

import gql from 'graphql-tag';

export default gql`
	mutation AddSkill($profileId: ID!, $name: String!, $skill_type: SkillType, $languageCode: String, $proficiencyCode: String, $proficiencyCodeDescription: String) {
	 addSkill(profileId: $profileId, db_candidate_id: "123", name: $name, skill_type: $skill_type, languageCode: $languageCode, proficiencyCode: $proficiencyCode, proficiencyCodeDescription: $proficiencyCodeDescription) {
      objectId
      skillId
      name
      skill_type
      languageCode
      proficiencyCode
      proficiencyCodeDescription
		}
	}
`
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

String.prototype.hashCode = function() {
  var hash = 0,
    i = 0,
    len = this.length;
  while (i < len) {
    hash = ((hash << 5) - hash + this.charCodeAt(i++)) << 0;
  }
  return hash;
};

const colourAvailable = [
  "#f76666",
  "#f3d866",
  "#8cb6fc",
  "#87eeb2",
  "#d7f9e5",
  "#80e7e7",
  "#ffb5bf",
  "#2ba9e3",
  "#f76666",
  "#f3d866"
];

String.prototype.findUserColour = function() {
  var posNumber = this.hashCode();
  var one = String(posNumber).charAt(2);
  return colourAvailable[one];
};

const getSize = size =>
  ({
    small: {
      size: "24px",
      fontSize: "8px",
      fontWeight: "600",
      paddingTop: "2px",
      boxShadow: "none",
      borderWidth: "1px",
      borderRadius: "100%",
      color: "0f2d49"
    },
    medium: {
      size: "30px",
      fontSize: "10px",
      fontWeight: "bold",
      paddingTop: "7px",
      boxShadow: "none",
      borderWidth: "1px",
      borderRadius: "100%",
      color: "0f2d49"
    },
    large: {
      size: "80px",
      fontSize: "24px",
      fontWeight: "bold",
      paddingTop: "33px",
      borderWidth: "0px",
      borderRadius: "100%",
      color: "3f576d"
    },
    chat: {
      size: "30px",
      fontSize: "12px",
      fontWeight: "500",
      paddingTop: "6px",
      borderWidth: "0px",
      borderRadius: "100%",
      color: "3f576d"
    }
  }[size]);

const InitialsOrImageStyled = styled.span.attrs(
  ({ backgroundColor, size }) => ({
    float: backgroundColor,
    size: getSize(size).size,
    fontSize: getSize(size).fontSize,
    fontWeight: getSize(size).fontWeight,
    paddingTop: getSize(size).paddingTop,
    boxShadow: getSize(size).boxShadow,
    borderWidth: getSize(size).borderWidth,
    color: getSize(size).color,
    borderRadius: getSize(size).borderRadius
  })
)`
  display: inline-block;
  box-sizing: border-box;
  width: ${props => props.size};
  height: ${props => props.size};
  padding-top: ${props => props.paddingTop};
  background: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  border: 1px solid #fff;
  border-width: ${props => props.borderWidth};
  box-shadow: ${props => props.boxShadow};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: 18px;
  text-align: center;
  color: ${props => props.color};
`;

const InitialsOrImage = props => {
  let initials;
  const name = props.name;
  const size = props.size;
  const backgroundColor = name.findUserColour();
  if (name.split(" ").length > 1) {
    initials = name
      .split(" ")
      .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
      .join("")
      .toUpperCase();
  } else {
    initials = name.substring(0, 2).toUpperCase();
  }
  return (
    <InitialsOrImageStyled backgroundColor={backgroundColor} size={size}>
      {initials}
    </InitialsOrImageStyled>
  );
};

InitialsOrImage.propTypes = {
  name: PropTypes.string,
  profileId: PropTypes.string,
  size: PropTypes.string,
  backgroundColor: PropTypes.string
};

InitialsOrImage.defaultProps = {
  name: "",
  profileId: "",
  size: "medium",
  backgroundColor: "#80E7E7"
};

export default InitialsOrImage;

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import InitialsOrImage from './InitialsOrImage'

const CandidateStubStyled = styled.div.attrs(({ isDisabled }) => ({
  opacity: isDisabled ? ".5" : "1"
}))`
  opacity: ${props => props.opacity};
	display: inline-block;
	margin-bottom: 10px;
	padding: 0px 20px 0px 0;
	border-radius: 15px;
	background: rgba(212, 247, 247, 0.5);
	font-size: 14px;

	span {
		margin-right: 10px;
		border: none !important;
	}
`

const CandidateStub = (props) => {
	const { name, profileId, handleProfileOpen } = props
	if (handleProfileOpen && profileId) {
		return (
			<CandidateStubStyled isDisabled={false} onClick={ () => handleProfileOpen(profileId) }>
				<InitialsOrImage name={name} />{name}
			</CandidateStubStyled>
		)
	} else {
		return (
			<CandidateStubStyled isDisabled={true}>
				<InitialsOrImage name={name} />{name}
			</CandidateStubStyled>
		)
	}
}

CandidateStub.propTypes = {
  name: PropTypes.string,
  profileId: PropTypes.string,
  handleProfileOpen: PropTypes.func,
}

CandidateStub.defaultProps = { 
  name: '',
  profileId: '',
  handleProfileOpen: undefined,
}


export default CandidateStub
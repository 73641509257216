import gql from 'graphql-tag';

export default gql`
  mutation AddPool($pool_name: String!, $company_name: String) {
    addPool(pool_name: $pool_name, company_name: $company_name) {
      __typename
      poolId
      pool_name
      company_name
    }
  }
 `
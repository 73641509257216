import gql from 'graphql-tag';

export default gql`
  query GetPool($poolId: ID!) {
    getPool(poolId: $poolId) {
      pool_name
      company_name
      identities {
        identityId
        username
        first_name
        last_name
      }
      profileSummaries {
        profileId
        name
        stageId
        job_type
        notice_period
        current_job_title
        recruiter_comments
      }
      stages {
        stageId
        name
        order
        __typename
      }
    }
  }
`
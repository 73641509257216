import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MediaQuery from "react-responsive";

// Images
import EditIcon from "../assets/images/edit-circle.svg";
import ChatIconWhite from "../assets/images/chat-bubble-white.svg";
import ChatIconBlue from "../assets/images/chat-bubble-blue.svg";

// Components
import AddStage from "./AddStage";
import PopUnder from "./PopUnder";
import Scrubber from "./Scrubber";

const isRecruiter = user_type => user_type === "RECRUITER";

const PoolScrubberStyled = styled.div`
  position: fixed;
  top: 50px;
  height: 55px;
  z-index: 20;
  width: 100%;
  max-width: 1200px;
  background: #ffffff;
  box-shadow: 0 2px 3px 0 #cfd5da;
  font-size: 14px;

  & > div:first-child {
    padding: 10px;
    box-sizing: border-box;
  }

  @media (min-width: 900px) {
    box-shadow: 2px 1px 3px 0 #cfd5da;
    box-sizing: border-box;
    width: 250px;
    height: 100%;
    padding: 15px 15px 15px 0;
  }
`;

const StageTag = styled.div.attrs(({ isOpened }) => ({
  backgroundImage: isOpened
    ? "linear-gradient(90deg, #2CD7D8 0%, #2BA9E3 100%)"
    : "none",
  color: isOpened ? "white" : "#2BA9E3"
}))`
  padding: 7px 15px;
  background-image: ${props => props.backgroundImage};
  border-radius: 22px;
  color: ${props => props.color};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  > button {
    color: ${props => props.color};
    padding: 0;
  }
`;

const ChatTag = styled.div.attrs(({ isOpened }) => ({
  backgroundImage: isOpened
    ? "linear-gradient(90deg, #2CD7D8 0%, #2BA9E3 100%)"
    : "none"
}))`
  background-image: ${props => props.backgroundImage};
  margin-right: 10px;
  padding: 7px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  button {
    padding: 0;
    margin: 0;
  }
`;

const Button = styled.button`
  margin: 0 0 0 10px;
  padding: 0;
`;

const ModProcButton = styled.button`
  margin: 10px 0 0 15px;
  padding: 0;
  font-size: 12px;
  color: #0f2d49;
  img {
    width: 25px;
    height: 25px;
    margin-bottom: -6px;
    padding-left: 5px;
  }
`;

const ChatImage = styled.img`
  margin-top: 1px;
  margin-left: 1px;

  @media (min-width: 900px) {
    margin: 0 0 -3px 0;
    padding: 0 10px 0 5px;
  }
`;

const PoolScrubber = props => {
  const { poolId, stages, user_type, handleOpened, opened } = props;
  const [isAddStageOpen, setIsAddStageOpen] = useState(false);
  return (
    <PoolScrubberStyled>
      <MediaQuery query="(max-width: 899px)">
        <Scrubber>
          {opened === "__messages" ? (
            <ChatTag isOpened={true}>
              <ChatImage src={ChatIconWhite} />
            </ChatTag>
          ) : (
            <ChatTag isOpened={false}>
              <button onClick={() => handleOpened("__messages")}>
                <ChatImage src={ChatIconBlue} />
              </button>
            </ChatTag>
          )}
          {stages
            .filter(stage =>
              !isRecruiter(user_type) && stage.stageId === "draft"
                ? false
                : true
            )
            .map((stage, key) => (
              <StageTag
                isOpened={opened === stage.stageId}
                onClick={() => handleOpened(stage.stageId)}
                key={key}
              >
                {stage.name} ({stage.countOfProfiles})
              </StageTag>
            ))}
          <Button onClick={() => setIsAddStageOpen(!isAddStageOpen)}>
            <img src={EditIcon} alt="Add or modify process" />
          </Button>
        </Scrubber>
      </MediaQuery>
      <MediaQuery query="(min-width: 900px)">
        {opened === "__messages" ? (
          <StageTag isOpened={true}>
            <ChatImage src={ChatIconWhite} />
            Messages
          </StageTag>
        ) : (
          <StageTag isOpened={false}>
            <button onClick={() => handleOpened("__messages")}>
              <ChatImage src={ChatIconBlue} />
              Messages
            </button>
          </StageTag>
        )}
        {stages
          .filter(stage =>
            !isRecruiter(user_type) && stage.stageId === "draft" ? false : true
          )
          .map((stage, key) => (
            <StageTag
              isOpened={opened === stage.stageId}
              onClick={() => handleOpened(stage.stageId)}
              key={key}
            >
              {stage.name} ({stage.countOfProfiles})
            </StageTag>
          ))}
        <ModProcButton onClick={() => setIsAddStageOpen(!isAddStageOpen)}>
          Add or modify process{" "}
          <img src={EditIcon} alt="Add or modify process" />
        </ModProcButton>
      </MediaQuery>

      <PopUnder
        title="Add Hiring Process"
        height="500px"
        isOpen={isAddStageOpen}
        handleClose={() => setIsAddStageOpen(false)}
        zIndex={900}
      >
        <AddStage
          poolId={poolId}
          stages={stages}
          handleClose={() => setIsAddStageOpen(false)}
        />
      </PopUnder>
    </PoolScrubberStyled>
  );
};

PoolScrubber.propTypes = {
  poolId: PropTypes.string,
  opened: PropTypes.string,
  stages: PropTypes.array,
  handleOpened: PropTypes.func
};

PoolScrubber.defaultProps = {
  stages: []
};

export default PoolScrubber;

import gql from 'graphql-tag';

export default gql`
	mutation AddHobby($profileId: ID!, $name: String!) {
	 addHobby(profileId: $profileId, db_candidate_id: "123", name: $name) {
      objectId
      hobbyId
      name
		}
	}
`
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CloseIcon from '../assets/images/closePopup.svg'

const PopOverCont = styled.div.attrs(({ isOpen }) => ({
  height: isOpen ? "100%" : "0px",
  opacity: isOpen ? "1" : "0",
  top: isOpen ? "0" : "-9999px",
  buttonDisplay: isOpen ? "block" : "none",
}))`
  position: fixed;
  z-index: 105;
  width: 100%;
  height: ${props => props.height};
  opacity: ${props => props.opacity};
  top: ${props => props.top};
  left: 0;
  overflow: hidden;
  background: rgba(15,45,73,.15);
  transition: opacity 0.3s ease;

  & > div > div > button {
    display: ${props => props.buttonDisplay};
  }
`

const Button = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0;
  background: none;
  border: none;
  text-align: center;

  img {
    width: 38px;
    height: 38px;
  }
`

const PopOverCard = styled.div`
  position: relative;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #FFF;

  @media (min-width: 1200px) {
    margin-top: 23px;
    border: solid 1px #DDD;
    border-radius: 9px;    
  }
`

const PopOverHeader = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
`

const PopOverBody = styled.div`
  height: 100%;
`

const PopOver = (props) => {
  const isOpen = props.isOpen
  return (
    <PopOverCont isOpen={isOpen}>
      <PopOverCard>
        <PopOverBody>
        {props.children}
        </PopOverBody>
        <PopOverHeader>
          <Button onClick={props.handleClose}><img src={CloseIcon} alt="Close" /></Button>
        </PopOverHeader>
      </PopOverCard>
    </PopOverCont>
  )
}

export default PopOver

PopOver.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,  
}

PopOver.defaultProps = { 
  isOpen: false,
}
import React from 'react';
import { Link } from 'react-router-dom';
import Profile from '../../Components/Profile'

const ProfilePage = (props) => {
	const poolId = props.match.params.poolId
  const profileId = props.match.params.profileId
  return (
  	<div className="profile-page">
  		<p>
        <Link to={`/pool/${poolId}`}>
          Back to Pool
        </Link>     
  		</p>
      <h1>Profile</h1>
      <Profile profileId={profileId} />
    </div>
  )
}

export default ProfilePage
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// APOLLO/GRAPHQL
import { Mutation } from "react-apollo"
import UPDATE_POOL from './Queries/UPDATE_POOL'
import GET_POOL from './Queries/GET_POOL'

// Components
import TextInput from './UI/TextInput'
import SubmitButton from './UI/SubmitButton'

// Images
import AddIcon from '../assets/images/button-add.svg'

const AddStageStyled = styled.div`
	display: block;
`

const TextInputStyled = styled(TextInput)`
  margin-bottom: 0;
`

const TextButtonCont = styled.div`
  display: grid;
  grid-template-columns: auto 60px;
`

const Stages = styled.div`
  margin-top: 20px;
`

const StageItem = styled.div`
  margin: 23px 17px;
  color: #0F2D49;
  font-size: 14px;
`

const SubmitButtonCentered = styled.div`
  text-align: center;
`

const Button = styled.button`
  justify-self: end;
  padding: 0;
`

const AddStage = (props) => {
  let stage_name, form
	const { poolId, stages, handleClose } = props
  const customStages = stages
    .filter(obj => (obj.stageId !== 'draft'))
    .filter(obj => (obj.stageId !== 'pool'))
    .filter(obj => (obj.stageId !== 'shortlist'))
    .filter(obj => (obj.stageId !== 'not_interested'))

	return (
    <Mutation 
      mutation={UPDATE_POOL}
      >
      {(updatePool, { data }) => (

    		<AddStageStyled>
          <form
            ref={node => form = node}
            onSubmit={e => {
              e.preventDefault()
              const newStageId = stage_name.value.trim().replace(/\s+/g, '_').toLowerCase()
              const newStage = {
                stageId: newStageId,
                name: stage_name.value,
                order: stages.length
              }
              const stagesToSend = stages.map(({ __typename, countOfProfiles, ...stage }) => stage)
              stagesToSend.push(newStage)
              updatePool({ 
                variables: { 
                  poolId: poolId, 
                  stages: stagesToSend 
                },
                update: (cache, { data: { addProfile } }) => {
                  const query = GET_POOL
                  const variables = { poolId: poolId }
                  const data = cache.readQuery({ query, variables })
                  data.getPool.stages = stagesToSend.map((stage) => {
                    stage.__typename = 'PoolStage'
                    return stage
                  })
                  cache.writeQuery({ query, variables, data })
                }})
              stage_name.value = ""
            }}
          >
            <TextButtonCont>
              <TextInputStyled
                type="text"
                placeholder="e.g. 1st Round Interview"
                required
                ref={node => {
                  stage_name = node
                }}
              />
              <Button type="submit"><img src={AddIcon} alt="Add a Hiring Process" /></Button>
            </TextButtonCont>

            <Stages>
              { customStages.map((stage, key) => (
                <StageItem key={key}>{stage.name}</StageItem>
              ))}
            </Stages>

            <SubmitButtonCentered>
              <SubmitButton onClick={handleClose}>Close</SubmitButton>
            </SubmitButtonCentered>
           
          </form>

    		</AddStageStyled>

      )}
    </Mutation>
	)
}

AddStage.propTypes = {
  poolId: PropTypes.string,
  stages: PropTypes.array,
  handleClose: PropTypes.func
}

AddStage.defaultProps = { 
  stages: [],
}

export default AddStage
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Images
import talentPoolsIconActive from "../assets/images/tray-talent-pools-active.svg";
import talentPoolsIcon from "../assets/images/tray-talent-pools.svg";
import profilesIcon from "../assets/images/tray-profiles.svg";
import profilesIconActive from "../assets/images/tray-profiles-active.svg";

// Components
import FixedWidthContainer from "./UI/FixedWidthContainer";

const FixedWidthContainerBottom = styled(FixedWidthContainer)`
  top: auto;
  bottom: 0px;
`;

const TrayCont = styled.div`
  background: #ff475f;
  padding: 7px 0px;
  color: white;
`;

const Tray = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0px;
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;
`;

const TrayItem = styled.li`
  margin: 0;
  padding: 0;

  img {
    padding: 8px 20px;
    height: 25px;
  }
`;

const TalentPoolsImage = props => {
  if (props.isActive) {
    return <img src={talentPoolsIconActive} alt="Messages" />;
  } else {
    return <img src={talentPoolsIcon} alt="Messages" />;
  }
};

const CandidatesImage = props => {
  if (props.isActive) {
    return <img src={profilesIconActive} alt="Messages" />;
  } else {
    return <img src={profilesIcon} alt="Messages" />;
  }
};

const BottomTray = props => {
  const pageVisible = props.pageVisible;
  return (
    <FixedWidthContainerBottom>
      <TrayCont>
        <Tray>
          <TrayItem>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <TalentPoolsImage isActive={pageVisible === "pool"} />
            </Link>
          </TrayItem>
          <TrayItem>
            <Link to="/account" onClick={() => window.scrollTo(0, 0)}>
              <CandidatesImage isActive={pageVisible === "account"} />
            </Link>
          </TrayItem>
        </Tray>
      </TrayCont>
    </FixedWidthContainerBottom>
  );
};

BottomTray.propTypes = {
  pageVisible: PropTypes.string
};

BottomTray.defaultProps = {
  pageVisible: "pool"
};

export default BottomTray;

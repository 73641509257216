const languageSuggestions = [
	{ id: 'AA-2', text: 'Afar (Basic Knowledge)' },
	{ id: 'AB-2', text: 'Abkhazian (Basic Knowledge)' },
	{ id: 'AE-2', text: 'Avestan (Basic Knowledge)' },
	{ id: 'AF-2', text: 'Afrikaans (Basic Knowledge)' },
	{ id: 'AK-2', text: 'Akan (Basic Knowledge)' },
	{ id: 'AM-2', text: 'Amharic (Basic Knowledge)' },
	{ id: 'AN-2', text: 'Aragonese (Basic Knowledge)' },
	{ id: 'AR-2', text: 'Arabic (Basic Knowledge)' },
	{ id: 'AS-2', text: 'Assamese (Basic Knowledge)' },
	{ id: 'AV-2', text: 'Avaric (Basic Knowledge)' },
	{ id: 'AY-2', text: 'Aymara (Basic Knowledge)' },
	{ id: 'AZ-2', text: 'Azeri (Basic Knowledge)' },
	{ id: 'BA-2', text: 'Bashkir (Basic Knowledge)' },
	{ id: 'BE-2', text: 'Belarusian (Basic Knowledge)' },
	{ id: 'BG-2', text: 'Bulgarian (Basic Knowledge)' },
	{ id: 'BH-2', text: 'Bihari (Basic Knowledge)' },
	{ id: 'BI-2', text: 'Bislama (Basic Knowledge)' },
	{ id: 'BM-2', text: 'Bambara (Basic Knowledge)' },
	{ id: 'BN-2', text: 'Bangladeshi (Basic Knowledge)' },
	{ id: 'BO-2', text: 'Tibetan (Basic Knowledge)' },
	{ id: 'BR-2', text: 'Bretón (Basic Knowledge)' },
	{ id: 'BS-2', text: 'Bosnian (Basic Knowledge)' },
	{ id: 'CA-2', text: 'Catalan (Basic Knowledge)' },
	{ id: 'CE-2', text: 'Chechen (Basic Knowledge)' },
	{ id: 'CH-2', text: 'Chamorro (Basic Knowledge)' },
	{ id: 'CO-2', text: 'Corsican (Basic Knowledge)' },
	{ id: 'CR-2', text: 'Cree (Basic Knowledge)' },
	{ id: 'CS-2', text: 'Czech (Basic Knowledge)' },
	{ id: 'CV-2', text: 'Chuvash (Basic Knowledge)' },
	{ id: 'CY-2', text: 'Welsh (Basic Knowledge)' },
	{ id: 'DA-2', text: 'Danish (Basic Knowledge)' },
	{ id: 'DE-2', text: 'German (Basic Knowledge)' },
	{ id: 'DV-2', text: 'Divehi (Basic Knowledge)' },
	{ id: 'DZ-2', text: 'Bhutanese (Basic Knowledge)' },
	{ id: 'EE-2', text: 'Ewe (Basic Knowledge)' },
	{ id: 'EL-2', text: 'Greek (Basic Knowledge)' },
	{ id: 'EN-2', text: 'English (Basic Knowledge)' },
	{ id: 'EO-2', text: 'Esperanto (Basic Knowledge)' },
	{ id: 'ES-2', text: 'Spanish (Basic Knowledge)' },
	{ id: 'ET-2', text: 'Estonian (Basic Knowledge)' },
	{ id: 'EU-2', text: 'Basque (Basic Knowledge)' },
	{ id: 'FA-2', text: 'Persian (Basic Knowledge)' },
	{ id: 'FF-2', text: 'Fulah (Basic Knowledge)' },
	{ id: 'FI-2', text: 'Finnish (Basic Knowledge)' },
	{ id: 'FJ-2', text: 'Fijian (Basic Knowledge)' },
	{ id: 'FO-2', text: 'Faroese (Basic Knowledge)' },
	{ id: 'FR-2', text: 'French (Basic Knowledge)' },
	{ id: 'FY-2', text: 'West Frisian (Basic Knowledge)' },
	{ id: 'GA-2', text: 'Irish (Gaelic) (Basic Knowledge)' },
	{ id: 'GD-2', text: 'Scottish Gaelic (Basic Knowledge)' },
	{ id: 'GL-2', text: 'Galician (Basic Knowledge)' },
	{ id: 'GN-2', text: 'Guarani (Basic Knowledge)' },
	{ id: 'GU-2', text: 'Gujarati (Basic Knowledge)' },
	{ id: 'GV-2', text: 'Manx (Basic Knowledge)' },
	{ id: 'HA-2', text: 'Hausa (Basic Knowledge)' },
	{ id: 'HE-2', text: 'Hebrew (Basic Knowledge)' },
	{ id: 'HI-2', text: 'Hindi (Basic Knowledge)' },
	{ id: 'HR-2', text: 'Croatian (Basic Knowledge)' },
	{ id: 'HT-2', text: 'Haitian (Basic Knowledge)' },
	{ id: 'HU-2', text: 'Hungarian (Basic Knowledge)' },
	{ id: 'HY-2', text: 'Armenian (Basic Knowledge)' },
	{ id: 'HZ-2', text: 'Herero (Basic Knowledge)' },
	{ id: 'IA-2', text: 'Interlingua (Basic Knowledge)' },
	{ id: 'ID-2', text: 'Indonesian (Basic Knowledge)' },
	{ id: 'IG-2', text: 'Igbo (Basic Knowledge)' },
	{ id: 'II-2', text: 'Sichuan Yi (Basic Knowledge)' },
	{ id: 'IK-2', text: 'Inupiaq (Basic Knowledge)' },
	{ id: 'IS-2', text: 'Icelandic (Basic Knowledge)' },
	{ id: 'IT-2', text: 'Italian (Basic Knowledge)' },
	{ id: 'IU-2', text: 'Inuktitut (Basic Knowledge)' },
	{ id: 'JA-2', text: 'Japanese (Basic Knowledge)' },
	{ id: 'JV-2', text: 'Javanese (Basic Knowledge)' },
	{ id: 'KA-2', text: 'Georgian (Basic Knowledge)' },
	{ id: 'KG-2', text: 'Kongo (Basic Knowledge)' },
	{ id: 'KI-2', text: 'Kikuyu (Basic Knowledge)' },
	{ id: 'KJ-2', text: 'Kuanyama (Basic Knowledge)' },
	{ id: 'KK-2', text: 'Kazakh (Basic Knowledge)' },
	{ id: 'KL-2', text: 'Greenlandic (Basic Knowledge)' },
	{ id: 'KM-2', text: 'Cambodian (Basic Knowledge)' },
	{ id: 'KN-2', text: 'Kannada (Basic Knowledge)' },
	{ id: 'KO-2', text: 'Korean (Basic Knowledge)' },
	{ id: 'KR-2', text: 'Kanuri (Basic Knowledge)' },
	{ id: 'KS-2', text: 'Kashmiri (Basic Knowledge)' },
	{ id: 'KU-2', text: 'Kurdish (Basic Knowledge)' },
	{ id: 'KV-2', text: 'Komi (Basic Knowledge)' },
	{ id: 'KW-2', text: 'Cornish (Basic Knowledge)' },
	{ id: 'KY-2', text: 'Kirghiz (Basic Knowledge)' },
	{ id: 'LA-2', text: 'Latin (Basic Knowledge)' },
	{ id: 'LB-2', text: 'Luxembourgish (Basic Knowledge)' },
	{ id: 'LG-2', text: 'Ganda (Basic Knowledge)' },
	{ id: 'LI-2', text: 'Limburgan (Basic Knowledge)' },
	{ id: 'LN-2', text: 'Lingala (Basic Knowledge)' },
	{ id: 'LO-2', text: 'Lao (Basic Knowledge)' },
	{ id: 'LT-2', text: 'Lithuanian (Basic Knowledge)' },
	{ id: 'LU-2', text: 'Luba-Katanga (Basic Knowledge)' },
	{ id: 'LV-2', text: 'Latvian (Basic Knowledge)' },
	{ id: 'ME-2', text: 'Montenegrin (Basic Knowledge)' },
	{ id: 'MG-2', text: 'Malagasy (Basic Knowledge)' },
	{ id: 'MH-2', text: 'Marshallese (Basic Knowledge)' },
	{ id: 'MI-2', text: 'Maori (Basic Knowledge)' },
	{ id: 'MK-2', text: 'Macedonian (Basic Knowledge)' },
	{ id: 'ML-2', text: 'Malayalam (Basic Knowledge)' },
	{ id: 'MN-2', text: 'Mongolian (Basic Knowledge)' },
	{ id: 'MR-2', text: 'Marathi (Basic Knowledge)' },
	{ id: 'MS-2', text: 'Malaysian (Basic Knowledge)' },
	{ id: 'MT-2', text: 'Maltese (Basic Knowledge)' },
	{ id: 'MY-2', text: 'Myanmarese (Basic Knowledge)' },
	{ id: 'ND-2', text: 'North Ndebele (Basic Knowledge)' },
	{ id: 'NE-2', text: 'Nepali (Basic Knowledge)' },
	{ id: 'NG-2', text: 'Ndonga (Basic Knowledge)' },
	{ id: 'NL-2', text: 'Dutch (Basic Knowledge)' },
	{ id: 'NO-2', text: 'Norwegian (Basic Knowledge)' },
	{ id: 'NR-2', text: 'South Ndebele (Basic Knowledge)' },
	{ id: 'NV-2', text: 'Navajo (Basic Knowledge)' },
	{ id: 'NY-2', text: 'Nyanja (Basic Knowledge)' },
	{ id: 'OC-2', text: 'Occitan (Basic Knowledge)' },
	{ id: 'OJ-2', text: 'Ojibwa (Basic Knowledge)' },
	{ id: 'OM-2', text: 'Oromo (Basic Knowledge)' },
	{ id: 'OR-2', text: 'Oriya (Basic Knowledge)' },
	{ id: 'OS-2', text: 'Ossetic (Basic Knowledge)' },
	{ id: 'PA-2', text: 'Punjabi (Basic Knowledge)' },
	{ id: 'PA-2', text: 'Papiamento (Basic Knowledge)' },
	{ id: 'PL-2', text: 'Polish (Basic Knowledge)' },
	{ id: 'PS-2', text: 'Pushto (Basic Knowledge)' },
	{ id: 'PT-2', text: 'Portuguese (Basic Knowledge)' },
	{ id: 'QU-2', text: 'Quechua (Basic Knowledge)' },
	{ id: 'RM-2', text: 'Romansh (Basic Knowledge)' },
	{ id: 'RN-2', text: 'Rundi (Basic Knowledge)' },
	{ id: 'RO-2', text: 'Romanian (Basic Knowledge)' },
	{ id: 'RU-2', text: 'Russian (Basic Knowledge)' },
	{ id: 'RW-2', text: 'Kinyarwanda (Basic Knowledge)' },
	{ id: 'SA-2', text: 'Sanskrit (Basic Knowledge)' },
	{ id: 'SC-2', text: 'Sardinian (Basic Knowledge)' },
	{ id: 'SD-2', text: 'Sindhi (Basic Knowledge)' },
	{ id: 'SE-2', text: 'Northern Sami (Basic Knowledge)' },
	{ id: 'SG-2', text: 'Sango (Basic Knowledge)' },
	{ id: 'SI-2', text: 'Sinhala (Basic Knowledge)' },
	{ id: 'SK-2', text: 'Slovak (Basic Knowledge)' },
	{ id: 'SL-2', text: 'Slovenian (Basic Knowledge)' },
	{ id: 'SM-2', text: 'Samoan (Basic Knowledge)' },
	{ id: 'SN-2', text: 'Shona (Basic Knowledge)' },
	{ id: 'SO-2', text: 'Somali (Basic Knowledge)' },
	{ id: 'SQ-2', text: 'Albanian (Basic Knowledge)' },
	{ id: 'SR-2', text: 'Serbian (Basic Knowledge)' },
	{ id: 'SS-2', text: 'Swati (Basic Knowledge)' },
	{ id: 'ST-2', text: 'Southern Sotho (Basic Knowledge)' },
	{ id: 'SV-2', text: 'Swedish (Basic Knowledge)' },
	{ id: 'SW-2', text: 'Swahili (Basic Knowledge)' },
	{ id: 'TA-2', text: 'Tamil (Basic Knowledge)' },
	{ id: 'TE-2', text: 'Telugu (Basic Knowledge)' },
	{ id: 'TG-2', text: 'Tajik (Basic Knowledge)' },
	{ id: 'TH-2', text: 'Thai (Basic Knowledge)' },
	{ id: 'TI-2', text: 'Tigrinya (Basic Knowledge)' },
	{ id: 'TK-2', text: 'Turkmen (Basic Knowledge)' },
	{ id: 'TL-2', text: 'Filipino (Basic Knowledge)' },
	{ id: 'TN-2', text: 'Tswana (Basic Knowledge)' },
	{ id: 'TO-2', text: 'Tonga (Basic Knowledge)' },
	{ id: 'TR-2', text: 'Turkish (Basic Knowledge)' },
	{ id: 'TS-2', text: 'Tsonga (Basic Knowledge)' },
	{ id: 'TW-2', text: 'Twi (Basic Knowledge)' },
	{ id: 'TY-2', text: 'Tahitian (Basic Knowledge)' },
	{ id: 'UG-2', text: 'Uyghur (Basic Knowledge)' },
	{ id: 'UK-2', text: 'Ukrainian (Basic Knowledge)' },
	{ id: 'UR-2', text: 'Urdu (Basic Knowledge)' },
	{ id: 'UZ-2', text: 'Uzbek (Basic Knowledge)' },
	{ id: 'VE-2', text: 'Venda (Basic Knowledge)' },
	{ id: 'VI-2', text: 'Vietnamese (Basic Knowledge)' },
	{ id: 'WO-2', text: 'Wolof (Basic Knowledge)' },
	{ id: 'XH-2', text: 'Xhosa (Basic Knowledge)' },
	{ id: 'YI-2', text: 'Yiddish (Basic Knowledge)' },
	{ id: 'YO-2', text: 'Yoruba (Basic Knowledge)' },
	{ id: 'ZA-2', text: 'Zhuang (Basic Knowledge)' },
	{ id: 'ZH-2', text: 'Chinese (Basic Knowledge)' },
	{ id: 'ZU-2', text: 'Zulu (Basic Knowledge)' },
	{ id: 'AA-3', text: 'Afar (Intermediate)' },
	{ id: 'AB-3', text: 'Abkhazian (Intermediate)' },
	{ id: 'AE-3', text: 'Avestan (Intermediate)' },
	{ id: 'AF-3', text: 'Afrikaans (Intermediate)' },
	{ id: 'AK-3', text: 'Akan (Intermediate)' },
	{ id: 'AM-3', text: 'Amharic (Intermediate)' },
	{ id: 'AN-3', text: 'Aragonese (Intermediate)' },
	{ id: 'AR-3', text: 'Arabic (Intermediate)' },
	{ id: 'AS-3', text: 'Assamese (Intermediate)' },
	{ id: 'AV-3', text: 'Avaric (Intermediate)' },
	{ id: 'AY-3', text: 'Aymara (Intermediate)' },
	{ id: 'AZ-3', text: 'Azeri (Intermediate)' },
	{ id: 'BA-3', text: 'Bashkir (Intermediate)' },
	{ id: 'BE-3', text: 'Belarusian (Intermediate)' },
	{ id: 'BG-3', text: 'Bulgarian (Intermediate)' },
	{ id: 'BH-3', text: 'Bihari (Intermediate)' },
	{ id: 'BI-3', text: 'Bislama (Intermediate)' },
	{ id: 'BM-3', text: 'Bambara (Intermediate)' },
	{ id: 'BN-3', text: 'Bangladeshi (Intermediate)' },
	{ id: 'BO-3', text: 'Tibetan (Intermediate)' },
	{ id: 'BR-3', text: 'Bretón (Intermediate)' },
	{ id: 'BS-3', text: 'Bosnian (Intermediate)' },
	{ id: 'CA-3', text: 'Catalan (Intermediate)' },
	{ id: 'CE-3', text: 'Chechen (Intermediate)' },
	{ id: 'CH-3', text: 'Chamorro (Intermediate)' },
	{ id: 'CO-3', text: 'Corsican (Intermediate)' },
	{ id: 'CR-3', text: 'Cree (Intermediate)' },
	{ id: 'CS-3', text: 'Czech (Intermediate)' },
	{ id: 'CV-3', text: 'Chuvash (Intermediate)' },
	{ id: 'CY-3', text: 'Welsh (Intermediate)' },
	{ id: 'DA-3', text: 'Danish (Intermediate)' },
	{ id: 'DE-3', text: 'German (Intermediate)' },
	{ id: 'DV-3', text: 'Divehi (Intermediate)' },
	{ id: 'DZ-3', text: 'Bhutanese (Intermediate)' },
	{ id: 'EE-3', text: 'Ewe (Intermediate)' },
	{ id: 'EL-3', text: 'Greek (Intermediate)' },
	{ id: 'EN-3', text: 'English (Intermediate)' },
	{ id: 'EO-3', text: 'Esperanto (Intermediate)' },
	{ id: 'ES-3', text: 'Spanish (Intermediate)' },
	{ id: 'ET-3', text: 'Estonian (Intermediate)' },
	{ id: 'EU-3', text: 'Basque (Intermediate)' },
	{ id: 'FA-3', text: 'Persian (Intermediate)' },
	{ id: 'FF-3', text: 'Fulah (Intermediate)' },
	{ id: 'FI-3', text: 'Finnish (Intermediate)' },
	{ id: 'FJ-3', text: 'Fijian (Intermediate)' },
	{ id: 'FO-3', text: 'Faroese (Intermediate)' },
	{ id: 'FR-3', text: 'French (Intermediate)' },
	{ id: 'FY-3', text: 'West Frisian (Intermediate)' },
	{ id: 'GA-3', text: 'Irish (Gaelic) (Intermediate)' },
	{ id: 'GD-3', text: 'Scottish Gaelic (Intermediate)' },
	{ id: 'GL-3', text: 'Galician (Intermediate)' },
	{ id: 'GN-3', text: 'Guarani (Intermediate)' },
	{ id: 'GU-3', text: 'Gujarati (Intermediate)' },
	{ id: 'GV-3', text: 'Manx (Intermediate)' },
	{ id: 'HA-3', text: 'Hausa (Intermediate)' },
	{ id: 'HE-3', text: 'Hebrew (Intermediate)' },
	{ id: 'HI-3', text: 'Hindi (Intermediate)' },
	{ id: 'HR-3', text: 'Croatian (Intermediate)' },
	{ id: 'HT-3', text: 'Haitian (Intermediate)' },
	{ id: 'HU-3', text: 'Hungarian (Intermediate)' },
	{ id: 'HY-3', text: 'Armenian (Intermediate)' },
	{ id: 'HZ-3', text: 'Herero (Intermediate)' },
	{ id: 'IA-3', text: 'Interlingua (Intermediate)' },
	{ id: 'ID-3', text: 'Indonesian (Intermediate)' },
	{ id: 'IG-3', text: 'Igbo (Intermediate)' },
	{ id: 'II-3', text: 'Sichuan Yi (Intermediate)' },
	{ id: 'IK-3', text: 'Inupiaq (Intermediate)' },
	{ id: 'IS-3', text: 'Icelandic (Intermediate)' },
	{ id: 'IT-3', text: 'Italian (Intermediate)' },
	{ id: 'IU-3', text: 'Inuktitut (Intermediate)' },
	{ id: 'JA-3', text: 'Japanese (Intermediate)' },
	{ id: 'JV-3', text: 'Javanese (Intermediate)' },
	{ id: 'KA-3', text: 'Georgian (Intermediate)' },
	{ id: 'KG-3', text: 'Kongo (Intermediate)' },
	{ id: 'KI-3', text: 'Kikuyu (Intermediate)' },
	{ id: 'KJ-3', text: 'Kuanyama (Intermediate)' },
	{ id: 'KK-3', text: 'Kazakh (Intermediate)' },
	{ id: 'KL-3', text: 'Greenlandic (Intermediate)' },
	{ id: 'KM-3', text: 'Cambodian (Intermediate)' },
	{ id: 'KN-3', text: 'Kannada (Intermediate)' },
	{ id: 'KO-3', text: 'Korean (Intermediate)' },
	{ id: 'KR-3', text: 'Kanuri (Intermediate)' },
	{ id: 'KS-3', text: 'Kashmiri (Intermediate)' },
	{ id: 'KU-3', text: 'Kurdish (Intermediate)' },
	{ id: 'KV-3', text: 'Komi (Intermediate)' },
	{ id: 'KW-3', text: 'Cornish (Intermediate)' },
	{ id: 'KY-3', text: 'Kirghiz (Intermediate)' },
	{ id: 'LA-3', text: 'Latin (Intermediate)' },
	{ id: 'LB-3', text: 'Luxembourgish (Intermediate)' },
	{ id: 'LG-3', text: 'Ganda (Intermediate)' },
	{ id: 'LI-3', text: 'Limburgan (Intermediate)' },
	{ id: 'LN-3', text: 'Lingala (Intermediate)' },
	{ id: 'LO-3', text: 'Lao (Intermediate)' },
	{ id: 'LT-3', text: 'Lithuanian (Intermediate)' },
	{ id: 'LU-3', text: 'Luba-Katanga (Intermediate)' },
	{ id: 'LV-3', text: 'Latvian (Intermediate)' },
	{ id: 'ME-3', text: 'Montenegrin (Intermediate)' },
	{ id: 'MG-3', text: 'Malagasy (Intermediate)' },
	{ id: 'MH-3', text: 'Marshallese (Intermediate)' },
	{ id: 'MI-3', text: 'Maori (Intermediate)' },
	{ id: 'MK-3', text: 'Macedonian (Intermediate)' },
	{ id: 'ML-3', text: 'Malayalam (Intermediate)' },
	{ id: 'MN-3', text: 'Mongolian (Intermediate)' },
	{ id: 'MR-3', text: 'Marathi (Intermediate)' },
	{ id: 'MS-3', text: 'Malaysian (Intermediate)' },
	{ id: 'MT-3', text: 'Maltese (Intermediate)' },
	{ id: 'MY-3', text: 'Myanmarese (Intermediate)' },
	{ id: 'ND-3', text: 'North Ndebele (Intermediate)' },
	{ id: 'NE-3', text: 'Nepali (Intermediate)' },
	{ id: 'NG-3', text: 'Ndonga (Intermediate)' },
	{ id: 'NL-3', text: 'Dutch (Intermediate)' },
	{ id: 'NO-3', text: 'Norwegian (Intermediate)' },
	{ id: 'NR-3', text: 'South Ndebele (Intermediate)' },
	{ id: 'NV-3', text: 'Navajo (Intermediate)' },
	{ id: 'NY-3', text: 'Nyanja (Intermediate)' },
	{ id: 'OC-3', text: 'Occitan (Intermediate)' },
	{ id: 'OJ-3', text: 'Ojibwa (Intermediate)' },
	{ id: 'OM-3', text: 'Oromo (Intermediate)' },
	{ id: 'OR-3', text: 'Oriya (Intermediate)' },
	{ id: 'OS-3', text: 'Ossetic (Intermediate)' },
	{ id: 'PA-3', text: 'Punjabi (Intermediate)' },
	{ id: 'PA-3', text: 'Papiamento (Intermediate)' },
	{ id: 'PL-3', text: 'Polish (Intermediate)' },
	{ id: 'PS-3', text: 'Pushto (Intermediate)' },
	{ id: 'PT-3', text: 'Portuguese (Intermediate)' },
	{ id: 'QU-3', text: 'Quechua (Intermediate)' },
	{ id: 'RM-3', text: 'Romansh (Intermediate)' },
	{ id: 'RN-3', text: 'Rundi (Intermediate)' },
	{ id: 'RO-3', text: 'Romanian (Intermediate)' },
	{ id: 'RU-3', text: 'Russian (Intermediate)' },
	{ id: 'RW-3', text: 'Kinyarwanda (Intermediate)' },
	{ id: 'SA-3', text: 'Sanskrit (Intermediate)' },
	{ id: 'SC-3', text: 'Sardinian (Intermediate)' },
	{ id: 'SD-3', text: 'Sindhi (Intermediate)' },
	{ id: 'SE-3', text: 'Northern Sami (Intermediate)' },
	{ id: 'SG-3', text: 'Sango (Intermediate)' },
	{ id: 'SI-3', text: 'Sinhala (Intermediate)' },
	{ id: 'SK-3', text: 'Slovak (Intermediate)' },
	{ id: 'SL-3', text: 'Slovenian (Intermediate)' },
	{ id: 'SM-3', text: 'Samoan (Intermediate)' },
	{ id: 'SN-3', text: 'Shona (Intermediate)' },
	{ id: 'SO-3', text: 'Somali (Intermediate)' },
	{ id: 'SQ-3', text: 'Albanian (Intermediate)' },
	{ id: 'SR-3', text: 'Serbian (Intermediate)' },
	{ id: 'SS-3', text: 'Swati (Intermediate)' },
	{ id: 'ST-3', text: 'Southern Sotho (Intermediate)' },
	{ id: 'SV-3', text: 'Swedish (Intermediate)' },
	{ id: 'SW-3', text: 'Swahili (Intermediate)' },
	{ id: 'TA-3', text: 'Tamil (Intermediate)' },
	{ id: 'TE-3', text: 'Telugu (Intermediate)' },
	{ id: 'TG-3', text: 'Tajik (Intermediate)' },
	{ id: 'TH-3', text: 'Thai (Intermediate)' },
	{ id: 'TI-3', text: 'Tigrinya (Intermediate)' },
	{ id: 'TK-3', text: 'Turkmen (Intermediate)' },
	{ id: 'TL-3', text: 'Filipino (Intermediate)' },
	{ id: 'TN-3', text: 'Tswana (Intermediate)' },
	{ id: 'TO-3', text: 'Tonga (Intermediate)' },
	{ id: 'TR-3', text: 'Turkish (Intermediate)' },
	{ id: 'TS-3', text: 'Tsonga (Intermediate)' },
	{ id: 'TW-3', text: 'Twi (Intermediate)' },
	{ id: 'TY-3', text: 'Tahitian (Intermediate)' },
	{ id: 'UG-3', text: 'Uyghur (Intermediate)' },
	{ id: 'UK-3', text: 'Ukrainian (Intermediate)' },
	{ id: 'UR-3', text: 'Urdu (Intermediate)' },
	{ id: 'UZ-3', text: 'Uzbek (Intermediate)' },
	{ id: 'VE-3', text: 'Venda (Intermediate)' },
	{ id: 'VI-3', text: 'Vietnamese (Intermediate)' },
	{ id: 'WO-3', text: 'Wolof (Intermediate)' },
	{ id: 'XH-3', text: 'Xhosa (Intermediate)' },
	{ id: 'YI-3', text: 'Yiddish (Intermediate)' },
	{ id: 'YO-3', text: 'Yoruba (Intermediate)' },
	{ id: 'ZA-3', text: 'Zhuang (Intermediate)' },
	{ id: 'ZH-3', text: 'Chinese (Intermediate)' },
	{ id: 'ZU-3', text: 'Zulu (Intermediate)' },
	{ id: 'AA-4', text: 'Afar (Advanced)' },
	{ id: 'AB-4', text: 'Abkhazian (Advanced)' },
	{ id: 'AE-4', text: 'Avestan (Advanced)' },
	{ id: 'AF-4', text: 'Afrikaans (Advanced)' },
	{ id: 'AK-4', text: 'Akan (Advanced)' },
	{ id: 'AM-4', text: 'Amharic (Advanced)' },
	{ id: 'AN-4', text: 'Aragonese (Advanced)' },
	{ id: 'AR-4', text: 'Arabic (Advanced)' },
	{ id: 'AS-4', text: 'Assamese (Advanced)' },
	{ id: 'AV-4', text: 'Avaric (Advanced)' },
	{ id: 'AY-4', text: 'Aymara (Advanced)' },
	{ id: 'AZ-4', text: 'Azeri (Advanced)' },
	{ id: 'BA-4', text: 'Bashkir (Advanced)' },
	{ id: 'BE-4', text: 'Belarusian (Advanced)' },
	{ id: 'BG-4', text: 'Bulgarian (Advanced)' },
	{ id: 'BH-4', text: 'Bihari (Advanced)' },
	{ id: 'BI-4', text: 'Bislama (Advanced)' },
	{ id: 'BM-4', text: 'Bambara (Advanced)' },
	{ id: 'BN-4', text: 'Bangladeshi (Advanced)' },
	{ id: 'BO-4', text: 'Tibetan (Advanced)' },
	{ id: 'BR-4', text: 'Bretón (Advanced)' },
	{ id: 'BS-4', text: 'Bosnian (Advanced)' },
	{ id: 'CA-4', text: 'Catalan (Advanced)' },
	{ id: 'CE-4', text: 'Chechen (Advanced)' },
	{ id: 'CH-4', text: 'Chamorro (Advanced)' },
	{ id: 'CO-4', text: 'Corsican (Advanced)' },
	{ id: 'CR-4', text: 'Cree (Advanced)' },
	{ id: 'CS-4', text: 'Czech (Advanced)' },
	{ id: 'CV-4', text: 'Chuvash (Advanced)' },
	{ id: 'CY-4', text: 'Welsh (Advanced)' },
	{ id: 'DA-4', text: 'Danish (Advanced)' },
	{ id: 'DE-4', text: 'German (Advanced)' },
	{ id: 'DV-4', text: 'Divehi (Advanced)' },
	{ id: 'DZ-4', text: 'Bhutanese (Advanced)' },
	{ id: 'EE-4', text: 'Ewe (Advanced)' },
	{ id: 'EL-4', text: 'Greek (Advanced)' },
	{ id: 'EN-4', text: 'English (Advanced)' },
	{ id: 'EO-4', text: 'Esperanto (Advanced)' },
	{ id: 'ES-4', text: 'Spanish (Advanced)' },
	{ id: 'ET-4', text: 'Estonian (Advanced)' },
	{ id: 'EU-4', text: 'Basque (Advanced)' },
	{ id: 'FA-4', text: 'Persian (Advanced)' },
	{ id: 'FF-4', text: 'Fulah (Advanced)' },
	{ id: 'FI-4', text: 'Finnish (Advanced)' },
	{ id: 'FJ-4', text: 'Fijian (Advanced)' },
	{ id: 'FO-4', text: 'Faroese (Advanced)' },
	{ id: 'FR-4', text: 'French (Advanced)' },
	{ id: 'FY-4', text: 'West Frisian (Advanced)' },
	{ id: 'GA-4', text: 'Irish (Gaelic) (Advanced)' },
	{ id: 'GD-4', text: 'Scottish Gaelic (Advanced)' },
	{ id: 'GL-4', text: 'Galician (Advanced)' },
	{ id: 'GN-4', text: 'Guarani (Advanced)' },
	{ id: 'GU-4', text: 'Gujarati (Advanced)' },
	{ id: 'GV-4', text: 'Manx (Advanced)' },
	{ id: 'HA-4', text: 'Hausa (Advanced)' },
	{ id: 'HE-4', text: 'Hebrew (Advanced)' },
	{ id: 'HI-4', text: 'Hindi (Advanced)' },
	{ id: 'HR-4', text: 'Croatian (Advanced)' },
	{ id: 'HT-4', text: 'Haitian (Advanced)' },
	{ id: 'HU-4', text: 'Hungarian (Advanced)' },
	{ id: 'HY-4', text: 'Armenian (Advanced)' },
	{ id: 'HZ-4', text: 'Herero (Advanced)' },
	{ id: 'IA-4', text: 'Interlingua (Advanced)' },
	{ id: 'ID-4', text: 'Indonesian (Advanced)' },
	{ id: 'IG-4', text: 'Igbo (Advanced)' },
	{ id: 'II-4', text: 'Sichuan Yi (Advanced)' },
	{ id: 'IK-4', text: 'Inupiaq (Advanced)' },
	{ id: 'IS-4', text: 'Icelandic (Advanced)' },
	{ id: 'IT-4', text: 'Italian (Advanced)' },
	{ id: 'IU-4', text: 'Inuktitut (Advanced)' },
	{ id: 'JA-4', text: 'Japanese (Advanced)' },
	{ id: 'JV-4', text: 'Javanese (Advanced)' },
	{ id: 'KA-4', text: 'Georgian (Advanced)' },
	{ id: 'KG-4', text: 'Kongo (Advanced)' },
	{ id: 'KI-4', text: 'Kikuyu (Advanced)' },
	{ id: 'KJ-4', text: 'Kuanyama (Advanced)' },
	{ id: 'KK-4', text: 'Kazakh (Advanced)' },
	{ id: 'KL-4', text: 'Greenlandic (Advanced)' },
	{ id: 'KM-4', text: 'Cambodian (Advanced)' },
	{ id: 'KN-4', text: 'Kannada (Advanced)' },
	{ id: 'KO-4', text: 'Korean (Advanced)' },
	{ id: 'KR-4', text: 'Kanuri (Advanced)' },
	{ id: 'KS-4', text: 'Kashmiri (Advanced)' },
	{ id: 'KU-4', text: 'Kurdish (Advanced)' },
	{ id: 'KV-4', text: 'Komi (Advanced)' },
	{ id: 'KW-4', text: 'Cornish (Advanced)' },
	{ id: 'KY-4', text: 'Kirghiz (Advanced)' },
	{ id: 'LA-4', text: 'Latin (Advanced)' },
	{ id: 'LB-4', text: 'Luxembourgish (Advanced)' },
	{ id: 'LG-4', text: 'Ganda (Advanced)' },
	{ id: 'LI-4', text: 'Limburgan (Advanced)' },
	{ id: 'LN-4', text: 'Lingala (Advanced)' },
	{ id: 'LO-4', text: 'Lao (Advanced)' },
	{ id: 'LT-4', text: 'Lithuanian (Advanced)' },
	{ id: 'LU-4', text: 'Luba-Katanga (Advanced)' },
	{ id: 'LV-4', text: 'Latvian (Advanced)' },
	{ id: 'ME-4', text: 'Montenegrin (Advanced)' },
	{ id: 'MG-4', text: 'Malagasy (Advanced)' },
	{ id: 'MH-4', text: 'Marshallese (Advanced)' },
	{ id: 'MI-4', text: 'Maori (Advanced)' },
	{ id: 'MK-4', text: 'Macedonian (Advanced)' },
	{ id: 'ML-4', text: 'Malayalam (Advanced)' },
	{ id: 'MN-4', text: 'Mongolian (Advanced)' },
	{ id: 'MR-4', text: 'Marathi (Advanced)' },
	{ id: 'MS-4', text: 'Malaysian (Advanced)' },
	{ id: 'MT-4', text: 'Maltese (Advanced)' },
	{ id: 'MY-4', text: 'Myanmarese (Advanced)' },
	{ id: 'ND-4', text: 'North Ndebele (Advanced)' },
	{ id: 'NE-4', text: 'Nepali (Advanced)' },
	{ id: 'NG-4', text: 'Ndonga (Advanced)' },
	{ id: 'NL-4', text: 'Dutch (Advanced)' },
	{ id: 'NO-4', text: 'Norwegian (Advanced)' },
	{ id: 'NR-4', text: 'South Ndebele (Advanced)' },
	{ id: 'NV-4', text: 'Navajo (Advanced)' },
	{ id: 'NY-4', text: 'Nyanja (Advanced)' },
	{ id: 'OC-4', text: 'Occitan (Advanced)' },
	{ id: 'OJ-4', text: 'Ojibwa (Advanced)' },
	{ id: 'OM-4', text: 'Oromo (Advanced)' },
	{ id: 'OR-4', text: 'Oriya (Advanced)' },
	{ id: 'OS-4', text: 'Ossetic (Advanced)' },
	{ id: 'PA-4', text: 'Punjabi (Advanced)' },
	{ id: 'PA-4', text: 'Papiamento (Advanced)' },
	{ id: 'PL-4', text: 'Polish (Advanced)' },
	{ id: 'PS-4', text: 'Pushto (Advanced)' },
	{ id: 'PT-4', text: 'Portuguese (Advanced)' },
	{ id: 'QU-4', text: 'Quechua (Advanced)' },
	{ id: 'RM-4', text: 'Romansh (Advanced)' },
	{ id: 'RN-4', text: 'Rundi (Advanced)' },
	{ id: 'RO-4', text: 'Romanian (Advanced)' },
	{ id: 'RU-4', text: 'Russian (Advanced)' },
	{ id: 'RW-4', text: 'Kinyarwanda (Advanced)' },
	{ id: 'SA-4', text: 'Sanskrit (Advanced)' },
	{ id: 'SC-4', text: 'Sardinian (Advanced)' },
	{ id: 'SD-4', text: 'Sindhi (Advanced)' },
	{ id: 'SE-4', text: 'Northern Sami (Advanced)' },
	{ id: 'SG-4', text: 'Sango (Advanced)' },
	{ id: 'SI-4', text: 'Sinhala (Advanced)' },
	{ id: 'SK-4', text: 'Slovak (Advanced)' },
	{ id: 'SL-4', text: 'Slovenian (Advanced)' },
	{ id: 'SM-4', text: 'Samoan (Advanced)' },
	{ id: 'SN-4', text: 'Shona (Advanced)' },
	{ id: 'SO-4', text: 'Somali (Advanced)' },
	{ id: 'SQ-4', text: 'Albanian (Advanced)' },
	{ id: 'SR-4', text: 'Serbian (Advanced)' },
	{ id: 'SS-4', text: 'Swati (Advanced)' },
	{ id: 'ST-4', text: 'Southern Sotho (Advanced)' },
	{ id: 'SV-4', text: 'Swedish (Advanced)' },
	{ id: 'SW-4', text: 'Swahili (Advanced)' },
	{ id: 'TA-4', text: 'Tamil (Advanced)' },
	{ id: 'TE-4', text: 'Telugu (Advanced)' },
	{ id: 'TG-4', text: 'Tajik (Advanced)' },
	{ id: 'TH-4', text: 'Thai (Advanced)' },
	{ id: 'TI-4', text: 'Tigrinya (Advanced)' },
	{ id: 'TK-4', text: 'Turkmen (Advanced)' },
	{ id: 'TL-4', text: 'Filipino (Advanced)' },
	{ id: 'TN-4', text: 'Tswana (Advanced)' },
	{ id: 'TO-4', text: 'Tonga (Advanced)' },
	{ id: 'TR-4', text: 'Turkish (Advanced)' },
	{ id: 'TS-4', text: 'Tsonga (Advanced)' },
	{ id: 'TW-4', text: 'Twi (Advanced)' },
	{ id: 'TY-4', text: 'Tahitian (Advanced)' },
	{ id: 'UG-4', text: 'Uyghur (Advanced)' },
	{ id: 'UK-4', text: 'Ukrainian (Advanced)' },
	{ id: 'UR-4', text: 'Urdu (Advanced)' },
	{ id: 'UZ-4', text: 'Uzbek (Advanced)' },
	{ id: 'VE-4', text: 'Venda (Advanced)' },
	{ id: 'VI-4', text: 'Vietnamese (Advanced)' },
	{ id: 'WO-4', text: 'Wolof (Advanced)' },
	{ id: 'XH-4', text: 'Xhosa (Advanced)' },
	{ id: 'YI-4', text: 'Yiddish (Advanced)' },
	{ id: 'YO-4', text: 'Yoruba (Advanced)' },
	{ id: 'ZA-4', text: 'Zhuang (Advanced)' },
	{ id: 'ZH-4', text: 'Chinese (Advanced)' },
	{ id: 'ZU-4', text: 'Zulu (Advanced)' },
	{ id: 'AA-5', text: 'Afar (Near Native)' },
	{ id: 'AB-5', text: 'Abkhazian (Near Native)' },
	{ id: 'AE-5', text: 'Avestan (Near Native)' },
	{ id: 'AF-5', text: 'Afrikaans (Near Native)' },
	{ id: 'AK-5', text: 'Akan (Near Native)' },
	{ id: 'AM-5', text: 'Amharic (Near Native)' },
	{ id: 'AN-5', text: 'Aragonese (Near Native)' },
	{ id: 'AR-5', text: 'Arabic (Near Native)' },
	{ id: 'AS-5', text: 'Assamese (Near Native)' },
	{ id: 'AV-5', text: 'Avaric (Near Native)' },
	{ id: 'AY-5', text: 'Aymara (Near Native)' },
	{ id: 'AZ-5', text: 'Azeri (Near Native)' },
	{ id: 'BA-5', text: 'Bashkir (Near Native)' },
	{ id: 'BE-5', text: 'Belarusian (Near Native)' },
	{ id: 'BG-5', text: 'Bulgarian (Near Native)' },
	{ id: 'BH-5', text: 'Bihari (Near Native)' },
	{ id: 'BI-5', text: 'Bislama (Near Native)' },
	{ id: 'BM-5', text: 'Bambara (Near Native)' },
	{ id: 'BN-5', text: 'Bangladeshi (Near Native)' },
	{ id: 'BO-5', text: 'Tibetan (Near Native)' },
	{ id: 'BR-5', text: 'Bretón (Near Native)' },
	{ id: 'BS-5', text: 'Bosnian (Near Native)' },
	{ id: 'CA-5', text: 'Catalan (Near Native)' },
	{ id: 'CE-5', text: 'Chechen (Near Native)' },
	{ id: 'CH-5', text: 'Chamorro (Near Native)' },
	{ id: 'CO-5', text: 'Corsican (Near Native)' },
	{ id: 'CR-5', text: 'Cree (Near Native)' },
	{ id: 'CS-5', text: 'Czech (Near Native)' },
	{ id: 'CV-5', text: 'Chuvash (Near Native)' },
	{ id: 'CY-5', text: 'Welsh (Near Native)' },
	{ id: 'DA-5', text: 'Danish (Near Native)' },
	{ id: 'DE-5', text: 'German (Near Native)' },
	{ id: 'DV-5', text: 'Divehi (Near Native)' },
	{ id: 'DZ-5', text: 'Bhutanese (Near Native)' },
	{ id: 'EE-5', text: 'Ewe (Near Native)' },
	{ id: 'EL-5', text: 'Greek (Near Native)' },
	{ id: 'EN-5', text: 'English (Near Native)' },
	{ id: 'EO-5', text: 'Esperanto (Near Native)' },
	{ id: 'ES-5', text: 'Spanish (Near Native)' },
	{ id: 'ET-5', text: 'Estonian (Near Native)' },
	{ id: 'EU-5', text: 'Basque (Near Native)' },
	{ id: 'FA-5', text: 'Persian (Near Native)' },
	{ id: 'FF-5', text: 'Fulah (Near Native)' },
	{ id: 'FI-5', text: 'Finnish (Near Native)' },
	{ id: 'FJ-5', text: 'Fijian (Near Native)' },
	{ id: 'FO-5', text: 'Faroese (Near Native)' },
	{ id: 'FR-5', text: 'French (Near Native)' },
	{ id: 'FY-5', text: 'West Frisian (Near Native)' },
	{ id: 'GA-5', text: 'Irish (Gaelic) (Near Native)' },
	{ id: 'GD-5', text: 'Scottish Gaelic (Near Native)' },
	{ id: 'GL-5', text: 'Galician (Near Native)' },
	{ id: 'GN-5', text: 'Guarani (Near Native)' },
	{ id: 'GU-5', text: 'Gujarati (Near Native)' },
	{ id: 'GV-5', text: 'Manx (Near Native)' },
	{ id: 'HA-5', text: 'Hausa (Near Native)' },
	{ id: 'HE-5', text: 'Hebrew (Near Native)' },
	{ id: 'HI-5', text: 'Hindi (Near Native)' },
	{ id: 'HR-5', text: 'Croatian (Near Native)' },
	{ id: 'HT-5', text: 'Haitian (Near Native)' },
	{ id: 'HU-5', text: 'Hungarian (Near Native)' },
	{ id: 'HY-5', text: 'Armenian (Near Native)' },
	{ id: 'HZ-5', text: 'Herero (Near Native)' },
	{ id: 'IA-5', text: 'Interlingua (Near Native)' },
	{ id: 'ID-5', text: 'Indonesian (Near Native)' },
	{ id: 'IG-5', text: 'Igbo (Near Native)' },
	{ id: 'II-5', text: 'Sichuan Yi (Near Native)' },
	{ id: 'IK-5', text: 'Inupiaq (Near Native)' },
	{ id: 'IS-5', text: 'Icelandic (Near Native)' },
	{ id: 'IT-5', text: 'Italian (Near Native)' },
	{ id: 'IU-5', text: 'Inuktitut (Near Native)' },
	{ id: 'JA-5', text: 'Japanese (Near Native)' },
	{ id: 'JV-5', text: 'Javanese (Near Native)' },
	{ id: 'KA-5', text: 'Georgian (Near Native)' },
	{ id: 'KG-5', text: 'Kongo (Near Native)' },
	{ id: 'KI-5', text: 'Kikuyu (Near Native)' },
	{ id: 'KJ-5', text: 'Kuanyama (Near Native)' },
	{ id: 'KK-5', text: 'Kazakh (Near Native)' },
	{ id: 'KL-5', text: 'Greenlandic (Near Native)' },
	{ id: 'KM-5', text: 'Cambodian (Near Native)' },
	{ id: 'KN-5', text: 'Kannada (Near Native)' },
	{ id: 'KO-5', text: 'Korean (Near Native)' },
	{ id: 'KR-5', text: 'Kanuri (Near Native)' },
	{ id: 'KS-5', text: 'Kashmiri (Near Native)' },
	{ id: 'KU-5', text: 'Kurdish (Near Native)' },
	{ id: 'KV-5', text: 'Komi (Near Native)' },
	{ id: 'KW-5', text: 'Cornish (Near Native)' },
	{ id: 'KY-5', text: 'Kirghiz (Near Native)' },
	{ id: 'LA-5', text: 'Latin (Near Native)' },
	{ id: 'LB-5', text: 'Luxembourgish (Near Native)' },
	{ id: 'LG-5', text: 'Ganda (Near Native)' },
	{ id: 'LI-5', text: 'Limburgan (Near Native)' },
	{ id: 'LN-5', text: 'Lingala (Near Native)' },
	{ id: 'LO-5', text: 'Lao (Near Native)' },
	{ id: 'LT-5', text: 'Lithuanian (Near Native)' },
	{ id: 'LU-5', text: 'Luba-Katanga (Near Native)' },
	{ id: 'LV-5', text: 'Latvian (Near Native)' },
	{ id: 'ME-5', text: 'Montenegrin (Near Native)' },
	{ id: 'MG-5', text: 'Malagasy (Near Native)' },
	{ id: 'MH-5', text: 'Marshallese (Near Native)' },
	{ id: 'MI-5', text: 'Maori (Near Native)' },
	{ id: 'MK-5', text: 'Macedonian (Near Native)' },
	{ id: 'ML-5', text: 'Malayalam (Near Native)' },
	{ id: 'MN-5', text: 'Mongolian (Near Native)' },
	{ id: 'MR-5', text: 'Marathi (Near Native)' },
	{ id: 'MS-5', text: 'Malaysian (Near Native)' },
	{ id: 'MT-5', text: 'Maltese (Near Native)' },
	{ id: 'MY-5', text: 'Myanmarese (Near Native)' },
	{ id: 'ND-5', text: 'North Ndebele (Near Native)' },
	{ id: 'NE-5', text: 'Nepali (Near Native)' },
	{ id: 'NG-5', text: 'Ndonga (Near Native)' },
	{ id: 'NL-5', text: 'Dutch (Near Native)' },
	{ id: 'NO-5', text: 'Norwegian (Near Native)' },
	{ id: 'NR-5', text: 'South Ndebele (Near Native)' },
	{ id: 'NV-5', text: 'Navajo (Near Native)' },
	{ id: 'NY-5', text: 'Nyanja (Near Native)' },
	{ id: 'OC-5', text: 'Occitan (Near Native)' },
	{ id: 'OJ-5', text: 'Ojibwa (Near Native)' },
	{ id: 'OM-5', text: 'Oromo (Near Native)' },
	{ id: 'OR-5', text: 'Oriya (Near Native)' },
	{ id: 'OS-5', text: 'Ossetic (Near Native)' },
	{ id: 'PA-5', text: 'Punjabi (Near Native)' },
	{ id: 'PA-5', text: 'Papiamento (Near Native)' },
	{ id: 'PL-5', text: 'Polish (Near Native)' },
	{ id: 'PS-5', text: 'Pushto (Near Native)' },
	{ id: 'PT-5', text: 'Portuguese (Near Native)' },
	{ id: 'QU-5', text: 'Quechua (Near Native)' },
	{ id: 'RM-5', text: 'Romansh (Near Native)' },
	{ id: 'RN-5', text: 'Rundi (Near Native)' },
	{ id: 'RO-5', text: 'Romanian (Near Native)' },
	{ id: 'RU-5', text: 'Russian (Near Native)' },
	{ id: 'RW-5', text: 'Kinyarwanda (Near Native)' },
	{ id: 'SA-5', text: 'Sanskrit (Near Native)' },
	{ id: 'SC-5', text: 'Sardinian (Near Native)' },
	{ id: 'SD-5', text: 'Sindhi (Near Native)' },
	{ id: 'SE-5', text: 'Northern Sami (Near Native)' },
	{ id: 'SG-5', text: 'Sango (Near Native)' },
	{ id: 'SI-5', text: 'Sinhala (Near Native)' },
	{ id: 'SK-5', text: 'Slovak (Near Native)' },
	{ id: 'SL-5', text: 'Slovenian (Near Native)' },
	{ id: 'SM-5', text: 'Samoan (Near Native)' },
	{ id: 'SN-5', text: 'Shona (Near Native)' },
	{ id: 'SO-5', text: 'Somali (Near Native)' },
	{ id: 'SQ-5', text: 'Albanian (Near Native)' },
	{ id: 'SR-5', text: 'Serbian (Near Native)' },
	{ id: 'SS-5', text: 'Swati (Near Native)' },
	{ id: 'ST-5', text: 'Southern Sotho (Near Native)' },
	{ id: 'SV-5', text: 'Swedish (Near Native)' },
	{ id: 'SW-5', text: 'Swahili (Near Native)' },
	{ id: 'TA-5', text: 'Tamil (Near Native)' },
	{ id: 'TE-5', text: 'Telugu (Near Native)' },
	{ id: 'TG-5', text: 'Tajik (Near Native)' },
	{ id: 'TH-5', text: 'Thai (Near Native)' },
	{ id: 'TI-5', text: 'Tigrinya (Near Native)' },
	{ id: 'TK-5', text: 'Turkmen (Near Native)' },
	{ id: 'TL-5', text: 'Filipino (Near Native)' },
	{ id: 'TN-5', text: 'Tswana (Near Native)' },
	{ id: 'TO-5', text: 'Tonga (Near Native)' },
	{ id: 'TR-5', text: 'Turkish (Near Native)' },
	{ id: 'TS-5', text: 'Tsonga (Near Native)' },
	{ id: 'TW-5', text: 'Twi (Near Native)' },
	{ id: 'TY-5', text: 'Tahitian (Near Native)' },
	{ id: 'UG-5', text: 'Uyghur (Near Native)' },
	{ id: 'UK-5', text: 'Ukrainian (Near Native)' },
	{ id: 'UR-5', text: 'Urdu (Near Native)' },
	{ id: 'UZ-5', text: 'Uzbek (Near Native)' },
	{ id: 'VE-5', text: 'Venda (Near Native)' },
	{ id: 'VI-5', text: 'Vietnamese (Near Native)' },
	{ id: 'WO-5', text: 'Wolof (Near Native)' },
	{ id: 'XH-5', text: 'Xhosa (Near Native)' },
	{ id: 'YI-5', text: 'Yiddish (Near Native)' },
	{ id: 'YO-5', text: 'Yoruba (Near Native)' },
	{ id: 'ZA-5', text: 'Zhuang (Near Native)' },
	{ id: 'ZH-5', text: 'Chinese (Near Native)' },
	{ id: 'ZU-5', text: 'Zulu (Near Native)' },
	{ id: 'AA-6', text: 'Afar (Native)' },
	{ id: 'AB-6', text: 'Abkhazian (Native)' },
	{ id: 'AE-6', text: 'Avestan (Native)' },
	{ id: 'AF-6', text: 'Afrikaans (Native)' },
	{ id: 'AK-6', text: 'Akan (Native)' },
	{ id: 'AM-6', text: 'Amharic (Native)' },
	{ id: 'AN-6', text: 'Aragonese (Native)' },
	{ id: 'AR-6', text: 'Arabic (Native)' },
	{ id: 'AS-6', text: 'Assamese (Native)' },
	{ id: 'AV-6', text: 'Avaric (Native)' },
	{ id: 'AY-6', text: 'Aymara (Native)' },
	{ id: 'AZ-6', text: 'Azeri (Native)' },
	{ id: 'BA-6', text: 'Bashkir (Native)' },
	{ id: 'BE-6', text: 'Belarusian (Native)' },
	{ id: 'BG-6', text: 'Bulgarian (Native)' },
	{ id: 'BH-6', text: 'Bihari (Native)' },
	{ id: 'BI-6', text: 'Bislama (Native)' },
	{ id: 'BM-6', text: 'Bambara (Native)' },
	{ id: 'BN-6', text: 'Bangladeshi (Native)' },
	{ id: 'BO-6', text: 'Tibetan (Native)' },
	{ id: 'BR-6', text: 'Bretón (Native)' },
	{ id: 'BS-6', text: 'Bosnian (Native)' },
	{ id: 'CA-6', text: 'Catalan (Native)' },
	{ id: 'CE-6', text: 'Chechen (Native)' },
	{ id: 'CH-6', text: 'Chamorro (Native)' },
	{ id: 'CO-6', text: 'Corsican (Native)' },
	{ id: 'CR-6', text: 'Cree (Native)' },
	{ id: 'CS-6', text: 'Czech (Native)' },
	{ id: 'CV-6', text: 'Chuvash (Native)' },
	{ id: 'CY-6', text: 'Welsh (Native)' },
	{ id: 'DA-6', text: 'Danish (Native)' },
	{ id: 'DE-6', text: 'German (Native)' },
	{ id: 'DV-6', text: 'Divehi (Native)' },
	{ id: 'DZ-6', text: 'Bhutanese (Native)' },
	{ id: 'EE-6', text: 'Ewe (Native)' },
	{ id: 'EL-6', text: 'Greek (Native)' },
	{ id: 'EN-6', text: 'English (Native)' },
	{ id: 'EO-6', text: 'Esperanto (Native)' },
	{ id: 'ES-6', text: 'Spanish (Native)' },
	{ id: 'ET-6', text: 'Estonian (Native)' },
	{ id: 'EU-6', text: 'Basque (Native)' },
	{ id: 'FA-6', text: 'Persian (Native)' },
	{ id: 'FF-6', text: 'Fulah (Native)' },
	{ id: 'FI-6', text: 'Finnish (Native)' },
	{ id: 'FJ-6', text: 'Fijian (Native)' },
	{ id: 'FO-6', text: 'Faroese (Native)' },
	{ id: 'FR-6', text: 'French (Native)' },
	{ id: 'FY-6', text: 'West Frisian (Native)' },
	{ id: 'GA-6', text: 'Irish (Gaelic) (Native)' },
	{ id: 'GD-6', text: 'Scottish Gaelic (Native)' },
	{ id: 'GL-6', text: 'Galician (Native)' },
	{ id: 'GN-6', text: 'Guarani (Native)' },
	{ id: 'GU-6', text: 'Gujarati (Native)' },
	{ id: 'GV-6', text: 'Manx (Native)' },
	{ id: 'HA-6', text: 'Hausa (Native)' },
	{ id: 'HE-6', text: 'Hebrew (Native)' },
	{ id: 'HI-6', text: 'Hindi (Native)' },
	{ id: 'HR-6', text: 'Croatian (Native)' },
	{ id: 'HT-6', text: 'Haitian (Native)' },
	{ id: 'HU-6', text: 'Hungarian (Native)' },
	{ id: 'HY-6', text: 'Armenian (Native)' },
	{ id: 'HZ-6', text: 'Herero (Native)' },
	{ id: 'IA-6', text: 'Interlingua (Native)' },
	{ id: 'ID-6', text: 'Indonesian (Native)' },
	{ id: 'IG-6', text: 'Igbo (Native)' },
	{ id: 'II-6', text: 'Sichuan Yi (Native)' },
	{ id: 'IK-6', text: 'Inupiaq (Native)' },
	{ id: 'IS-6', text: 'Icelandic (Native)' },
	{ id: 'IT-6', text: 'Italian (Native)' },
	{ id: 'IU-6', text: 'Inuktitut (Native)' },
	{ id: 'JA-6', text: 'Japanese (Native)' },
	{ id: 'JV-6', text: 'Javanese (Native)' },
	{ id: 'KA-6', text: 'Georgian (Native)' },
	{ id: 'KG-6', text: 'Kongo (Native)' },
	{ id: 'KI-6', text: 'Kikuyu (Native)' },
	{ id: 'KJ-6', text: 'Kuanyama (Native)' },
	{ id: 'KK-6', text: 'Kazakh (Native)' },
	{ id: 'KL-6', text: 'Greenlandic (Native)' },
	{ id: 'KM-6', text: 'Cambodian (Native)' },
	{ id: 'KN-6', text: 'Kannada (Native)' },
	{ id: 'KO-6', text: 'Korean (Native)' },
	{ id: 'KR-6', text: 'Kanuri (Native)' },
	{ id: 'KS-6', text: 'Kashmiri (Native)' },
	{ id: 'KU-6', text: 'Kurdish (Native)' },
	{ id: 'KV-6', text: 'Komi (Native)' },
	{ id: 'KW-6', text: 'Cornish (Native)' },
	{ id: 'KY-6', text: 'Kirghiz (Native)' },
	{ id: 'LA-6', text: 'Latin (Native)' },
	{ id: 'LB-6', text: 'Luxembourgish (Native)' },
	{ id: 'LG-6', text: 'Ganda (Native)' },
	{ id: 'LI-6', text: 'Limburgan (Native)' },
	{ id: 'LN-6', text: 'Lingala (Native)' },
	{ id: 'LO-6', text: 'Lao (Native)' },
	{ id: 'LT-6', text: 'Lithuanian (Native)' },
	{ id: 'LU-6', text: 'Luba-Katanga (Native)' },
	{ id: 'LV-6', text: 'Latvian (Native)' },
	{ id: 'ME-6', text: 'Montenegrin (Native)' },
	{ id: 'MG-6', text: 'Malagasy (Native)' },
	{ id: 'MH-6', text: 'Marshallese (Native)' },
	{ id: 'MI-6', text: 'Maori (Native)' },
	{ id: 'MK-6', text: 'Macedonian (Native)' },
	{ id: 'ML-6', text: 'Malayalam (Native)' },
	{ id: 'MN-6', text: 'Mongolian (Native)' },
	{ id: 'MR-6', text: 'Marathi (Native)' },
	{ id: 'MS-6', text: 'Malaysian (Native)' },
	{ id: 'MT-6', text: 'Maltese (Native)' },
	{ id: 'MY-6', text: 'Myanmarese (Native)' },
	{ id: 'ND-6', text: 'North Ndebele (Native)' },
	{ id: 'NE-6', text: 'Nepali (Native)' },
	{ id: 'NG-6', text: 'Ndonga (Native)' },
	{ id: 'NL-6', text: 'Dutch (Native)' },
	{ id: 'NO-6', text: 'Norwegian (Native)' },
	{ id: 'NR-6', text: 'South Ndebele (Native)' },
	{ id: 'NV-6', text: 'Navajo (Native)' },
	{ id: 'NY-6', text: 'Nyanja (Native)' },
	{ id: 'OC-6', text: 'Occitan (Native)' },
	{ id: 'OJ-6', text: 'Ojibwa (Native)' },
	{ id: 'OM-6', text: 'Oromo (Native)' },
	{ id: 'OR-6', text: 'Oriya (Native)' },
	{ id: 'OS-6', text: 'Ossetic (Native)' },
	{ id: 'PA-6', text: 'Punjabi (Native)' },
	{ id: 'PA-6', text: 'Papiamento (Native)' },
	{ id: 'PL-6', text: 'Polish (Native)' },
	{ id: 'PS-6', text: 'Pushto (Native)' },
	{ id: 'PT-6', text: 'Portuguese (Native)' },
	{ id: 'QU-6', text: 'Quechua (Native)' },
	{ id: 'RM-6', text: 'Romansh (Native)' },
	{ id: 'RN-6', text: 'Rundi (Native)' },
	{ id: 'RO-6', text: 'Romanian (Native)' },
	{ id: 'RU-6', text: 'Russian (Native)' },
	{ id: 'RW-6', text: 'Kinyarwanda (Native)' },
	{ id: 'SA-6', text: 'Sanskrit (Native)' },
	{ id: 'SC-6', text: 'Sardinian (Native)' },
	{ id: 'SD-6', text: 'Sindhi (Native)' },
	{ id: 'SE-6', text: 'Northern Sami (Native)' },
	{ id: 'SG-6', text: 'Sango (Native)' },
	{ id: 'SI-6', text: 'Sinhala (Native)' },
	{ id: 'SK-6', text: 'Slovak (Native)' },
	{ id: 'SL-6', text: 'Slovenian (Native)' },
	{ id: 'SM-6', text: 'Samoan (Native)' },
	{ id: 'SN-6', text: 'Shona (Native)' },
	{ id: 'SO-6', text: 'Somali (Native)' },
	{ id: 'SQ-6', text: 'Albanian (Native)' },
	{ id: 'SR-6', text: 'Serbian (Native)' },
	{ id: 'SS-6', text: 'Swati (Native)' },
	{ id: 'ST-6', text: 'Southern Sotho (Native)' },
	{ id: 'SV-6', text: 'Swedish (Native)' },
	{ id: 'SW-6', text: 'Swahili (Native)' },
	{ id: 'TA-6', text: 'Tamil (Native)' },
	{ id: 'TE-6', text: 'Telugu (Native)' },
	{ id: 'TG-6', text: 'Tajik (Native)' },
	{ id: 'TH-6', text: 'Thai (Native)' },
	{ id: 'TI-6', text: 'Tigrinya (Native)' },
	{ id: 'TK-6', text: 'Turkmen (Native)' },
	{ id: 'TL-6', text: 'Filipino (Native)' },
	{ id: 'TN-6', text: 'Tswana (Native)' },
	{ id: 'TO-6', text: 'Tonga (Native)' },
	{ id: 'TR-6', text: 'Turkish (Native)' },
	{ id: 'TS-6', text: 'Tsonga (Native)' },
	{ id: 'TW-6', text: 'Twi (Native)' },
	{ id: 'TY-6', text: 'Tahitian (Native)' },
	{ id: 'UG-6', text: 'Uyghur (Native)' },
	{ id: 'UK-6', text: 'Ukrainian (Native)' },
	{ id: 'UR-6', text: 'Urdu (Native)' },
	{ id: 'UZ-6', text: 'Uzbek (Native)' },
	{ id: 'VE-6', text: 'Venda (Native)' },
	{ id: 'VI-6', text: 'Vietnamese (Native)' },
	{ id: 'WO-6', text: 'Wolof (Native)' },
	{ id: 'XH-6', text: 'Xhosa (Native)' },
	{ id: 'YI-6', text: 'Yiddish (Native)' },
	{ id: 'YO-6', text: 'Yoruba (Native)' },
	{ id: 'ZA-6', text: 'Zhuang (Native)' },
	{ id: 'ZH-6', text: 'Chinese (Native)' },
	{ id: 'ZU-6', text: 'Zulu (Native)' },
]

module.exports = languageSuggestions;
import gql from 'graphql-tag';

export default gql`
  mutation AddMessage($message: String!, $chatId: ID!, $SortKey: ID, $data: AWSJSON, $username: String!, $theme: String!) {
   addMessage(chatId: $chatId, SortKey: $SortKey, username: $username, message: $message, theme: $theme, data: $data) {
      __typename
      chatId
      SortKey
      message
      theme
      created_dt
      data
      username
    }
  }
`
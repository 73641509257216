import * as React from "react"
import styled from 'styled-components'
import Button from './Button'

const ConfirmCont = styled.div.attrs(({ isOpen }) => ({
  height: isOpen ? "180px" : "0",
  bottom: isOpen ? "0" : "-200px"
}))`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: ${props => props.height};
  bottom: ${props => props.bottom};
  left: 0
  overflow: scroll;
  background-color: transparent;
  transition: bottom 0.3s ease, height 0.3s ease;
`
const Card = styled.div`
  position: relative;
  max-width: 300px;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 15px;
  z-index: 999;
  border: solid 1px #DDD;
  border-radius: 9px;
  background-color: #FFF;
  
  & > p {
    padding: 15px 40px;
    text-align: center;
    font-size: 14px;
    color: #0f2d49;
  }

`

const Overlay = styled.div.attrs(({ isOpen }) => ({
  display: isOpen ? "block" : "none",
  background: isOpen ? "rgba(15,45,73,.15)" : "rgba(15,45,73,0)",
}))`
  display: ${props => props.display};
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  transition: all 0.3s ease;
`

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 47% auto;
  grid-column-gap: 15px;
`

export default class Confirm extends React.Component {
  state = {
    isOpen: false,
    callback: null
  }

  show = callback => event => {
    event.preventDefault()

    event = {
      ...event,
      target: { ...event.target, value: event.target.value }
    }

    this.setState({
      isOpen: true,
      callback: () => callback(event)
    })
  }

  hide = () => this.setState({ isOpen: false, callback: null })

  confirm = () => {
    this.state.callback()
    this.hide()
  }

  render() {
    const isOpen = this.state.isOpen
    const title = this.props.title
    return (
      <React.Fragment>
        {this.props.children(this.show)}
        {isOpen && (
          <React.Fragment>
            <Overlay isOpen={isOpen} />
            <ConfirmCont isOpen={isOpen}>
              <Card>
                { title ? <h1>{title}</h1> : null }
                <p>{this.props.description}</p>
                <Buttons>
                  <Button color="#2ba9e3" isFilled={true} onClick={this.confirm}>Yes</Button>
                  <Button color="#2ba9e3" onClick={this.hide}>Cancel</Button>
                </Buttons>
              </Card>
            </ConfirmCont>
            </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

import gql from 'graphql-tag';

export default gql`
  mutation AddProfile($poolId: ID!, $profileId: ID!, $email: AWSEmail, $name: String, $recruiter_comments: String, $current_job_title: String, $notice_period: String, $job_type: String, $workhistories: [WorkHistoryInput], $educations: [EducationInput], $skills: [SkillInput], $hobbies: [HobbyInput]) {
    addProfile(
      profileId: $profileId,
      poolId: $poolId, 
      email: $email,
      name: $name, 
      db_candidate_id: "999", 
      recruiter_comments: $recruiter_comments,
      current_job_title: $current_job_title,
      notice_period: $notice_period,
      job_type: $job_type,
      workhistories: $workhistories,
      educations: $educations,
      skills: $skills,
      hobbies: $hobbies
    ) {
      poolId
      profileId
      email
      name
      recruiter_comments
      current_job_title
      notice_period
      stageId
      job_type
      workhistories {
        work_title,
        company_name,
        comments,
        start_dt,
        end_dt
      }
      educations {
        institute_name,
        institute_place,
        description,
        level,
        level_description,
        start_dt,
        end_dt
      }
      skills {
      	name,
      	skill_type
      }
      hobbies {
      	name
      }
    }
  }
`
import React, { Component } from "react";
import "./App.css";
import "./normalize.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as localForage from "localforage";
// For IE11
import "isomorphic-unfetch";

// AppSync
import awsmobile from "./aws-exports";
import AWSAppSyncClient from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import { AUTH_TYPE } from "aws-appsync/lib/link/auth-link";
// Apollo
import { ApolloProvider } from "react-apollo";
import { Query, Mutation } from "react-apollo";
import GET_IDENTITY from "./Components/Queries/GET_IDENTITY";
import UPDATE_IDENTITY_LAST_ACTIVE from "./Components/Queries/UPDATE_IDENTITY_LAST_ACTIVE";
// Amplify
import Amplify, { Auth, Storage } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
// Layouts
import HomePage from "./hoc/layout/HomePage";
import AccountPage from "./hoc/layout/AccountPage";
import PoolPage from "./hoc/layout/PoolPage";
import ProfilePage from "./hoc/layout/ProfilePage";
import AddProfilePage from "./hoc/layout/AddProfilePage";
// Loading gif
import LoadingIcon from "./assets/images/loading.gif";

// Configuring Amplify authentication
Amplify.configure(awsmobile);
Amplify.configure({
  Auth: {
    identityPoolId: "eu-west-1:41986f7e-48da-4947-aafa-2bcb3620a770",
    region: "eu-west-1",
    userPoolId: "eu-west-1_Qw5l3sSLq",
    mandatorySignIn: true,
    userPoolWebClientId: "58rge683tn0di4odf2smfg74gs"
  }
});

Storage.configure({
  AWSS3: {
    bucket: "mmk-galaxy-nova-users-dev",
    region: "eu-west-1"
  }
});

// Amplify theme overrides for authentication screens
const MyTheme = {
  container: { borderTop: "solid 54px #FF475F" },
  signInButtonIcon: { display: "none" },
  button: { backgroundColor: "#FF475F", borderRadius: "22px" },
  input: {
    padding: "10px 15px",
    background: "#FFFFFF",
    border: "1px solid #7FCBEE",
    boxShadow: "1px 1px 4px 0 #E7EAED",
    borderRadius: "27px",
    fontSize: "14px",
    color: "#6F8191"
  },
  a: { color: "#CC384C" },
  inputLabel: { margin: "0 0 5px 15px", color: "#0F2D49" },
  hint: { margin: "10px 0 5px 15px", fontSize: "14px", color: "#0F2D49" }
};

// AppSync client instantiation
const client = new AWSAppSyncClient({
  disableOffline: false,
  url: awsmobile.aws_appsync_graphqlEndpoint,
  region: awsmobile.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
  offlineConfig: {
    storage: localForage
  },
  onError: e => {
    console.log(e);
  }
});

class UpdateLastActive extends Component {
  componentDidMount() {
    const last_active_dt = Date.now();

    setTimeout(
      function() {
        this.props.updateIdentityLastActive({
          variables: {
            identityId: this.props.identityId,
            last_active_dt
          },
          ignoreResults: true,
          onError: () => {
            console.error("last update error");
          }
        });
      }.bind(this),
      2000
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    return null;
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/pool/:poolId/:messageCreated?"
            component={props => (
              <Query query={GET_IDENTITY} fetchPolicy="cache-and-network">
                {({ loading, error, data, refetch, subscribeToMore }) => {
                  if (error) return `Error! ${error.message}`;
                  if (data.getIdentity)
                    return (
                      <React.Fragment>
                        <Mutation mutation={UPDATE_IDENTITY_LAST_ACTIVE}>
                          {updateIdentityLastActive => (
                            <UpdateLastActive
                              updateIdentityLastActive={
                                updateIdentityLastActive
                              }
                              identityId={data.getIdentity.username}
                            />
                          )}
                        </Mutation>
                        <PoolPage
                          match={props.match}
                          identity={data.getIdentity}
                        />
                      </React.Fragment>
                    );
                  if (loading)
                    return (
                      <div className="app-loading">
                        <img
                          src={LoadingIcon}
                          alt="Please wait while the applications loads."
                        />
                      </div>
                    );
                }}
              </Query>
            )}
          />
          <Route
            exact
            path="/pool/:poolId/profile-add"
            component={AddProfilePage}
          />
          <Route exact path="/account" component={AccountPage} />
          <Route
            exact
            path="/pool/:poolId/profile/:profileId"
            component={ProfilePage}
          />
          <Route
            exact
            path="/"
            component={props => (
              <Query query={GET_IDENTITY} fetchPolicy="cache-and-network">
                {({ loading, error, data, refetch, subscribeToMore }) => {
                  if (error) return `Error! ${error.message}`;
                  if (data.getIdentity)
                    return (
                      <React.Fragment>
                        <Mutation mutation={UPDATE_IDENTITY_LAST_ACTIVE}>
                          {updateIdentityLastActive => (
                            <UpdateLastActive
                              updateIdentityLastActive={
                                updateIdentityLastActive
                              }
                              identityId={data.getIdentity.username}
                            />
                          )}
                        </Mutation>
                        <HomePage
                          match={props.match}
                          identity={data.getIdentity}
                        />
                      </React.Fragment>
                    );
                  if (loading)
                    return (
                      <div className="app-loading">
                        <img
                          src={LoadingIcon}
                          alt="Please wait while the applications loads."
                        />
                      </div>
                    );
                }}
              </Query>
            )}
          />
        </Switch>
      </Router>
    );
  }
}

const AppWithAuth = withAuthenticator(App, false, [], null, MyTheme);

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <AppWithAuth />
    </Rehydrated>
  </ApolloProvider>
);

export default WithProvider;

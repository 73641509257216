import styled from 'styled-components'

const Label = styled.label`
	font-size: 14px;
	font-weight: 500;
	display: block;
	margin: 0 0 5px 15px;
	color: #0f2d49;
`

export default Label
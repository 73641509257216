import React from 'react'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'
import { withRouter } from 'react-router'

import Button from './UI/Button'

const ButtonStyled = styled(Button)`
	width: 120px;
	margin: 20px 0;
	font-size: 15px;
	text-align: center;
`

class LogoutButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleLogout = this.handleLogout.bind(this)
  }
  handleLogout() {
		try {
		  Auth.signOut()
			this.props.history.push(`/`)
		}
		catch(e) {
		   console.error('Error logging out.')
		}
	}

  render() {
    return (
      <ButtonStyled onClick={this.handleLogout}>Logout</ButtonStyled>
    )
  }
}

export default withRouter(LogoutButton)

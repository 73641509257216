import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// Apollo components
import { Mutation } from "react-apollo"
import ADD_SKILL from './Queries/ADD_SKILL'
import DELETE_SKILL from './Queries/DELETE_SKILL'

// UI Components
import SkillsEditTags from './SkillsEditTags'
import Tags from './UI/Tags'

const SkillsStyled = styled(Tags)`
	overflow: scroll;
`

class SkillsEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      skills: props.skills,
    }
    this.handleAddition = this.handleAddition.bind(this)
  }

	handleInputFocus() {
		window.scrollTo(0,0)
		document.body.scrollTop = 0
	}

  handleAddition(skill) {
    this.setState(state => ({ skills: [...state.skills, skill] }))
  }

  render() {

	  const { skills, skillType, profileId } = this.props
		const skillsForTags = skills.filter( skill => skill.skill_type === skillType).map((skill, index) => ( { id: skill.skillId, "text": skill.name } ))

	  return (
	    <Mutation 
	      mutation={ADD_SKILL}
	      >
	      {(AddSkill, { data }) => (

	        <Mutation 
	          mutation={DELETE_SKILL}
	          >
	          {(DeleteSkill, { data }) => (

						<SkillsStyled>
							<SkillsEditTags profileId={profileId} skillsForTags={skillsForTags} skillType={skillType} AddSkill={AddSkill} DeleteSkill={DeleteSkill} handleInputFocus={this.handleInputFocus} />
            </SkillsStyled>

	          )}
	        </Mutation>
	      )}
	    </Mutation>
	  )
	}
}

export default SkillsEdit

SkillsEdit.propTypes = {
  skills: PropTypes.array.isRequired,
}

SkillsEdit.defaultProps = { 
  skills: [],
}


import React from "react";
import styled from "styled-components";
import packageJson from "../../../package.json";
import MediaQuery from "react-responsive";

// Typography
import Header1 from "../../Components/Typography/Header1";

// Components
import BottomTray from "../../Components/BottomTray";
import Menu from "../../Components/Menu";
import LogoutButton from "../../Components/LogoutButton";

const MenuStyled = styled.div`
  padding: 15px;
`;

const VersionNumber = styled.div`
  color: #999;
  font-size: 12px;
`;

const AccountPage = props => {
  function handleRefreshPage() {
    navigator.serviceWorker.getRegistrations().then(registrationsArray => {
      registrationsArray[0].update();
    });
  }

  return (
    <MenuStyled>
      <MediaQuery query="(min-width: 768px)">
        <Menu />
      </MediaQuery>
      <Header1>My Account</Header1>
      <LogoutButton />
      <VersionNumber onClick={handleRefreshPage}>
        Version: {packageJson.version}
      </VersionNumber>
      <MediaQuery query="(max-width: 767px)">
        <BottomTray pageVisible="account" />
      </MediaQuery>
    </MenuStyled>
  );
};

export default AccountPage;

import React from 'react'
import { Mutation } from "react-apollo"
import styled from 'styled-components'
import ADD_POOL_USER_ACCESS from './Queries/ADD_POOL_USER_ACCESS'
import ADD_MESSAGE from './Queries/ADD_MESSAGE'

import TextInput from './UI/TextInput'
import Label from './Typography/Label'
import SubmitButton from './UI/SubmitButton'
//import Radio from './UI/Radio'

/*const UserType = styled.div`
  margin: 10px 0 40px 10px;
`*/

const SubmitButtonCentered = styled.div`
  text-align: center;
`

/*
                <UserType>
                  <Radio name="user_type" value="RECRUITER" label="Recruiter" />
                  <Radio name="user_type" value="CLIENT" checked="checked" label="Client" />
                </UserType>
*/

const AddPoolUserAccess = (props) => {
  let username, form // , job_type
  const currentUsername = props.username
  const poolId = props.poolId
  const handleClose = props.handleClose

  return (
    <Mutation 
      mutation={ADD_POOL_USER_ACCESS}
      >
      {(addPoolUserAccess, { data }) => (

        <Mutation 
          mutation={ADD_MESSAGE}
          >
          {(addMessage, { data }) => (

            <div>
              <form
                ref={node => form = node}
                onSubmit={e => {
                  e.preventDefault()
                  handleClose()
                  addPoolUserAccess({ variables: { poolId: poolId, username: username.value } }) // Add user_type
                  addMessage({ 
                    variables: { 
                      message: `"${username.value}" has been added as a collaborator.`, 
                      username: currentUsername,
                      theme: "system_add_collaborator",
                      chatId: poolId,
                      data: JSON.stringify({
                        poolId: poolId,
                        username_added: username.value,
                        username: username.value,
                        // user_type: 
                      })
                    } 
                  })
                  username.value = ""
                }}
              >
                <Label>Email address</Label>
                <TextInput
                  type="email"
                  placeholder="Enter email address"
                  ref={node => {
                    username = node
                  }}
                /><br />
                <SubmitButtonCentered>
                  <SubmitButton type="submit">Invite Client</SubmitButton>
                </SubmitButtonCentered>
              </form>
            </div>

          )}
        </Mutation>

      )}
    </Mutation>
  )
}

export default AddPoolUserAccess